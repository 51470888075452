<script>
    import {getCurrentPage} from '../../resources/js/creator6-helper';
    import {renderPage} from '../../resources/js/creator6-core';
    async function redoStep(){
        getCurrentPage().redo().subscribe(function () {
            return renderPage();
        });
    }
</script>

<button id="redo"  on:click={(e) => redoStep()}>
    <span class="colop-icon-forward_round"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
</button>

<style>
    button {
        border:none;
        background:none;
        font-size: var(--creator6-fontsize-iconlarge);
        margin-top: -5px;
        margin-left: 25px;
        cursor:pointer;
    }

    button:disabled {
        opacity: 0.5;
        cursor: none;
    }
</style>