//import {c6_previewPdfEndpoint, c6_apiConnector} from "./creator6-init";
import { get } from 'svelte/store';
import {c6PreviewPdfEndpoint, c6ApiConnector, c6WorkspaceId, c6PreviewPdfTemplateUrl} from "./stores";
import {_} from "../../services/i18n";

function showFile(blob, filename){
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], {type: "application/pdf"})

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download=filename+"_file.pdf";
    link.click();
    setTimeout(function(){
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
}

async function getPlatePreviewUrl(page= 1, dpi=300){
    var connection = get(c6ApiConnector).getGateway().getFrontendApi().connection;
    var resource = "workspaces/"+get(c6WorkspaceId)+"/pages/"+page+"/views/preview?dpi="+dpi;
    var previewUrl = `${connection.baseUrl}/${resource}`;
    return previewUrl;
}

async function getPreviewImageUrl(filename, productionServiceUrl, page= 1, dpi=300){
    var previewUrl = await getPlatePreviewUrl(page= 1, dpi=300);
    var templateUrl = (get(c6PreviewPdfTemplateUrl) === null) ? "" : get(c6PreviewPdfTemplateUrl);
    var getParams="?configurationId=default&title=Creator 6 Print Preview&subtitle=plate subtitle az&imprintWidth=59&imprintHeight=23&imprint="+previewUrl+"&templateUrl="+templateUrl+"&name=TestC6_print_preview&productImageUrl=&testMode=0";
    if(productionServiceUrl === undefined){
        productionServiceUrl = get(c6PreviewPdfEndpoint);
    }
    var previewPDFUrl = productionServiceUrl+getParams;
    fetch(`${previewPDFUrl}`, {
        method: 'GET'
    }).then((r) => r.blob()).then(rBlob => {
        if(rBlob.type !== "application/pdf"){
            return Promise.reject("error");
        }
        else {
            showFile( rBlob,filename);
            return Promise.resolve("ok");
        }
    });
}

async function getWorkspacePreviewImageUrl(workSpaceId, page=1, dpi = 300){
    //var workSpace = getWorkspaceFromId(c6_apiConnector, getWorkspaceId());
    //var previewUrlWorkspace = workSpace.getPageExportDownloadUrl(1, 'PDF');
    var connection = get(c6ApiConnector).getGateway().getFrontendApi().connection;
    var resource = "workspaces/"+workSpaceId+"/pages/"+page+"/views/preview?dpi="+dpi;
    var previewUrl = `${connection.baseUrl}/${resource}`;//console.log("previewURL: "+ previewUrl);
    return previewUrl;

}

export {getPreviewImageUrl, getWorkspacePreviewImageUrl, showFile, getPlatePreviewUrl}