<script>
    export let options;
    export let legend;
    export let valueSelected = options[0].value;
    export let fontSize = 16;
    export let flexDirection = 'row';
    export let icon;
    export let valueClicked;

    const uniqueID = Math.floor(Math.random() * 100)
    const slugify = (str = "") =>
        str.toLowerCase().replace(/ /g, "-").replace(/\./g, "");
    const onToogleButtonSelected = (value) => {
        //console.log("toggle button clicked --> "+value);
        valueClicked = true;
    }

</script>

<div class="c6-toggle-buttons together"
     aria-labelledby={`label-${uniqueID}`}
     style="font-size:{fontSize}px; flex-direction:{flexDirection}"
     id={`group-${uniqueID}`}>
    {#if legend}
        <div class="legend" id={`label-${uniqueID}`}>{legend} </div>
    {/if}
    {#each options as { value, label, icon }}
        <input class="sr-only"
                type="radio"
                id={`tb-${uniqueID}`+ slugify(value)}
                bind:group={valueSelected}
               on:click={onToogleButtonSelected(value)}
               value={value} /><label for={`tb-${uniqueID}`+ slugify(value)}>{#if icon}<span class="{icon}"></span>{:else}{label}{/if} </label>
    {/each}
</div>

<style>

    .c6-toggle-buttons {
        width: 100%;
        display: inline-flex;
        justify-content: stretch;
    }

    .c6-toggle-buttons input[type="radio"] {
        visibility: hidden;
    }
    .c6-toggle-buttons label {
        border: 1px solid var(--creator6-controls-bg);
        border-radius: var(--creator6-border-radius);
        padding: 0 1em;
        display: inline-block;
        line-height:37px;
        height:40px;
        width: 100%;
        text-align: center;
        background: var(--creator6-controls-bg);
    }

    .c6-toggle-buttons input:checked + label {
        background: white;
        box-shadow: none;
        color: var(--creator6-color-blue);
    }

    .c6-toggle-buttons input:hover + label,
    .c6-toggle-buttons input:focus + label {
        background: var(--creator6-controls-hover);
    }

    .together {
        position: relative;
    }

    .together input {
        position: absolute;
        width: 1px;
        height: 1px;
        top: 0;
        left: 0;
    }

    .together label {
        border-radius: 0;
        border-right: 0;
    }

    .together label:first-of-type {
        border-radius: var(--creator6-border-radius) 0 0 var(--creator6-border-radius);
        border-right: 0;
    }

    .together label:last-of-type {
        border-radius: 0 var(--creator6-border-radius) var(--creator6-border-radius) 0;
        border: 1px solid var(--creator6-controls-bg);
    }

</style>
