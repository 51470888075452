<script>
    import Select from 'svelte-select';
    import {dndzone} from 'svelte-dnd-action';
    import {_} from '../../../services/i18n';
    import {renderPage} from '../../../resources/js/creator6-core';
    import {
        getTextElementData,
        setTextLines,
        updateText,
        textSizeAbsolute,
        textLineDetailsUpdate,
        textLineHeightAbsolute,
        textLineFontUpdate,
        textElementGeometryUpdate,
        addTextLine,
        removeTextLine,
        setTextElementDataString, textBlockDetailsUpdate
    } from '../../../resources/js/creator6-text';
    import {onMount} from "svelte";
    import Fontselection from "./Fontselection.svelte";
    import {
        c6WorkspaceFonts,
        c6WorkspaceContainerIds,
        c6ApiConnector,
        c6EngineCanvas,
        c6WorkspaceId,
        c6LoadWorkspaceWithTexts,
        c6WorkspaceConfiguration,
        c6WorkspaceValidation,
        c6TextReplacementdropDowns,
        c6WorkspaceMediumHeight,
        c6WorkspaceMediumWidth,
        c6SolutionType, c6WorkspaceMaxLines
    } from "../../../resources/js/stores";
    import TextAlignmentButtons from "./TextAlignmentButtons.svelte";
    import ArcAlignmentButtons from "./ArcAlignmentButtons.svelte";
    import Loader from "../../Loader.svelte";
    import {convertRtlRoundText} from '../../../resources/js/rtlroundtext';
    import Switch from "./Switch.svelte";
    import {flip} from 'svelte/animate';
    import {getUiConfigs} from "../../../resources/js/creator6-configuration";
    import TextblockAlignmentButtons from "./TextblockAlignmentButtons.svelte";
    import RangeSlider from "svelte-range-slider-pips";
    import {getFabricElementById, getFabricValue} from "../../../resources/js/fabricjshelper";

    //export let autolayouttext;// = '0c24b444-3814-4762-81bd-1067e1c8dbd3';
    export let elementId;
    export let textModel;
    export let textBlockId;
    export let autoText;

    let renderAgain = false;
    //$:renderAgain && await renderPage();

    let lines;
    let addMaxLines; //for testing  Math.round($c6WorkspaceMediumHeight / (5 / 2.835));

    let texts = [];

    let currentLine = 0;
    let currentElementId = elementId;
    let fontSize;
    let fontFamily;
    let fontItalic;
    let fontWeight;
    let updateFontInfo = false;
    //$:updateFontInfo, console.log("updateFontInfo" + updateFontInfo);

    $:fontFamily, textLineFontUpdate(elementId, currentLine, fontFamily, fontWeight, fontItalic, updateFontInfo), updateFontInfo = false;
    $:fontItalic, textLineFontUpdate(elementId, currentLine, fontFamily, fontWeight, fontItalic, updateFontInfo), updateFontInfo = false;
    $:fontWeight, textLineFontUpdate(elementId, currentLine, fontFamily, fontWeight, fontItalic, updateFontInfo), updateFontInfo = false;
    let minFontSize = $c6WorkspaceConfiguration.get('validation.fonts.minFontSize').values[0];
    let maxFontSize = $c6WorkspaceConfiguration.get('validation.fonts.maxFontSize').values[0];


    let lineHeight;
    let minLineHeight = 20;
    let maxLineHeight = 400;

    let ignoreVarChange = true;

    let autoWidth = false;
    let updateAutoWidth = false;
    $:autoWidth, textLineDetailsUpdate(elementId, selectedLines, 'autoWidth', autoWidth, false, updateTextColor);

    let alignment;
    let updateAlignment = false;
    //$:updateAlignment, console.log("updateAlignment" + updateAlignment);

    $:alignment, textLineDetailsUpdate(elementId, selectedLines, 'alignment', alignment, false, updateAlignment);
    let alignmentOptions = [];

    alignmentOptions.push({
        value: 'Left',
        label: $_('text.button.alignmentleft'),
    });

    alignmentOptions.push({
        value: 'Center',
        label: $_('text.button.alignmentcenter'),
    });
    alignmentOptions.push({
        value: 'Right',
        label: $_('text.button.alignmentright'),
    });

    let blockVAlignment;
    let updateBlockVAlignment = false;
    let blockVPadding  = [0];
    let verticalBlockPadding
    $:blockVAlignment, textBlockDetailsUpdate(elementId, 'verticalTextRenderStrategy', blockVAlignment, updateBlockVAlignment);
    let blockVAlignmentOptions = [];
    let blockVAlignmentIcon;
    blockVAlignmentOptions.push({
        value: 'Top',
        icon: 'horizontal_top',
        label: $_('text.button.valigntop')
    });

    blockVAlignmentOptions.push({
        value: 'Middle',
        icon: 'horizontal_center',
        label: $_('text.button.valigncenter')
    });

    blockVAlignmentOptions.push({
        value: 'Bottom',
        icon: 'horizontal_bottom',
        label: $_('text.button.valignbottom')
    });

    const previewVpadding = (event, value) => {
        //console.log("preview V padding for element " + elementId + " to " + value);
        let differenz = value - blockVPadding[0];
        let differenzCanvas = getFabricValue(differenz);
        let canvasFabric = $c6EngineCanvas.fabricCanvas;
        let object = getFabricElementById(canvasFabric, elementId);
        object.lockMovementY = false;
        blockVPadding = [value];
        if(blockVAlignment === "Top"){
            //object.set('left', newValue + (object.width/2) + canvasPadding);
            object.set('top', object.top + differenzCanvas);
        }
        else if(blockVAlignment === "Bottom"){
            //object.set('left', canvasFabric.width - (newValue + (object.width) + canvasPadding));
            object.set('top', object.top - differenzCanvas);
        }
        canvasFabric.requestRenderAll();
        object.lockMovementY = true;
    }

    async function submitVpadding(event, value, startvalue) {
        //console.log("submit now V padding for element " + elementId + " from " + startvalue + " to " + value);
        if (value !== startvalue && value !== undefined) {
            try {
                let updated = await textBlockDetailsUpdate(elementId, 'verticalPadding', value);
                verticalBlockPadding = value;
            } catch (err) {
                console.log(err);
            }
        }
    }

    let blockHAlignment;
    let updateBlockHAlignment = false;
    let blockHPadding = [0];
    let horizontalBlockPadding;
    $:blockHAlignment, textBlockDetailsUpdate(elementId, 'horizontalTextRenderStrategy', blockHAlignment, updateBlockHAlignment);
    let blockHAlignmentOptions = [];
    let blockHAlignmentIcon;


    blockHAlignmentOptions.push({
        value: 'Left',
        icon: 'vertical_left',
        label: $_('text.button.halignleft')
    });

    blockHAlignmentOptions.push({
        value: 'Center',
        icon: 'vertical_center',
        label: $_('text.button.haligncenter')
    });
    blockHAlignmentOptions.push({
        value: 'Right',
        icon: 'vertical_right',
        label: $_('text.button.halignright')
    });

    const previewHpadding = (event, value) => {
        //console.log("preview H padding for element " + elementId + " to " + value);
        let differenz = value - blockHPadding[0];
        let differenzCanvas = getFabricValue(differenz);
        let canvasFabric = $c6EngineCanvas.fabricCanvas;
        let object = getFabricElementById(canvasFabric, elementId);
        object.lockMovementX = false;
        blockHPadding = [value];
        if(blockHAlignment === "Left"){
            //object.set('left', newValue + (object.width/2) + canvasPadding);
            object.set('left', object.left + differenzCanvas);
        }
        else if(blockHAlignment === "Right"){
            //object.set('left', canvasFabric.width - (newValue + (object.width) + canvasPadding));
            object.set('left', object.left - differenzCanvas);
        }
        canvasFabric.requestRenderAll();
        object.lockMovementX = true;
    }

    async function submitHpadding(event, value, startvalue) {
        //console.log("submit now H padding for element " + elementId + " from " + startvalue + " to " + value);
        if (value !== startvalue && value !== undefined) {
            try {
                let updated = await textBlockDetailsUpdate(elementId, 'horizontalPadding', value);
                horizontalBlockPadding = value;
            } catch (err) {
                console.log(err);
            }
        }
    }

    let fixedWidth;

    const previewFixedWidth = (event, value) => {
        //console.log("preview fixedWidth element " + elementId + " to " + value);

    }

    async function submitFixedWidth(event, value, startvalue) {
        //console.log("submit now H padding for element " + elementId + " from " + startvalue + " to " + value);
        if (value !== startvalue && value !== undefined) {
            try {
                let updated = await textBlockDetailsUpdate(elementId, 'fixedWidth', value);
                fixedWidth = value;
            } catch (err) {
                console.log(err);
            }
        }
    }

    let letterspacing = 1; //round only so far
    let indent = 0; //round only so far
    let updateBaseline = false;
    let baseline;
    let baselineOptions = [];
    $:baseline, textLineDetailsUpdate(elementId, currentLine, 'baseline', baseline, false, updateBaseline);

    baselineOptions.push({
        value: 'Top',
        label: $_('text.button.arctop'),
    });

    baselineOptions.push({
        value: 'Bottom',
        label: $_('text.button.arcbottom'),
    });

    let updateTextColor = false;
    let textInverted = false;
    let textColor = "";
    $:textInverted, textColor = (textInverted) ? "#FFFFFF" : "";
    $:textColor, textLineDetailsUpdate(elementId, selectedLines, 'hexColor', textColor, false, updateTextColor);

    let radiusx = 0;
    let geometry = "";
    let customData = "";
    let fonts;
    let loadElementDataInProgress = false;
    let inputTexts;
    let currentElementData;
    let forceRtl;
    let changeAll;
    let selectedLines = [];

    let textInfoLabel = $_('text.info.bottomlabel');
    let textInfoDetail = $_('text.info.bottomdetails');

    let textInfoShow = (!(textInfoLabel === " " && textInfoDetail === " "));
    let textInfo4Lines = false;
    let textHead4Lines = false;
    let textReplaceInputLines = false;
    let textHeadlineTop;
    let headlineTopShow = false;
    let textReplacementdropDowns = $c6TextReplacementdropDowns;
    let showTextControls;
    let showTextControlsAdmin;
    let showTextControlsSpecific;
    let showTextControlsSpecificSettings = {};
    let pattern;
    let showBlock = true;

    async function loadElementData(elementId) {
        if (loadElementDataInProgress === false) {
            //console.log("loadElementDataInProgress= " + loadElementDataInProgress);
            loadElementDataInProgress = true;
            currentElementData = await getTextElementData(elementId);
            let tmptexts = currentElementData.lines;
            lines = currentElementData.lines.length;
            addMaxLines = ($c6WorkspaceMaxLines === 0 || $c6WorkspaceMaxLines < lines) ? lines : Math.round($c6WorkspaceMaxLines);
            let selectedLine = currentElementData.details.textLines[currentLine];
            fontSize = Math.max(selectedLine.fontSize, minFontSize);
            lineHeight = selectedLine.lineHeight;
            fontFamily = selectedLine.fontFamily;
            fontWeight = selectedLine.fontWeight;
            fontItalic = (selectedLine.fontItalic) ? selectedLine.fontItalic : false;
            alignment = selectedLine.alignment;
            autoWidth = selectedLine.autoWidth;
            textColor = (selectedLine.hexColor && selectedLine.hexColor !== "") ? selectedLine.hexColor : "";
            textInverted = (textColor.toUpperCase() === "#FFFFFF") ? true : false;
            geometry = JSON.stringify(currentElementData.geometry, undefined, 4);
            fixedWidth = currentElementData.fixedWidth;
            horizontalBlockPadding = (currentElementData.details.horizontalPadding) ? currentElementData.details.horizontalPadding: 0;
            blockHPadding = [horizontalBlockPadding];
            blockHAlignment = (currentElementData.details.horizontalTextRenderStrategy) ? currentElementData.details.horizontalTextRenderStrategy: "Center";
            blockHAlignmentIcon = (blockHAlignment === "Left") ? "colop-icon-pointer-right" : "colop-icon-pointer-left";

            verticalBlockPadding = (currentElementData.details.verticalPadding) ? currentElementData.details.verticalPadding: 0;
            blockVPadding = [verticalBlockPadding];
            blockVAlignment = (currentElementData.details.verticalTextRenderStrategy) ? currentElementData.details.verticalTextRenderStrategy: "Middle";
            blockVAlignmentIcon = (blockVAlignment === "Top") ? "colop-icon-pointer-down" : "colop-icon-pointer-up";

            customData = {};
            if ("dataString" in currentElementData) {
                customData = JSON.parse(currentElementData.dataString);
            }

            //console.log("showBlock default: " + showBlock);
            if ('blocksettings' in customData) {
                //console.log("showBlock template settings:");
                //console.log(customData.blocksettings);
                showBlock = ('blocksettings' in customData && customData.blocksettings !== undefined && customData.blocksettings.showBlock !== undefined) ? customData.blocksettings.showBlock : true;
                //console.log("showBlock blocksettings " + showBlock);
            }

            if ('infotext' in customData) {
                if ('general' in customData.infotext) {
                    textInfoDetail = customData.infotext.general;
                    textInfoShow = true;
                }
                if ('lines' in customData.infotext) {
                    textInfo4Lines = true;
                }
                if ('general' in customData.headlines) {
                    textHeadlineTop = customData.headlines.general;
                    headlineTopShow = true;
                }
                if ('lines' in customData.headlines) {
                    textHead4Lines = true;
                }
            }

            if ('replaceInputLines' in customData) {
                textReplaceInputLines = true;
            }

            if (textModel !== "linear") {
                letterspacing = ("letterSpacing" in selectedLine) ? selectedLine.letterSpacing : currentElementData.letterSpacing;
                indent = ("indent" in selectedLine) ? selectedLine.indent : currentElementData.indent;
                baseline = ("baseline" in selectedLine) ? selectedLine.baseline : currentElementData.baseline;
                radiusx = currentElementData.geometry.radiusX;
            }
            //c6WorkspaceontainerIds.set(c => c.push(currentElementData.details.containerId));
            if (currentElementData.details.containerId !== undefined) {
                c6WorkspaceContainerIds.set({[currentElementData.details.containerId]: elementId});
            }

            if ($c6LoadWorkspaceWithTexts.length > 0) {
                inputTexts = $c6LoadWorkspaceWithTexts;

                function changeText(item, index, arr) {
                    if (inputTexts.length) {
                        arr[index] = inputTexts.shift();
                    }
                }

                tmptexts.forEach(changeText);
                currentElementData.lines = tmptexts;
                let textUpdated = await setTextLines(elementId, currentElementData, true);
            }

            texts = tmptexts;

            function prepareDnDLines(item, index, arr) {
                items.push({
                    id: index,
                    title: item,
                    pattern: ('linesettings' in customData && customData.linesettings[index] !== undefined && customData.linesettings[index].pattern) ? customData.linesettings[index].pattern : null,
                    invalidText: ('linesettings' in customData && customData.linesettings[index] !== undefined && customData.linesettings[index].invalidText) ? customData.linesettings[index].invalidText : null,
                    maxlength: ('linesettings' in customData && customData.linesettings[index] !== undefined && customData.linesettings[index].maxChars) ? customData.linesettings[index].maxChars : null,
                    minlength: ('linesettings' in customData && customData.linesettings[index] !== undefined && customData.linesettings[index].minChars) ? customData.linesettings[index].minChars : null,
                    autoFillUp: ('linesettings' in customData && customData.linesettings[index] !== undefined && customData.linesettings[index].autoFillUp) ? customData.linesettings[index].autoFillUp : null,
                    placeholder: ('linesettings' in customData && customData.linesettings[index] !== undefined && customData.linesettings[index].placeholder) ? customData.linesettings[index].placeholder : $_('text.line.empty'),
                    required: ('linesettings' in customData && customData.linesettings[index] !== undefined && customData.linesettings[index].required) ? customData.linesettings[index].required : null,
                    visible: ('linesettings' in customData && customData.linesettings[index] !== undefined && customData.linesettings[index].hide) ? !customData.linesettings[index].hide : true,
                    locked: currentElementData.details.textLines[index].locked
                });
            }

            items = [];
            texts.forEach(prepareDnDLines);

            if (selectedLines.length === 0) {
                for (let i = 0; i < lines; i++) {
                    selectedLines[i] = false;
                }
            }
            loadElementDataInProgress = false;
            return currentElementData;
        }
    }

    function getTextForLine(line) {
        return texts[line];
    }

    function textSizePlus(sceneElementId, line) {
        fontSize++;
        textLineDetailsUpdate(elementId, selectedLines, 'fontSize', fontSize, 1);
    }

    function textSizeMinus(sceneElementId, line) {
        fontSize--;
        fontSize = Math.max(fontSize, minFontSize);
        textLineDetailsUpdate(elementId, selectedLines, 'fontSize', fontSize, -1);
    }

    function textLineHeightPlus(sceneElementId, line) {
        lineHeight += 10;
        textLineDetailsUpdate(elementId, selectedLines, 'lineHeight', lineHeight);
    }

    function textLineHeightMinus(sceneElementId, line) {
        lineHeight -= 10;
        textLineDetailsUpdate(elementId, selectedLines, 'lineHeight', lineHeight);
    }

    function textIndentAbsolute(sceneElementId, line, value) {
        indent = value;
        textLineDetailsUpdate(elementId, selectedLines, 'indent', indent);
    }

    function textIndentPlus(sceneElementId, line) {
        indent++;
        textLineDetailsUpdate(elementId, selectedLines, 'indent', indent);
    }

    function textIndentMinus(sceneElementId, line) {
        indent--;
        textLineDetailsUpdate(elementId, selectedLines, 'indent', indent);
    }

    function textLetterspacingAbsolute(sceneElementId, line, value) {
        letterspacing = value;
        textLineDetailsUpdate(elementId, selectedLines, 'letterSpacing', letterspacing);
    }

    function textLetterspacingPlus(sceneElementId, line) {
        letterspacing++;
        textLineDetailsUpdate(elementId, selectedLines, 'letterSpacing', letterspacing);
    }

    function textLetterspacingMinus(sceneElementId, line) {
        letterspacing--;
        textLineDetailsUpdate(elementId, selectedLines, 'letterSpacing', letterspacing);
    }

    function textRadiusAbsolute(sceneElementId, value) {
        radiusx = value;
        textElementGeometryUpdate(sceneElementId, 'radiusX', radiusx);
    }

    function textRadiusPlus(sceneElementId) {
        radiusx++;
        textElementGeometryUpdate(sceneElementId, 'radiusX', radiusx);
    }

    function textRadiusMinus(sceneElementId) {
        radiusx--;
        textElementGeometryUpdate(sceneElementId, 'radiusX', radiusx);
    }

    function updateGeometry(sceneElementId, geometry) {
        let newGeometry = JSON.parse(geometry);
        textElementGeometryUpdate(sceneElementId, 'all', newGeometry);
    }

    let dragDisabled = true;
    let dragEnabled = false;
    $:dragEnabled, toggleDragEnabled();

    const flipDurationMs = 200;

    function toggleDragEnabled() {
        dragDisabled = (!dragEnabled);
    }

    function handleSort(e) {
        items = e.detail.items;
    }

    async function handleDrop(e) {
        items = e.detail.items;
        if (e.type === "finalize") {
            function updateLines(item, index, arr) {
                currentElementData.lines[index] = item.title;
            }

            await items.forEach(updateLines);
            let textUpdated = await setTextLines(elementId, currentElementData);
        }
    }

    let items = [
        /*{id:1, title: 'I'},
        {id:2, title: 'Am'},
        {id:3, title: 'Yoda'}*/
    ];

    let isAllCheck = false;

    function setSelectedLine(line) {
        updateAlignment = false;
        updateTextColor = false;
        for (let i = 0; i < selectedLines.length; i++) {
            selectedLines[i] = (i === line);
        }
    }

    function addSelectedLine(line) {
        selectedLines[line] = true;
    }

    function selectAllLines() {
        selectedLines.forEach(line => line = true);
    }

    function deselectAllLines() {
        selectedLines.forEach(line => line = false);
    }

    function togglecheckboxes() {
        updateAlignment = false;
        updateTextColor = false;
        for (let i = 0; i < selectedLines.length; i++) {
            selectedLines[i] = !isAllCheck;
        }
        isAllCheck = !isAllCheck;
    }

    async function setDropDownValue(event, line) {
        let value = event.detail.value;
        currentElementData.lines[line] = value;

        function updateLines(item, index, arr) {
            if (index == line) {
                item.title = value;
            }
        }

        await items.forEach(updateLines);
        let textUpdated = await setTextLines(elementId, currentElementData);
        await renderPage();
        items = items;
    }

    async function setDropDownPartValue(event, element, elementpos) {
        let linetext = "";
        let limitChars = null;
        let limitCharsPart = null;
        let showValue = (event.detail.showValue !== undefined) ? event.detail.showValue : 1;
        let value = (showValue) ? event.detail.value : "";
        let changeInputType = (event.detail.inputtype !== undefined) ? event.detail.inputtype : 0;
        if (changeInputType) {
            customData.replaceInputLines[element].multiinput.fields[elementpos + 1].inputtype = changeInputType;
            customData.replaceInputLines[element].multiinput.fields[elementpos + 1].text = (event.detail.defaultValue !== undefined) ? event.detail.defaultValue : "";
        } else {
            customData.replaceInputLines[element].multiinput.fields[elementpos + 1].inputtype = null;
        }

        let changeInputPattern = (event.detail.pattern !== undefined) ? event.detail.pattern : 0;
        let hasInvalidText = (event.detail.invalidText !== undefined) ? event.detail.invalidText : 0;
        if (changeInputPattern) {
            customData.replaceInputLines[element].multiinput.fields[elementpos + 1].pattern = changeInputPattern;
            if (hasInvalidText) {
                customData.replaceInputLines[element].multiinput.fields[elementpos + 1].invalidText = hasInvalidText;
            }
        } else {
            customData.replaceInputLines[element].multiinput.fields[elementpos + 1].pattern = null;
            customData.replaceInputLines[element].multiinput.fields[elementpos + 1].invalidText = null;
        }

        customData.replaceInputLines[element].multiinput.fields[elementpos].text = value;
        customData.replaceInputLines[element].multiinput.fields[elementpos].dropDownValue = event.detail.value;

        for (const [fieldid, data] of Object.entries(customData.replaceInputLines[element].multiinput.fields)) {
            let field = customData.replaceInputLines[element].multiinput.fields[fieldid];
            let checkElements = field.hideifempty;
            let checkElementsOperator = field.hasOwnProperty('hideifemptycondition') ? field.hasOwnProperty('hideifemptycondition') : 'OR';
            let showStaticText = true;
            if (checkElements.length) {
                showStaticText = showHideElementCheck(checkElements, checkElementsOperator, element);
            }
            if (showStaticText) {
                if (limitChars) {
                    if ((linetext.length + data.text.length) <= limitChars && !data.text.startsWith("!#!##")) {
                        linetext += data.text;
                    } else {
                        limitCharsPart = limitChars - linetext.length;
                        customData.replaceInputLines[element].multiinput.fields[fieldid].maxChars = limitCharsPart;
                    }
                } else {
                    if (!data.text.startsWith("!#!##")) {
                        linetext += data.text;
                    }
                }
            }
        }
        currentElementData.lines[element] = linetext;

        function updateLines(item, index, arr) {
            if (index === element) {
                item.title = linetext;
                item.invalidText = "";
                item.pattern = "";
            }
        }

        await items.forEach(updateLines);
        items = items;
        await setTextElementDataString(currentElementId, customData);
        let textUpdated = await setTextLines(elementId, currentElementData);
        await renderPage();
    }

    let timeouttext = 0;

    function showHideElementCheck(checkElements, checkElementsOperator, element) {
        let showStaticText = true;
        checkElements.forEach(checkElement => {
            if (!showStaticText && checkElementsOperator === "OR") return false;
            showStaticText = !!customData.replaceInputLines[element].multiinput.fields[checkElement].text.length;
        });
        return showStaticText;
    }

    async function updateOriginalTextField(event, element, forceRtl) {
        let linetext = "";
        let limitChars = null;
        let limitCharsPart = null;

        var input = event.target;
        input.checkValidity();
        if (!input.validity.valid) {
            return false;
        }

        if (customData.replaceInputLines[element].multiinput.settings !== undefined && customData.replaceInputLines[element].multiinput.settings.maxChars) {
            limitChars = customData.replaceInputLines[element].multiinput.settings.maxChars;
        }
        for (const [fieldid, data] of Object.entries(customData.replaceInputLines[element].multiinput.fields)) {
            let field = customData.replaceInputLines[element].multiinput.fields[fieldid];
            let checkElements = field.hideifempty;
            let checkElementsOperator = field.hasOwnProperty('hideifemptycondition') ? field.hasOwnProperty('hideifemptycondition') : 'OR';
            let showStaticText = true;
            if (checkElements.length) {
                showStaticText = showHideElementCheck(checkElements, checkElementsOperator, element);
            }
            if (showStaticText) {
                if (limitChars) {
                    if ((linetext.length + data.text.length) <= limitChars && !data.text.startsWith("!#!##")) {
                        linetext += data.text;
                    } else {
                        limitCharsPart = limitChars - linetext.length;
                        customData.replaceInputLines[element].multiinput.fields[fieldid].maxChars = limitCharsPart;
                    }
                } else {
                    if (!data.text.startsWith("!#!##")) {
                        linetext += data.text;
                    }
                }
            }
        }

        currentElementData.lines[element] = linetext;

        function updateLines(item, index, arr) {
            if (index === element) {
                item.title = linetext;
            }
        }

        await items.forEach(updateLines);
        items = items;

        if (event.keyCode === 9) {
            await setTextElementDataString(currentElementId, customData);
            let textUpdated = await setTextLines(elementId, currentElementData);
            await renderPage();
        } else {
            clearTimeout(timeouttext);
            timeouttext = setTimeout(async function () {
                // enter code here or a execute function.
                await setTextElementDataString(currentElementId, customData);
                let textUpdated = await setTextLines(elementId, currentElementData);
                await renderPage();
            }, 500);
        }
    }

    async function clearTextLines() {
        function updateLines(item, index, arr) {
            currentElementData.lines[index] = "";
            item.title = "";
        }

        await items.forEach(updateLines);
        items = items;
        let textUpdated = await setTextLines(elementId, currentElementData);
        await renderPage();
        return true;
    }



    onMount(async () => {
        showTextControls = await getUiConfigs('workspace.visibility.textcontrols');
        showTextControlsSpecific = await getUiConfigs('workspace.visibility.textcontrolsspecific');
        /*showTextControlsSpecificSettings = {
            invertText : false,
            sortLines : false,
            showRtl : false,
            addLines : false,
        }*/

        showTextControlsSpecificSettings = await getUiConfigs('workspace.visibility.textcontrolsspecificsettings');



        if($c6SolutionType === "eos") {
            showTextControlsSpecific = true;
            showTextControlsSpecificSettings = {};
            showTextControlsSpecificSettings.addLines = true;
            showTextControlsSpecificSettings.autoWidth = true;
        }
        //console.log("showTextControlsSpecificSettings " + showTextControlsSpecific);
        //console.log(showTextControlsSpecificSettings);
        showTextControlsAdmin = false;
        return () => {
        };
    });

</script>
{#await loadElementData(elementId)}
    <Loader/>
{:then currentElementData}
    {#if showBlock}
        <div class="creator6-panel-content">
            {#if showTextControls}
                <div class="creator6-autotext-controls">
                    {#await $c6WorkspaceFonts}
                    {:then $c6WorkspaceFonts}
                        <div class="creator6-autotext-fontstyle">
                            <Fontselection bind:selectedFontFamily="{fontFamily}" bind:selectedFontWeight="{fontWeight}"
                                           bind:selectedFontItalic="{fontItalic}" bind:valueClicked={updateFontInfo}/>
                        </div>
                    {/await}

                    <div class="creator6-button-input-button-group creator6-autotext-fontsize">
                        <button type="button" disabled="{(fontSize <= minFontSize) ? 'disabled' : ''}"
                                on:click={(e) => textSizeMinus(elementId, currentLine)}>
                            <span class="colop-icon-fontsize_minus"></span>
                        </button>
                        <input type="number" step="0.5" bind:value={fontSize} min={minFontSize} max={maxFontSize}
                               on:change={(e) => textSizeAbsolute(elementId, selectedLines, fontSize = Math.max(Math.min(e.target.valueAsNumber, maxFontSize), minFontSize))}/>
                        <button type="button" disabled="{(fontSize >= maxFontSize) ? 'disabled' : ''}"
                                on:click={(e) => textSizePlus(elementId, currentLine)}>
                            <span class="colop-icon-fontsize_plus"></span>
                        </button>
                    </div>

                    <div class="creator6-button-input-button-group creator6-autotext-linespacing">
                        <button type="button" on:click={(e) => textLineHeightMinus(elementId, currentLine)}>
                            <span class="colop-icon-txt_lineheight_smaller"></span>
                        </button>
                        <input type="number" step="0.5" bind:value={lineHeight} min={minLineHeight} max={maxLineHeight}
                               on:change={(e) => textLineHeightAbsolute(elementId, selectedLines, e.target.valueAsNumber)}/>
                        <button type="button" on:click={(e) => textLineHeightPlus(elementId, currentLine)}>
                            <span class="colop-icon-txt_lineheight_bigger"></span>
                        </button>
                    </div>

                    <div class="creator6-autotext-alignment">
                        <TextAlignmentButtons options={alignmentOptions} fontSize={16} bind:valueSelected={alignment}
                                              bind:valueClicked={updateAlignment}/>
                    </div>

                    {#if textModel !== "linear" }
                        <div class="creator6-button-input-button-group creator6-autotext-letterspacing">
                            <span class="colop-icon-font_spacing"></span>
                            <button type="button" on:click={(e) => textLetterspacingPlus(elementId, currentLine)}>
                                <span class="colop-icon-plus"></span>
                            </button>
                            <input type="number" step="0.5" bind:value={letterspacing} min={0} max={360}
                                   on:change={(e) => textLetterspacingAbsolute(elementId, currentLine, e.target.valueAsNumber)}/>
                            <button type="button" on:click={(e) => textLetterspacingMinus(elementId, currentLine)}>
                                <span class="colop-icon-minus"></span>
                            </button>
                        </div>

                        <div class="creator6-button-input-button-group creator6-autotext-indent">
                            <span class="colop-icon-txt_round_top_margin"><span class="path1"></span><span
                                    class="path2"></span><span class="path3"></span><span class="path4"></span><span
                                    class="path5"></span><span class="path6"></span><span class="path7"></span><span
                                    class="path8"></span><span class="path9"></span><span class="path10"></span><span
                                    class="path11"></span><span class="path12"></span><span class="path13"></span><span
                                    class="path14"></span></span>
                            <button type="button" on:click={(e) => textIndentPlus(elementId, currentLine)}>
                                <span class="colop-icon-plus"></span>
                            </button>
                            <input type="number" step="0.5" bind:value={indent} min={0} max={360}
                                   on:change={(e) => textIndentAbsolute(elementId, currentLine, e.target.valueAsNumber)}/>
                            <button type="button" on:click={(e) => textIndentMinus(elementId, currentLine)}>
                                <span class="colop-icon-minus"></span>
                            </button>
                        </div>

                        <div class="creator6-autotext-arc-alignment">
                            <ArcAlignmentButtons options={baselineOptions} fontSize={16} bind:valueSelected={baseline}/>
                        </div>

                        <div class="creator6-button-input-button-group creator6-autotext-radius">
                            <span class="colop-icon-txt_round_radius"></span>
                            <button type="button" on:click={(e) => textRadiusPlus(elementId)}>
                                <span class="colop-icon-plus"></span>
                            </button>
                            <input type="number" step="0.25" bind:value={radiusx} min={0} max={360}
                                   on:change={(e) => textRadiusAbsolute(elementId, e.target.valueAsNumber)}/>
                            <button type="button" on:click={(e) => textRadiusMinus(elementId)}>
                                <span class="colop-icon-minus"></span>
                            </button>
                        </div>

                        {#if showTextControlsSpecific && showTextControlsSpecificSettings.showRtl }
                            <div class="creator6-autotext-forcertl">
                                <span class="colop-icon-forward"></span>
                                <Switch bind:checked={forceRtl}/>
                                <span class="colop-icon-back"></span>
                            </div>
                        {/if}
                    {/if}
                    {#if showTextControlsSpecific && showTextControlsSpecificSettings.invertText }
                    <div class="creator6-autotext-invert">
                        <Switch bind:checked={textInverted} bind:valueClicked={updateTextColor}/>
                        <span>{$_('text.control.invert')}</span>
                    </div>
                    {/if}

                    {#if fixedWidth && fixedWidth > 0 }
                        <div class="creator6-autotext-invert">
                            <Switch bind:checked={autoWidth} bind:valueClicked={updateAutoWidth}/>
                            <span>{$_('text.control.autoWidth')}</span>
                        </div>
                    {/if}

                </div>
            {/if}
            <div class="creator6-autotext-lines">
                {#if headlineTopShow && textHeadlineTop !== " "}
                    <div class="creator6-textblock-headline">{@html textHeadlineTop}</div>
                {/if}
                <section use:dndzone={{items, flipDurationMs, dragDisabled, type:'block'+elementId, morphDisabled:true}}
                         on:consider={handleSort} on:finalize={handleDrop}>
                    {#each items as item, i (item.id)}
                        <div class="creator6-autotext-textline" animate:flip={{duration:flipDurationMs}}>
                            {#if showTextControls}
                                <label class="creator6-textlineselector">
                                    <input type="checkbox" name="{elementId}_textlineToEdit" value="{elementId}___{i}"
                                           bind:checked={selectedLines[i]}/>
                                    <span class="checkmark">{i + 1}</span>
                                </label>
                            {/if}
                            <label class="creator6-autotext {($c6WorkspaceValidation && 'elements' in $c6WorkspaceValidation && elementId in $c6WorkspaceValidation.elements && ('linesTooLong' in $c6WorkspaceValidation.elements[elementId] && $c6WorkspaceValidation.elements[elementId].linesTooLong.length && $c6WorkspaceValidation.elements[elementId].linesTooLong.includes(i+1))) ? 'creator6-autotext-error' : '' }">
                                {#if textHead4Lines && customData.headlines.lines[i] !== undefined }
                                    <span class="creator6-headline-message">{@html customData.headlines.lines[i]}</span>
                                {/if}
                                {#if textInfo4Lines && customData.infotext.lines[i] !== undefined }
                                    <span class="creator6-info-message">{@html customData.infotext.lines[i]}</span>
                                {/if}

                                {#if textReplaceInputLines && customData.replaceInputLines[i] !== undefined && selectedLines[i]}
                                    <div class="creator6-custom-input-fields">
                                        {#if 'dropdown' in customData.replaceInputLines[i] && customData.replaceInputLines[i].dropdown.allowed && customData.replaceInputLines[i].dropdown.data in textReplacementdropDowns}
                                            <div style="{customData.replaceInputLines[i].dropdown.settings.style}">
                                                <Select items="{textReplacementdropDowns[customData.replaceInputLines[i].dropdown.data]}"
                                                        on:select={(e) => setDropDownValue(e, i)} value="{item.title}"
                                                        listOpen="{true}" isClearable="{false}"></Select>
                                            </div>
                                            <span class="creator6-textreplacement-dropdown-arrow colop-icon-arrow_down"></span>
                                        {:else if 'multiinput' in customData.replaceInputLines[i] && customData.replaceInputLines[i].multiinput.allowed}
                                            {#each customData.replaceInputLines[i].multiinput.fields as customField, c}
                                        <span class="creator6-custom-input-field">
                                            {#if customField.fieldType === "readonly"}
                                                <span class="creator6-custom-static-text creator6-custom-static-text-{customField.id}">{customField.text}</span>
                                            {:else if customField.fieldType === "input"}
                                                <input type="text" class="autolayout-customfield"
                                                       name="{elementId}___{i}___{c}"
                                                       data-elementid="{elementId}"
                                                       data-blockid="{textBlockId}"
                                                       data-textline="{i}"
                                                       data-textid="{customField.id}"
                                                       style="{customField.style}"
                                                       bind:value="{customField.text}"
                                                       maxlength="{ (customField.maxChars > 0) ? customField.maxChars : null}"
                                                       pattern="{ (customField.pattern !== '') ? customField.pattern : null}"
                                                       placeholder="{ (customField.placeholder !== '') ? customField.placeholder : null}"
                                                       required="{ (customField.required !== '') ? customField.required : null}"
                                                       data-force="{customField.force}"
                                                       on:keyup={(e)=>updateOriginalTextField(e, i, forceRtl)}
                                                       on:focus={(e) => { if(currentLine !== i) {currentLine = i; loadElementData(elementId);} setSelectedLine(i)}}
                                                >
                                                {#if customField.invalidText}
                                                    <span class="creator6-invalid-message" aria-live="polite"
                                                          id="{elementId}___i{i}___c{c}">
                                                        {customField.invalidText}
                                                    </span>
                                                {/if}
                                            {:else if customField.fieldType.trim() !== ""}
                                                <div style="{customField.style}">
                                                    <Select items="{textReplacementdropDowns[customField.fieldType]}"
                                                            on:select={(e) => setDropDownPartValue(e, i, c)}
                                                            value="{{value: (customField.dropDownValue) ? customField.dropDownValue : customField.text, label: customField.text.trim()}}"
                                                            placeholder="-" listOpen="{false}"
                                                            isClearable="{false}"></Select>
                                                </div>
                                            {/if}
                                        </span>
                                            {/each}
                                        {/if}
                                    </div>
                                {/if}
                                {#if (!textReplaceInputLines || customData.replaceInputLines[i] === undefined) || (textReplaceInputLines && customData.replaceInputLines[i] !== undefined && !selectedLines[i])}
                                    <input type="text" class="autolayout" name="{elementId}___{i}"
                                           readonly="{item.locked}"
                                           data-elementid="{elementId}"
                                           data-blockid="{textBlockId}"
                                           data-textline="{i}"
                                           data-textid="{i}"
                                           value="{item.title}"
                                           style="{item.style}"
                                           pattern="{item.pattern}"
                                           maxlength="{item.maxlength}"
                                           placeholder="{item.placeholder}"
                                           required="{item.required}"
                                           on:keyup={(e)=>updateText(e, forceRtl, item.autoFillUp)}
                                           on:focus={(e) => { if(currentLine != i) {currentLine = i; loadElementData(elementId);} setSelectedLine(i)}}

                                    >
                                {/if}
                                <span class="placeholder"><span
                                        class="colop-icon-tab_line"></span> {$_('text.line.line')} {i + 1}</span>

                                {#if showTextControlsSpecific && showTextControlsSpecificSettings.addLines }

                                {#if i + 1 === items.length}
                            <span class="creator6-autotext-line-remove">
                                <button on:click={(e) => {items.splice(items.length -1);items = items; selectedLines.splice(-1,1); removeTextLine(elementId)} }><span
                                        class="colop-icon-remove"></span></button>
                            </span>
                                {/if}{/if}
                                {#if item.invalidText}
                        <span class="creator6-invalid-message" aria-live="polite" id="{elementId}___i{i}">
                            {item.invalidText}
                        </span>
                                {/if}
                                <span class="creator6-error-message" aria-live="polite" id="{elementId}___e{i}">{#if $c6WorkspaceValidation && 'elements' in $c6WorkspaceValidation && elementId in $c6WorkspaceValidation.elements}
                            {#if 'linesTooLong' in $c6WorkspaceValidation.elements[elementId] && $c6WorkspaceValidation.elements[elementId].linesTooLong.length && $c6WorkspaceValidation.elements[elementId].linesTooLong.includes(i + 1)}
                                {$_('text.error.tooLong')}
                            {/if}
                {/if}</span>

                                <span class="creator6-warning-message" aria-live="polite"
                                      id="{elementId}___w{i}"></span>

                            </label>
                            {#if dragEnabled}
                        <span class="creator6-autotext-sortline">
                            <span class="colop-icon-sort"></span>
                        </span>
                            {/if}

                        </div>
                    {/each}
                </section>
                {#if showTextControls}
                    <div class="creator6-autotext-controls-bottom">
                        <label class="creator6-textlineselector creator6-textlineselector-all">
                            <input type="checkbox" on:change={togglecheckboxes}/>
                            <span class="checkmark"></span>
                            <span class="creator6-control-label">{isAllCheck ? $_('text.control.deselectall') : $_('text.control.selectall')}</span>
                        </label>
                        {#if (showTextControlsSpecific && showTextControlsSpecificSettings.addLines) }
                            <button class="creator6-autotext-addline" on:click={(e) => {items.push({
                        id:items.length, title: "new"
                    }); items = items; selectedLines[items.length-1] = false; addTextLine(elementId)} }  disabled="{(items.length < addMaxLines) ? null : 'disabled'}"><span
                                    class="colop-icon-add"></span><span
                                    class="buttontext">{$_('text.control.addline')}</span></button>
                        {/if}
                        {#if showTextControlsSpecific && showTextControlsSpecificSettings.sortLines }
                            <div class="creator6-autotext-sortlines">
                                <span>{$_('text.control.sort')}</span>
                                <Switch bind:checked={dragEnabled}/>
                            </div>
                        {/if}

                    </div>
                    <div class="creator6-autotext-controls-bottom">
                        <button class="creator6-autotext-clearlines"
                                on:click={(e) => clearTextLines()}><span
                                class="colop-icon-delete"></span>{$_('text.control.clearlines')}</button>
                    </div>

                    <div class="creator6-autotext-controls creator6-autotext-controls-block">
                        <div class="creator6-control-arealabel">{$_('text.control.textblocksettings')}</div>

                        {#if showTextControlsSpecific && showTextControlsSpecificSettings.showTextControlsAdmin }
                            <div class="creator6-autotext-alignmentblock fixedWidth">
                                <div class="creator6-button-input-button-group">
                                    <div class="creator6-control-label">{$_('text.control.fixedWidth')} <span class="colop-icon-pointer-right"></span><span class="colop-icon-pointer-left"></span></div>
                                    <div class="creator6-input-only creator6-input-only-mm">
                                        <input type="number" step="0.1" bind:value={fixedWidth} min="{0}" max={$c6WorkspaceMediumWidth} on:keyup={(e) => previewFixedWidth(e, e.target.valueAsNumber)} on:focusout={(e) => submitFixedWidth(e, e.target.valueAsNumber)}/>
                                    </div>
                                </div>
                            </div>
                        {/if}

                        <div class="creator6-autotext-alignmentblock blockh">
                            <TextblockAlignmentButtons options={blockHAlignmentOptions} fontSize={16} bind:valueSelected={blockHAlignment}
                                                       bind:valueClicked={updateBlockHAlignment}/>
                            {#if blockHAlignment !== "Center" }
                                <div class="creator6-button-input-button-group">
                                    <div class="creator6-control-label"><span class="{blockHAlignmentIcon}"></span></div>
                                    <div class="creator6-input-only creator6-input-only-mm">
                                        <input type="number" step="0.1" bind:value={blockHPadding[0]} min="{0}" max={$c6WorkspaceMediumWidth} on:keyup={(e) => previewHpadding(e, e.target.valueAsNumber)} on:focusout={(e) => submitHpadding(e, e.target.valueAsNumber)}/>
                                    </div>
                                </div>
                                <div class="creator6-autotext-alignmentblock-control-slider">
                                    <RangeSlider values={blockHPadding} step={0.5} min={0} max={$c6WorkspaceMediumWidth} float on:change={(e) => previewHpadding(e, e.detail.value)} on:stop={(e) => submitHpadding(e, e.detail.value, e.detail.startvalue)} />
                                </div>
                            {/if}
                        </div>

                        <div class="creator6-autotext-alignmentblock blockv">
                            <TextblockAlignmentButtons options={blockVAlignmentOptions} fontSize={16} bind:valueSelected={blockVAlignment}
                                                       bind:valueClicked={updateBlockVAlignment}/>
                            {#if blockVAlignment !== "Middle" }
                                <div class="creator6-button-input-button-group">
                                    <div class="creator6-control-label"><span class="{blockVAlignmentIcon}"></span></div>
                                    <div class="creator6-input-only creator6-input-only-mm">
                                        <input type="number" step="0.1" bind:value={blockVPadding[0]} min="{0}" max={$c6WorkspaceMediumHeight} on:keyup={(e) => previewVpadding(e, e.target.valueAsNumber)} on:focusout={(e) => submitVpadding(e, e.target.valueAsNumber)}/>
                                    </div>
                                </div>
                                <div class="creator6-autotext-alignmentblock-control-slider">
                                    <RangeSlider values={blockVPadding}    step={0.5} min={0} max={$c6WorkspaceMediumHeight} float on:change={(e) => previewVpadding(e, e.detail.value)} on:stop={(e) => submitVpadding(e, e.detail.value, e.detail.startvalue)} />
                                </div>
                            {/if}
                        </div>
                    </div>

                {/if}
            </div>

            {#if !autoText && showTextControls && showTextControlsAdmin}
                <div class="creator6-autotext-controls-right">
                    <div>
                        DATA FOR THIS GROUP:
                        <textarea bind:value={geometry}></textarea>
                        <button on:click={(e) => updateGeometry(elementId, geometry)}>Update Geometry Data</button>
                    </div>
                    <div>
                        CUSTOM DATA:
                        <textarea bind:value={customData}></textarea>
                    </div>
                </div>
            {/if}
            {#if textInfoShow}
                <div class="creator6-text-info">
                    <div class="creator6-text-info-label">{@html textInfoLabel}</div>
                    <div class="creator6-text-info-details">{@html textInfoDetail}</div>
                </div>
            {/if}
        </div>
    {/if}
{/await}


<style>

    .creator6-autotext-fontstyle {
        display: flex;
    }

    .creator6-autotext {
        position: relative;
        display: inline-block;
        width: 100%;
        border-top: 10px solid transparent;
        margin-bottom: 2px;
        margin-right: 10px;
        --field-padding: 8px;
        left: 10px;
        top: 0;
    }

    .creator6-autotextselection input[type=checkbox] {
        display: none;
    }

    .creator6-autotextselection .autolayoutlabel {
        border: 1px solid #000;
        display: inline-block;
        padding: 3px;
        /* background: url("unchecked.png") no-repeat left center; */
        /* padding-left: 15px; */
    }

    .creator6-autotextselection input[type=checkbox]:checked + .autolayoutlabel {
        background: #f00;
        color: #fff;
        /* background-image: url("checked.png"); */
    }

    .creator6-autotext input {
        border: 2px solid transparent;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: var(--creator6-controls-bg);
        border-radius: var(--creator6-border-radius);
        width: 100%;
        padding: var(--field-padding);
        margin: 0;
    }

    .creator6-autotext input:not(:placeholder-shown) + .placeholder,
    .creator6-autotext input:focus + .placeholder {
        display: none;
        top: -10px;
        font-size: var(--creator6-fontsize-smaller);
        height: 10px;
        color: #222;
    }

    .creator6-autotext input:focus + .placeholder {
        color: orange;
    }

    .creator6-autotext input:placeholder-shown + .placeholder {
        display: none;
        height: 16px;
    }

    .creator6-autotext input:placeholder-shown {
        background: var(--creator6-controls-hover);
    }

    .creator6-autotext .placeholder {
        display: none;
        position: absolute;
        left: var(--field-padding);
        width: calc(100% - (var(--field-padding) * 2));
        bottom: 50%;
        top: 16px;
        transform: translateY(-50%);
        color: #aaa;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
    }

    .creator6-autotext input:placeholder-shown + .placeholder + .creator6-autotext-line-remove {
        display: block;
    }

    .creator6-autotext input:focus + .placeholder + .creator6-autotext-line-remove {
        display: block;
    }

    .creator6-autotext .creator6-autotext-line-remove {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 36px;
        z-index:1000;
    }

    .creator6-autotext .creator6-autotext-line-remove button {
        border-radius: var(--creator6-border-radius);
        height: 36px;
        width: 40px;
        cursor: pointer;
    }

    .creator6-headline-message {
        font-size: var(--creator6-fontsize-smaller);
        margin-left: 10px;
    }

    .creator6-autotext .creator6-error-message, .creator6-autotext .creator6-warning-message, .creator6-autotext .creator6-invalid-message {
        width: auto;
        max-width: 90%;
        display: flex;
        align-items: center;
        padding: 0 8px;
        font-size: var(--creator6-fontsize-smaller);
        background: #d30909;
        color: #fff;
        height: 36px;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: var(--creator6-border-radius);
    }

    .creator6-autotext .creator6-warning-message {
        background: #fb7e3f;
    }

    .creator6-autotext .creator6-invalid-message {
        display: none;
    }

    .creator6-autotext input:invalid + .creator6-invalid-message,
    .creator6-autotext input:invalid + .placeholder + .creator6-invalid-message,
    .creator6-autotext input:invalid + .placeholder + .creator6-autotext-line-remove + .creator6-invalid-message {
        display: flex;
    }


    .creator6-autotext .creator6-error-message:empty, .creator6-autotext .creator6-warning-message:empty {
        display: none;
    }

    .creator6-autotext.creator6-autotext-error input, .creator6-autotext.creator6-autotext-error input:focus {
        border: 2px solid var(--creator6-error-color);
    }

    .creator6-autotext input:focus {
        border: 2px solid var(--creator6-color-blue);
    }

    .creator6-autotext-lines {
        margin: 0 10px;
    }


    .creator6-autotext input:invalid {
        border: 2px solid var(--creator6-error-color);
        box-shadow: none;
    }


    .creator6-autotext-line-remove button {
        background: transparent;
        border: none;
    }


    /*controls bar*/

    .creator6-autotext-controls {
        display: flex;
        width: 100%;
        align-items: start;
        background: #fff;
        padding: 0;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .creator6-autotext-controls div:not(.creator6-control-arealabel) {
        display: flex;
        align-items: center;
        white-space: nowrap;
        height: 40px;
        border: 1px solid var(--creator6-controls-bg);
        border-radius: 25px;
        background: var(--creator6-controls-bg);
        margin: 5px;
        position: relative;
    }

    .creator6-button-input-button-group input {
        width: 60px;
        -moz-appearance: textfield;
        text-align: right;
        border: none;
        background: #fff;
        height: 29px;
        border-bottom: 1px solid #f3f3f3;
        padding-right: 22px;
        color: var(--creator6-color-blue);

    }

    .creator6-button-input-button-group button {
        height: 100%;
        width: 40px;
        border: none;
        font-size: var(--creator6-fontsize-default);
        background: none;
        cursor: pointer;
    }

    .creator6-button-input-button-group button:hover {
        background: var(--creator6-controls-hover);
        border-radius: var(--creator6-border-radius);
    }

    /*.creator6-button-input-button-group button:first-of-type{
        border-right:1px solid #aaa;
    }
    .creator6-button-input-button-group button:last-of-type{
        border-left:1px solid #aaa;
        border-radius: 0 var(--creator6-border-radius) var(--creator6-border-radius) 0;
    }*/

    .creator6-button-input-button-group::after {
        position: absolute;
        right: 40px;
        font-size: var(--creator6-fontsize-smaller);
        color: #aaa;
        text-align: left;
    }

    .creator6-autotext-fontsize::after {
        content: "pt";
    }

    .creator6-autotext-letterspacing::after {
        content: "%";
    }

    .creator6-autotext-linespacing::after {
        content: "%";
    }

    .creator6-autotext-radius::after {
        content: "%";
    }

    .creator6-autotext-indent::after {
        content: "mm";
    }

    .creator6-autotext-controls div.creator6-autotext-invert,
    .creator6-autotext-controls div.creator6-autotext-forcertl {
        border: none;
        background: transparent;
    }


    /* The container */
    .creator6-textlineselector {
        display: inline-block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 17px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .creator6-textlineselector input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: var(--creator6-controls-bg);
        text-align: center;
        opacity: 0.5;
        font-size: var(--creator6-fontsize-default);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* On mouse-over, add a grey background color */
    .creator6-textlineselector:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .creator6-textlineselector input:checked ~ .checkmark {
        background-color: var(--creator6-controls-selected);
        opacity: 1;
        color: var(--creator6-controls-selected);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .creator6-textlineselector input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .creator6-textlineselector .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    /* text lines */

    .creator6-autotext-textline {
        display: flex;
        align-items: center;
    }


    .creator6-autotext-sortline {
        height: 40px;
        width: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    /*below textlines */

    .creator6-autotext-controls-bottom {
        margin: 10px 0;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .creator6-autotext-controls-block {
        margin: 10px 0;
        display: flex;
        align-items: baseline;
        flex-direction: column;
    }

    .creator6-textlineselector-all .creator6-control-label {
        font-size: var(--creator6-fontsize-default);
        margin-left: 16px;
        white-space: nowrap;
    }

    .creator6-autotext-addline {
        border-radius: var(--creator6-border-radius);
        background: var(--creator6-controls-hover);
        height: 40px;
        border: 1px solid var(--creator6-controls-hover);
        padding: 5px;
        cursor: pointer;
        width: 100%;
        margin: 0 20px;
    }

    .creator6-autotext-sortlines {
        display: inline-flex;
        align-items: center;
    }

    .creator6-autotext-sortlines span {
        white-space: nowrap;
    }

    .creator6-text-info {
        border: 1px solid var(--creator6-color-blue);
        background: #2b77bc;
        margin: 10px 0 0 0;
        padding: 10px;
        color: white;
    }

    .creator6-info-message {
        font-size: var(--creator6-fontsize-small);
        display: block;
        padding: 2px 5px;
        position: absolute;
        right: 0;
        bottom: 0;
        text-align: right;
        font-style: italic;
    }


    .creator6-autotext-clearlines {
        border-radius: var(--creator6-border-radius);
        background: white;
        height: 40px;
        border: 1px solid var(--creator6-controls-hover);
        padding: 5px;
        cursor: pointer;
    }


    /* custom fields */
    .creator6-autotext input.autolayout-customfield {
        width: auto;
    }

    .creator6-autotext input.autolayout-customfield:read-only {
        background: transparent;
        border: none;
    }

    .creator6-autotext .creator6-custom-static-text {
        font-size: var(--creator6-fontsize-smaller);
        padding-right: 5px;
    }

    .creator6-custom-input-fields {
        --border: 2px solid var(--creator6-controls-bg);
        --borderRadius: var(--creator6-border-radius);
        --borderFocusColor: var(--creator6-color-blue);
        --height: 37px;
        --itemIsActiveBG: var(--creator6-controls-hover);
        display: flex;
        flex-flow: wrap;
        align-items: center;
    }

    .creator6-custom-input-fields select {
        height: 37px;
        background: var(--creator6-controls-bg);
        border: 1px solid var(--creator6-controls-bg);
        border-radius: var(--creator6-border-radius);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 5px;
    }

    .creator6-textreplacement-dropdown-arrow {
        position: absolute;
        right: 10px;
        top: 50%;
    }

    .creator6-autotext-controls div.creator6-autotext-alignmentblock-control-slider {
        width:90%;
        min-width: 200px;
        display:inline-block;
        margin-top: 0.5em;
        margin-left: 1em;
        margin-right: 1em;
    }


    @media (max-width: 768px) {
        .creator6-autotext-sortlines {
            display: none;
        }

        .creator6-autotext-addline .buttontext {
            margin: 0 0 0 20px;
            font-size: 0;
        }

    }

    @media (max-width: 400px) {
        .creator6-controls .creator6-tab-text {
            width: 100%;
            float: none;
            margin-right: 0;
        }

        .creator6-controls .creator6-tab-textactions {
            width: 100%;
            float: none;
        }
    }
</style>