<script>
    import Loader from "../../components/Loader.svelte";
    import {loadElements, saveAsDocument} from "../../resources/js/creator6-core";

    let cdocId;
    let cpermalinkId;
    let previewImageUrl;
    let productionImageLink;
    let loading = false;
    let showError = false;
    let errorMessage = "";
    import {
        c6SelectedPadColor,
        c6SelectedTemplate,
        c6ReturnFormUrl,
        c6ReturnFormMethod,
        c6DownloadProductionFile,
        c6PreviewBaseUrl,
        c6SceneryCanvas,
        c6ImprintImages,
        c6ImprintFrames,
        c6SaveUrl,
        c6ImprintTexts,
        c6ImprintDatefields,
        c6LanguageIso,
        c6ProductName,
        c6ProductId,
        c6UiVersion,
        c6CartValues,
        c6WorkspaceConfirmed, c6WorkspaceReady2Leave
    } from "../../resources/js/stores";
    import {_} from "svelte-i18n";

    async function returnData(e) {
        e.preventDefault();
        c6WorkspaceReady2Leave.set(true);
        const node = e.currentTarget;
        node.disabled = true;
        showError = false;
        loading = true;
        const formData = new FormData(e.target);
        const data = {};
        for (let field of formData) {
            const [key, value] = field;
            data[key] = value;
        }
        var imprintname = "cdoc-"+$c6UiVersion;
        var cdocData = await saveImprint(imprintname);
        var dbData = await saveData(cdocData);
        var storeIt = await saveToDb(dbData);
        document.getElementById('creator6returnform').submit();
    }

    async function saveToDb(data){
        return await fetch($c6SaveUrl, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then((response) => {
            //console.log(response);
            return response.status;
        });
    }
    async function saveData(cdocData) {
        cdocId = cdocData.documentId;
        cpermalinkId = cdocData.permalinkId;
        previewImageUrl = $c6PreviewBaseUrl+"preview/"+cpermalinkId;
        productionImageLink = $c6DownloadProductionFile+cdocId;

        let imprinttext = [];
        let imprintTexts = $c6ImprintTexts;
        Object.keys(imprintTexts).forEach(key => {
            imprintTexts[key].forEach(text =>{
                imprinttext.push(text);
            });
        });

        var data = {};
        data.docId = cdocId;
        data.permalinkId = cpermalinkId;
        data.templateId = $c6SelectedTemplate;
        data.padColor = $c6SelectedPadColor;
        data.language = $c6LanguageIso;
        data.productName = $c6ProductName;
        data.productId = $c6ProductId;
        data.text = imprinttext;
        data.dateCount = $c6ImprintDatefields;
        data.imageCount = $c6ImprintImages;
        data.frameCount = $c6ImprintFrames;
        return data;
    }

    async function loadSaveDetails(){
        let elements = await loadElements($c6SceneryCanvas);
        let images = 0;
        let frames = 0;
        let datefields = 0;
        elements.forEach(function(element){
            if(element.sceneElement.elementType === "Image"){
                images++;
            }
            else if(element.sceneElement.elementType === "Frame" && element.sceneElement.model.strokeWidth !== 0){
                frames++;
            }
            else if(element.sceneElement.model.textType === "Dater"){
                datefields ++;
            }
        });
        c6ImprintImages.set(images);
        c6ImprintFrames.set(frames);
        c6ImprintDatefields.set(datefields);
        return true;
    }

    async function saveImprint(imprintname) {
        let cdocData = await saveAsDocument(imprintname);
        let loadDetails = await loadSaveDetails();
        return cdocData;
    }
</script>

<form id="creator6returnform" method="{$c6ReturnFormMethod}" action="{$c6ReturnFormUrl}" on:submit|preventDefault={returnData}>
    <input type="hidden" value="{$c6ProductId}" name="productId" />
    <input type="hidden" value="{cdocId}" name="renderingId" />
    <input type="hidden" value="{$c6SelectedPadColor}" name="padColor" />
    <input type="hidden" value="{$c6SelectedTemplate}" name="templateId" />
    <input type="hidden" value="{$c6CartValues}" name="cartValues" />
    <input type="hidden" value="{previewImageUrl}" name="previewImageUrl" />
    <input type="hidden" value="{productionImageLink}" name="productionImageLink" />
    <label><input type="checkbox" bind:checked={$c6WorkspaceConfirmed} /> {$_('addtocart.confirm.text')} </label>
    <button disabled="{!($c6WorkspaceConfirmed===true)?'disabled':null}" type="submit" >
        {#if loading}
            <Loader size="20" color="#fff" background="transparent" transparent="true" />
        {:else }
            {$_('addtocart.submit.button')}
        {/if}
        </button>
    {#if showError}
        <div class="creator6-addtocart-error-message">{$_('addtocart.error.cannotsave')} {errorMessage}</div>
    {/if}
</form>


<style>
    button {
        background: var(--creator6-color-blue);
        border: 1px solid var(--creator6-color-blue);
        border-radius: 10px;
        padding: 0.4em;
        color: white;
        font-weight: bold;
        width: 90%;
        margin: 1em;
        display: inline-block;
        cursor: pointer;
    }

    button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
</style>