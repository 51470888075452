<script>
    import {
        printEosProductionFile,
        printEosNupProductionFile,
        getPlateProductionUrl,
        getPlateProductionUrlNup,
        showFile
    } from '../../../resources/js/creator6-productionservice';
    import Switch from "../../controls/Elements/Switch.svelte";
    import {_} from '../../../services/i18n';
    import Loader from "../../../components/Loader.svelte";

    import {
        c6ProductionServiceId,
        c6LanguageIso,
        c6SelectedPadColor,
        c6ProductPadColorsXpress,
        c6ProductIcUrl,
        c6ProductionMediasheet,
        c6ProductName,
        c6PreviewPdfTemplateUrl,
        c6WorkspaceMedium,
        c6PreviewPdfEndpoint,
        c6SceneryCanvas,
        c6ImprintImages,
        c6ImprintFrames,
        c6ImprintDatefields,
        c6PreviewBaseUrl,
        c6DownloadProductionFile,
        c6ImprintTexts,
        c6SelectedTemplate,
        c6ProductId,
        c6SaveUrl,
        c6ProductDocumentId,
        c6LogUrl, c6ApiConnector, c6EngineCanvas, c6WorkspaceId, c6ImprintPhotos, c6WorkspaceReady2Leave
    } from "../../../resources/js/stores";
    import {loadElements} from "../../../resources/js/creator6-core";
    import {getImageElementData} from "../../../resources/js/creator6-image";

    let
        xpressAvailable = !!($c6ProductPadColorsXpress.includes($c6SelectedPadColor)),
        nupAvailable = !!($c6ProductionServiceId === "ms" || $c6ProductionServiceId === "fr17"),
        imageCardAvailable = ($c6ProductIcUrl && $c6ProductIcUrl.replace(/^\s+|\s+$/g, "") !== ""),
        flashMachine = 0,
        flashMachineId = "FS800",
        pathPrefix = '',
        productionServiceUrl;

        $:flashMachine, setFlashMachineId(flashMachine);
        $:$c6SelectedPadColor, xpressAvailable = !!($c6ProductPadColorsXpress.includes($c6SelectedPadColor));

        function setFlashMachineId(flashMachine){
            flashMachineId =  (flashMachine) ? "FS800" : "FS1200";
        }

    let loading = false;
    let showError = false;
    let errorMessage = "";

    export let xpress = false;
    async function openProductionFile(e, filename, printMedium)
    {
        c6WorkspaceReady2Leave.set(true);
        const node = e.currentTarget;
        node.disabled = true;
        loading = true;
        const details = await loadSaveDetails();
        const data = await saveData(printMedium);
        const stored = saveToDb(data);
        let fetchUrl;
        if (printMedium === "productionSheet"){
            fetchUrl = await getPlateProductionUrlNup(1, filename, $c6ProductionServiceId, xpress, flashMachine, pathPrefix, productionServiceUrl );
        }
        else {
            const imageCardDefaultUrl = (imageCardAvailable && $c6ProductIcUrl === "default");
            const imageCardUrl = (imageCardAvailable && imageCardDefaultUrl) ? "" : $c6ProductIcUrl;
            fetchUrl = await getPlateProductionUrl(1, filename, printMedium, $c6ProductionServiceId, imageCardUrl, xpress, $c6SelectedPadColor, $c6LanguageIso, productionServiceUrl );
        }
        const platePreview = fetch(fetchUrl, {
            method: 'GET'
            //body: formData
        }).then(r => r.blob()).then(r => {
            if(r.type !== "application/pdf"){
                errorMessage = $_('eosoutput.error.nofile');
                showError = true;
                node.disabled = false;
                loading = false;
                return Promise.reject(errorMessage);
            }
            else {
                showFile( r,filename);
                showError = false;
                node.disabled = false;
                loading = false;
                return Promise.resolve("ok");
            }
        });
    }

    async function saveToDb(data){
        return await fetch($c6LogUrl, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then((response) => {
            //console.log(response);
            return response.status;
        });
    }

    async function saveData(printMedium) {
        let imprinttext = [];
        let imprintTexts = $c6ImprintTexts;
        Object.keys(imprintTexts).forEach(key => {
            imprintTexts[key].forEach(text =>{
                imprinttext.push(text);
            });
        });

        var data = {};
        if($c6ProductDocumentId) {
            data.documentId = $c6ProductDocumentId;
        }
        else {
            data.templateId = $c6SelectedTemplate;
        }
        data.padColor = $c6SelectedPadColor;
        data.language = $c6LanguageIso;
        data.productId = $c6ProductId;
        data.text = imprinttext;
        data.dateCount = $c6ImprintDatefields;
        data.imageCount = $c6ImprintImages;
        data.frameCount = $c6ImprintFrames;
        data.photoCount = $c6ImprintPhotos;
        data.printMedium = printMedium;
        data.xpress = xpress;
        return data;
    }

    async function loadSaveDetails(){
        let elements = await loadElements($c6SceneryCanvas);
        let images = 0;
        let photofilter = 0;
        let frames = 0;
        let datefields = 0;

        for (const element of elements) {
            if(element.sceneElement.elementType === "Image"){
                images++;
                let currentElementData = await getImageElementData($c6ApiConnector, $c6EngineCanvas, $c6WorkspaceId, element.id);
                let imageFilter = (currentElementData.imageFilters) ? currentElementData.imageFilters[0] : "None";
                if(imageFilter === "Halftone"){
                    photofilter++
                }
            }
            else if(element.sceneElement.elementType === "Frame" && element.sceneElement.model.strokeWidth !== 0){
                frames++;
            }
            else if(element.sceneElement.model.textType === "Dater"){
                datefields ++;
            }
        }

        c6ImprintImages.set(images);
        c6ImprintPhotos.set(photofilter);
        c6ImprintFrames.set(frames);
        c6ImprintDatefields.set(datefields);

        return true;
    }

</script>

<div class="creator6-section-div">
{#if xpressAvailable}
<div class="creator6-eosoutput-printtype">
    <span>{$_('eosoutput.print.standard')}</span>
    <Switch bind:checked={xpress} />
    <span>{$_('eosoutput.print.xpress')}</span>
</div>
{/if}

<button on:click={(e) => openProductionFile(e, 'velluma6', 'vellum-A6')}>
    {#if loading}
        <Loader size="20" color="#fff" background="transparent" transparent="true" />
    {:else }
        {$_('eosoutput.print.velluma6')}
    {/if}
</button>
    {#if $c6ProductionMediasheet}
<button on:click={(e) => openProductionFile(e, 'mediasheeta4', 'mediasheet-A4')}>
    {#if loading}
        <Loader size="20" color="#fff" background="transparent" transparent="true" />
    {:else }
        {$_('eosoutput.print.mediasheeta6')}
    {/if}
</button>
        {/if}
<button on:click={(e)=> openProductionFile(e, 'productionframe', 'frame-only-vellum')}>
    {#if loading}
        <Loader size="20" color="#fff" background="transparent" transparent="true" />
    {:else }
        {$_('eosoutput.print.platewithproductionframe')}
    {/if}
</button>
    {#if showError}
        <div class="creator6-save-error">{$_('eosoutput.print.errormessage')} {errorMessage}</div>
    {/if}

{#if nupAvailable}
    <div class="creator6-eosoutput-printtype">
        <span>FS-800</span>
        <Switch bind:checked={flashMachine} />
        <span>FS-1200</span>
    </div>
    <button on:click={(e)=> openProductionFile(e, 'productionsheet', 'productionSheet')}>
        {#if loading}
            <Loader size="20" color="#fff" background="transparent" transparent="true" />
        {:else }
            {$_('eosoutput.print.productionsheet')}
        {/if}
    </button>
{/if}

    {#if imageCardAvailable}
        <button class="creator6-eosoutput-imagecard" on:click={(e)=> openProductionFile(e, 'imagecard', 'imagecard')}>
            {$_('eosoutput.print.imagecard')}
        </button>
    {/if}
    <hr>
</div>

<style>
    .creator6-section-div {text-align: center;}

    .creator6-eosoutput-printtype {
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        margin-top: 20px;
    }

    .creator6-eosoutput-printtype span {
        margin:0 10px;
    }

    hr {border:1px solid var(--creator6-button-bg);}

 button {
     background: var(--creator6-color-blue);
     border: 1px solid var(--creator6-color-blue);
     border-radius: 10px;
     padding: 0.4em;
     color: white;
     font-weight: bold;
     width: 90%;
     margin: 1em;
     display: inline-block;
     cursor: pointer;
 }

 button.creator6-eosoutput-imagecard {
     background: var(--creator6-controls-bg);
     color: var(--creator6-controls-boxshadow);
 }
</style>