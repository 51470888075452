<script>
    import _ from 'lodash';
    import {
        deleteSharedAsset,
        getAssetUrl,
        updateMediaId,
        addImage
    } from '../../../resources/js/creator6-image';
    import {
        getCurrentPage
    } from '../../../resources/js/creator6-helper';
    import {
        renderPage
    } from '../../../resources/js/creator6-core';
    import {
        c6WorkspaceId,
        c6SolutionType
    } from '../../../resources/js/stores';
    import Togglebuttons from "../../../../../creator6-ui_backup/src/components/controls/Elements/Togglebuttons.svelte";

    export let cliparts;
    export let clipartCategories;
    export let showCliparts;
    export let imageId;
    export let clipartSrc;
    let c6mPermission = false;

    async function setClipart(clipartId) {
        if(imageId === "empty"){
            imageId = await addImage(getCurrentPage(), clipartId);
        }
        clipartSrc = getAssetUrl(clipartId);
        await updateMediaId(getCurrentPage(), clipartId, $c6WorkspaceId, imageId);
        renderPage();
        return true;
    }

    let start;
    let end;
    let searchTerm = "beruf";

    $: filteredList = cliparts.filter(item => item.name.indexOf(searchTerm) !== -1);

    let param = 'name'
    let order = 'asc'
    let updateSearchTerm = false;

    function clickOutside(node) {

        const handleClick = event => {
            if (node && !node.contains(event.target) && !event.defaultPrevented) {
                node.dispatchEvent(
                    new CustomEvent('click_outside', node)
                )
            }
        }

        document.addEventListener('click', handleClick, true);

        return {
            destroy() {
                document.removeEventListener('click', handleClick, true);
            }
        }
    }

    function handleClickOutside(event) {
        showCliparts = false;
    }
</script>

{#if showCliparts}
   <!-- Filter: <input bind:value={searchTerm} />
    {searchTerm} -->
<div class="creator6-cliparts" use:clickOutside on:click_outside={handleClickOutside}>

    <div class="creator6-clipart-categories">
        <Togglebuttons options={clipartCategories} fontSize={16}  bind:valueSelected={searchTerm} bind:valueClicked={updateSearchTerm}/>
    </div>

    {#each _.orderBy(filteredList, [param], [order]) as clipart (clipart.name) }
        <figure class="creator6-clipart" data-tag="{clipart.name}" on:click={(e) => setClipart(clipart.id) }>

            {#if $c6SolutionType === "c6Manager" && c6mPermission }
                <button on:click={(e) => deleteSharedAsset(clipart.id)} >X</button>
            {/if}
            <img src={getAssetUrl(clipart.id)} alt={clipart.name} />
        </figure>
    {/each}
</div>

{/if}
<style>
    .creator6-cliparts {
        position: absolute;
        height: 40vH;
        background: var(--creator6-controls-bg);
        z-index: 100;
        overflow: scroll;
        width:100%;
        min-height: 200px;
    }
    .creator6-clipart {display:inline-block; margin:10px; cursor: pointer;}
    .creator6-clipart img {max-width:60px; max-height:60px; height:60px; background:white;}
</style>



