import { get } from 'svelte/store';
import {c6EngineCanvas, c6WorkspaceZoom, c6WorkspaceZoomFactor} from "./stores";

let canvasWidthOri = 0;
let canvasHeightOri = 0;
let canvasZoomOri = 0;

function setZoom(zoom = get(c6WorkspaceZoom)){
    let engineCanvas = get(c6EngineCanvas);
        if(canvasZoomOri === 0 || canvasZoomOri === undefined){
            canvasWidthOri = engineCanvas.fabricCanvas.getWidth();
            canvasHeightOri = engineCanvas.fabricCanvas.getHeight();
            canvasZoomOri = engineCanvas.fabricCanvas.getZoom();
        }
        var ratio = zoom/canvasZoomOri/100;
        var newWidth = canvasWidthOri*ratio;
        var newHeight = canvasHeightOri*ratio;
        ratio = ratio * get(c6WorkspaceZoomFactor);
        engineCanvas.fabricCanvas.setDimensions({width: newWidth, height: newHeight});
        engineCanvas.fabricCanvas.setViewportTransform([ratio,0,0,ratio,0,0]);
        c6WorkspaceZoom.set(zoom);
    }

function resetZoomData(){
    canvasWidthOri = 0;
    canvasHeightOri = 0;
    canvasZoomOri = 0;
    return true;
}

export { setZoom, resetZoomData };