function showTemplateChooser(templates){
    let templateList = templates.split(",");
    return templateList;

    /*if(templateList.length > 1){
        return templateList;
    }
    else {
        return false;
    }*/
}

export {showTemplateChooser}