<script>
    export let options;
    export let legend = null;
    export let valueSelected = options[0].value;
    export let fontSize = 16;
    export let flexDirection = 'column';
    export let valueClicked;

    const uniqueID = Math.floor(Math.random() * 100)

    const slugify = (str = "") =>
        str.toLowerCase().replace(/ /g, "-").replace(/\./g, "");

    const onAlignmentSelected = (value) => {
        //console.log("alignment clicked --> "+value);
        valueClicked = true;
        return valueClicked;
    }
</script>

<div class="c6-toggle-buttons together"
     aria-labelledby={`label-${uniqueID}`}
     style="font-size:{fontSize}px; flex-direction:{flexDirection}"
     id={`textalignment-${uniqueID}`}>
    {#if legend}
        <div class="legend" id={`label-${uniqueID}`}>{legend} </div>
    {/if}
    {#each options as { value, icon, label }}
        <input
                class="sr-only"
                type="radio"
                id={`textalignment-${uniqueID}`+ slugify(value)}
                bind:group={valueSelected}
                on:click={onAlignmentSelected(value)}
                value={value} /><label for={`textalignment-${uniqueID}`+ slugify(value)}><span class="colop-icon-align_{icon.toLowerCase()}"></span></label>
    {/each}
</div>

<style>

    .c6-toggle-buttons input[type="radio"] {
        visibility: hidden;
    }
    .c6-toggle-buttons label {
        border-radius: var(--creator6-border-radius);
        padding: 8.5px 1em;
        display: unset;
        margin-bottom: 1rem;
    }

    .c6-toggle-buttons input:checked + label {
        background: white;
        box-shadow: none;
        color: var(--creator6-color-blue);
    }
    .c6-toggle-buttons input:checked + label span {
        color: var(--creator6-color-blue);
    }

    .c6-toggle-buttons input:hover + label,
    .c6-toggle-buttons input:focus + label {
        background: var(--creator6-controls-hover);
    }

    .together {
        position: relative;
    }

    .together input {
        position: absolute;
        width: 1px;
        height: 1px;
        top: 0;
        left: 0;
    }

    .together label {
        margin: 0.5em 0;
        border-radius: 0;
        border-right: 0;
    }

    .together label:first-of-type {
        border-radius: var(--creator6-border-radius) 0 0 var(--creator6-border-radius);
        border-right:0;
    }

    .together label:last-of-type {
        border-radius: 0 var(--creator6-border-radius) var(--creator6-border-radius) 0;

    }

</style>
