import { get } from 'svelte/store';
import {creator} from "@tagading/scenery-fabricjs";
import {
    c6ApiConnector,
    c6ImageProxyEndpoint,
    c6PreviewPdfEndpoint,
    c6HalftoneEndpoint,
    c6PermaLinksEndpoint,
    c6ProductionPdfEndpoint,
    c6SolutionType,
    c6ProjBaseUrl
} from "./stores";

function setApiConnector(apiConnector){
    c6ApiConnector.set(apiConnector);
}

async function initCreator6(apiBaseUrl, previewBaseUrl, repo, accessToken, canvasId) {
    return new Promise((resolve, reject) => {
        try {
            apiBaseUrl = apiBaseUrl.replace(/\/$/, "")
            let apiConnector = new creator.CreatorApiConnector({
                frontendApiUrl: `${apiBaseUrl}/creator/frontend-api/api`,
                managerApiUrl: `${apiBaseUrl}/creator/manager-api/api`,
                adminApiUrl: `${apiBaseUrl}/creator/admin-api/api`,
                mediaRepositoryApiUrl: `${apiBaseUrl}/repository-api/api`,
                apiKey: accessToken,
            });
            //c6_apiConnector = apiConnector;
            setApiConnector(apiConnector);


           /* c6_imageProxyEndpoint = `${apiBaseUrl}/service/image-proxy-api/api`;
            c6_halftoneEndpoint = `${apiBaseUrl}/service/halftone-api/api`;
            c6_previewPdfEndpoint = `${previewBaseUrl}/service/plate-preview`;
            c6_productionPdfEndpoint = `${previewBaseUrl}/service/`;
            c6_permaLinksEndpoint = `${apiBaseUrl}/service/permalinks-api/api`;
            //c6_repo = repo;*/
/*
            c6ImageProxyEndpoint.set(`${apiBaseUrl}/image-proxy-api/api`);
            c6HalftoneEndpoint.set(`${apiBaseUrl}/halftone-api/api`);

            if(get(c6SolutionType) === "integration"){
                c6PermaLinksEndpoint.set(get(c6ProjBaseUrl)+`/preview/`);
                c6PreviewPdfEndpoint.set(get(c6ProjBaseUrl)+`/plate-preview/`);
            }
            else {
                c6PermaLinksEndpoint.set(`${apiBaseUrl}/permalinks-api/api/data-urls/`);
                c6PreviewPdfEndpoint.set(`${previewBaseUrl}/plate-preview`);
            }
            if(get(c6SolutionType) === "eos") {
                c6ProductionPdfEndpoint.set(`${previewBaseUrl}/production-service/`);
            } */
            //console.log("init done");
            resolve(true);
        }
        catch (e) {
            reject("Could not init Creator 6");
        }
    });
}



export { setApiConnector, initCreator6};