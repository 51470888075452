<script>
    export let options;
    export let legend;
    export let valueSelected = options[0].value;
    export let fontSize = 16;
    export let flexDirection = 'column'

    const uniqueID = Math.floor(Math.random() * 100)

    const slugify = (str = "") =>
        str.toLowerCase().replace(/ /g, "-").replace(/\./g, "");
</script>

<div class="c6-toggle-buttons together"
     aria-labelledby={`label-${uniqueID}`}
     style="font-size:{fontSize}px; flex-direction:{flexDirection}"
     id={`arcalignment-${uniqueID}`}>
    {#if legend}
        <div class="legend" id={`label-${uniqueID}`}>{legend} </div>
    {/if}
    {#each options as { value, label }}
        <input
                class="sr-only"
                type="radio"
                id={`arcalignment-${uniqueID}`+ slugify(value)}
                bind:group={valueSelected}
                value={value} /><label for={`arcalignment-${uniqueID}`+ slugify(value)}><span class="colop-icon-txt_round_{value.toLowerCase()}_middle"></span></label>
    {/each}
</div>

<style>

    .c6-toggle-buttons input[type="radio"] {
        visibility: hidden;
    }
    .c6-toggle-buttons label {
        border: none;
        border-radius: var(--creator6-border-radius);
        padding: 8.5px 1em;
        display: unset;
        margin-bottom: 1rem;
    }

    .c6-toggle-buttons input:checked + label {
        background: #ddd;
        box-shadow: none;
    }

    .c6-toggle-buttons input:hover + label,
    .c6-toggle-buttons input:focus + label {
        background: #eee;
    }

    .together {
        position: relative;
    }

    .together input {
        position: absolute;
        width: 1px;
        height: 1px;
        top: 0;
        left: 0;
    }

    .together label {
        margin: 0.5em 0;
        border-radius: 0;
        border-right: 1px solid #aaa;
    }

    .together label:first-of-type {
        border-radius: var(--creator6-border-radius) 0 0 var(--creator6-border-radius);
        border-right: 0;
    }

    .together label:last-of-type {
        border-radius: 0 var(--creator6-border-radius) var(--creator6-border-radius) 0;
    }

</style>
