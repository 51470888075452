import {renderPage, registerSelection} from "./creator6-core";
import {getWorkspaceFromId, getPageFromWorkspaceId, getCurrentPage, setWorkspaceId, getWorkspaceId, setSelectedElement, getSelectedElement} from './creator6-helper';
import {Unexpected} from "@tagading/scenery-fabricjs";
import { get } from 'svelte/store';
import {c6WorkspaceFonts, c6ApiConnector, c6EngineCanvas, c6WorkspaceId, c6SceneryCanvas} from "./stores";

function toggleFrame(sceneElementId) {

    var update = getCurrentPage().frameElementUpdate();
    update.getFrame(sceneElementId).subscribe(function (elementModel) {
        if (elementModel instanceof Scenery.Unexpected) {
            console.error('Updater could not get frame element model');
        }
        else {
            if(elementModel.stroke.strokeWidth > 0){
                elementModel.stroke.strokeWidth = 0;
            }
            else {
                elementModel.stroke.strokeWidth = 0.2;
            }
            update.updateFrameStroke(elementModel, elementModel.stroke).subscribe(function (result) {
                renderPage();
            });
        }
    });
}

function deleteImage(sceneElementId) {
    var workspaceId = getWorkspaceId();
    var activePage = getCurrentPage().pageIndex;
    get(c6ApiConnector).getGateway().getFrontendApi().deleteFrameElement(workspaceId, activePage, sceneElementId).subscribe(function (result) {
        if (result.response.success) {
            renderPage();
        }
        else {
            console.error("Could not delete Image with ID " + sceneElementId);
        }
    });
}

async function setFrameBorderRadius(sceneElementId, value) {
    return new Promise((resolve, reject) => {
        var update = getCurrentPage().frameElementUpdate();
        update.getFrame(sceneElementId).subscribe(function (elementModel) {
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get frame element model');
            }
            else {
                elementModel.borderRadius = value;
                update.updateFrameBorderRadius(elementModel, elementModel.borderRadius).subscribe(function (result) {
                    if (result) {
                        renderPage();
                        resolve(true);
                    }
                    else {
                        reject("Could not update Frame with ID " + sceneElementId);
                    }
                    //renderPage();
                });
            }
        });

    });
}

async function setFrameStrokeData(sceneElementId, key, value) {
    return new Promise((resolve, reject) => {
        var update = getCurrentPage().frameElementUpdate();
        update.getFrame(sceneElementId).subscribe(function (elementModel) {
            update.getFr
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get frame element model');
            }
            else {
                /*
                strokeWidth
                 */
                elementModel.stroke[key] = value;
/*
                space	3
                strokeStyle	"SolidStroke"
                strokeWidth	0.2

                length	3.1
                space	3
                strokeStyle	"DashedStroke"
                strokeWidth	0.2

                length	3
                space	3
                strokeStyle	"DottedStroke"
                strokeWidth	0.2

                deleteFrameElement(id: string, sceneElement?: FrameElement): Observable<boolean>;
                updateFrameStroke(model: UpdateFrameRequest, sceneElement?: FrameElement): Observable<boolean>;
                updateFrameShape(model: UpdateFrameRequest, sceneElement?: FrameElement): Observable<boolean>;
                updateFramePadding(model: UpdateFrameRequest, sceneElement?: FrameElement): Observable<boolean>;
                updateFrameGeometry(model: UpdateFrameRequest, sceneElement?: FrameElement): Observable<boolean>;
*/
                update.updateFrameStroke(elementModel, elementModel.stroke).subscribe(function (result) {
                    if (result) {
                        renderPage();
                        resolve(true);
                    }
                    else {
                        reject("Could not update Frame with ID " + sceneElementId);
                    }
                    //renderPage();
                });
            }
        });

    });
}

async function setFrameGeometry(sceneElementId, data) {
    console.log("setFrameGeometry");
    console.log(data);
    return new Promise((resolve, reject) => {
        var update = getCurrentPage().frameElementUpdate();
        update.getFrame(sceneElementId).subscribe(function (elementModel) {
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get frame element model');
            }
            else {
                for (const [key, value] of Object.entries(data)) {

                    console.log(key + " needs to be changed to " + value);
                    elementModel.geometry[key] = value;
                };

                update.updateFrameGeometry(elementModel, elementModel.geometry).subscribe(function (result) {
                    if (result) {
                        renderPage();
                        resolve(true);
                    }
                    else {
                        reject("Could not update Frame Geometry with ID " + sceneElementId);
                    }
                    //renderPage();
                });
            }
        });

    });
}

async function setFramePadding(sceneElementId, value) {
    return new Promise((resolve, reject) => {
        var update = getCurrentPage().frameElementUpdate();
        update.getFrame(sceneElementId).subscribe(function (elementModel) {
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get frame element model');
            }
            else {
                elementModel.padding = value;
                update.updateFramePadding(elementModel, elementModel.padding).subscribe(function (result) {
                    if (result) {
                        renderPage();
                        resolve(true);
                    }
                    else {
                        reject("Could not update Frame Padding with ID " + sceneElementId);
                    }
                    //renderPage();
                });
            }
        });

    });
}

async function setFrameShape(sceneElementId, value) {
    return new Promise((resolve, reject) => {
        var update = getCurrentPage().frameElementUpdate();
        update.getFrame(sceneElementId).subscribe(function (elementModel) {
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get frame element model');
            }
            else {
                elementModel.shape = value;  //Elliptic, Rectangular
                update.updateFrameShape(elementModel, elementModel.shape).subscribe(function (result) {
                    if (result) {
                        //console.log("Frame Shape with ID " + sceneElementId + " successfully updated");
                        renderPage();
                        resolve(true);
                    }
                    else {
                        reject("Could not update Frame with ID " + sceneElementId);
                    }
                });
            }
        });

    });
}

async function getFrameElementData(sceneElementId, apiConnector = get(c6ApiConnector), engineCanvas = get(c6EngineCanvas), workspaceId = get(c6WorkspaceId) ){
    return new Promise((resolve, reject) => {
        let activePage = getCurrentPage().pageIndex;
        apiConnector.getGateway().getFrontendApi().getFrame(workspaceId, activePage, sceneElementId).subscribe(function(existingFrame){
            if (existingFrame instanceof Unexpected) {
                console.error('Updater could not get frame element model');
                reject(false);
            }
            else {
                resolve(existingFrame.response);
            }
        });
    });
}

export { setFrameStrokeData, setFrameShape, setFrameBorderRadius, getFrameElementData, setFrameGeometry, setFramePadding };