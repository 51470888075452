<script>
    import {setupI18n, locale, dir, _} from './services/i18n';
    import {onMount} from 'svelte';
    import Zoom from './components/preview/Zoom.svelte';
    import PreviewActionsArea from "./components/preview/PreviewActionsArea.svelte";
    import TabsArea from "./components/controls/TabsArea.svelte";
    import TemplateChooser from "./components/templatechooser/TemplateChooser.svelte";
    import EosOutput from "./components/addtocart/EosOutput.svelte";
    import Modal from './components/Modal.svelte';

    import {findPosY} from "./resources/js/scrollhelper";
    import {getUiConfigs} from './resources/js/creator6-configuration';

    import {showTemplateChooser} from "./resources/js/creator6-templatechooser"
    import {initCreator6} from "./resources/js/creator6-init";

    import {
        c6Repo,
        c6LanguageShort,
        c6LanguageIso,
        c6CanvasId,
        c6ConfirmationModal,
        c6MyWorkspaces,
        c6SelectedTemplate,
        c6TranslationsUrl,
        c6AccessToken,
        c6ProductId,
        c6ProductName,
        c6ProductCanvasInfo,
        c6ProductCanvasInfoTop,
        c6ProductCanvasInfoWidth,
        c6ProductCanvasInfoHeight,
        c6ProductionServiceId,
        c6ProductPadColors,
        c6ProductPadColorsXpress,
        c6ProductIcUrl,
        c6ProductionMediasheet,
        c6ProductTemplates,
        c6ProductDocumentId,
        c6ProductInputTextlines,
        c6SaveUrl,
        c6LogUrl,
        c6SolutionType,
        c6WorkspaceId,
        c6WorkspacePreviewPadColorClass,
        c6ApiConnector,
        c6ApiBaseUrl,
        c6PreviewBaseUrl,
        c6ProjBaseUrl,
        c6ProjectUiSettings,
        c6LoaderColor,
        c6SelectedPadColor,
        c6DebugIt,
        c6ReturnIds,
        c6ProductDocumentAllowOverwrite,
        c6LoadWorkspaceWithTexts,
        c6LoadedShowClasses,
        c6TextReplacementdropDowns,
        c6ReturnFormUrl,
        c6DownloadProductionFile,
        c6PreviewPdfTemplateUrl,
        c6UiVersion,
        c6CartValues,
        c6RtlBaseUrl,
        c6ImageProxyEndpoint,
        c6HalftoneEndpoint,
        c6PermaLinksEndpoint,
        c6PreviewPdfEndpoint,
        c6ProductionPdfEndpoint,
        c6ProductCanvasInfoTemplate, c6WorkspaceReady2Leave, c6WorkspaceMaxLines
    } from './resources/js/stores.js';
    import Loader from "./components/Loader.svelte";
    import Undo from "./components/preview/Undo.svelte";
    import Redo from "./components/preview/Redo.svelte";
    import GlobalCss from "./components/GlobalCss.svelte";
    import Integration from "./components/addtocart/Integration.svelte";
    import {updatePadColor} from "./resources/js/creator6-padcolor";
    import {saveAsDocument} from "./resources/js/creator6-core";

    let version = "2.0";
    let showLoader = false;
    export let language = undefined;
    let repo = undefined;  //remove
    let accessToken= undefined;  //remove
    export let canvasId= undefined;
    export let loaderColor= undefined;
    export let productId= undefined;
    export let productName= undefined;
    export let productionServiceId= undefined;
    export let canvasInfo= undefined;
    export let canvasInfoTop= undefined;
    export let canvasInfoWidth= undefined;
    export let canvasInfoHeight= undefined;

    export let padColors= undefined;
    export let padColorSelected= undefined;
    export let xpressPadColors = undefined;
    export let icUrl= undefined;
    export let mediasheet= undefined;
    export let allowNup= undefined;
    export let templateIds= undefined;
    export let documentId= undefined;
    export let allowOverwrite= undefined;
    export let inputText= undefined;
    export let userId = undefined;
    let ct = undefined; //remove
    export let returnUrl = undefined;
    export let cartValues= undefined;
     let logUrl= undefined; //remove
     let saveUrl= undefined; //remove
    export let afterSaveRedirectUrl= undefined;
    export let returnIds = "";

    export let maxLines;

    let apiBaseUrl = "https://api.colop-online.com/v6/service/";  //remove
    //export let projBaseUrl = "";
     let previewBaseUrl= undefined; //remove
     let localeUrl= undefined; //remove
    export let loadedShowClasses= undefined;
     let previewPdfTemplateUrl= undefined; //remove

    export let showCanvasInfoTop= undefined;
    export let showCanvasInfoHaW= undefined;

    export let debugIt = false;
    c6DebugIt.set(false);

    let downloadProductionFile;
    let theCanvas;
    let xpress = false;

    export function getCanvas() {
        return theCanvas;
    }

    export async function saveWorkspace(workspaceName) {
        let response = await saveAsDocument(workspaceName);
        return response;
    }

    export async function setPadColor(newColor) {
        await updatePadColor(newColor);
        c6SelectedPadColor.set(newColor);
        return true;
    }

    export function getVersion() {
        return version;
    }

    let y;
    //let templateId = undefined;
    let hideCanvas = "display:none;";
    let enableCanvas = false;
    $:y, checkCanvasCssClass(y);
    let templateList;
    let projectConfig;
    let projectConfigSettings = false;

    let creator6class = "creator6-inline";
    let creator6fullscreenicon = "colop-icon-resize";
    //let myWorkspaces = [];
    let creator6fixedclass = "";
    let startProductBarPos = -1;

    let showControls;
    let showControlsRight;
    let showZoomControls;
    let showRedo;
    let showUndo;
    let showTemplateList = true;
    let error = false;

    async function prepareControls() {
        showControls = await getUiConfigs('workspace.visibility.controls');
        showControlsRight = await getUiConfigs('workspace.visibility.controlsright');
        showZoomControls = await getUiConfigs('workspace.visibility.zoom');
        showRedo = await getUiConfigs('workspace.visibility.redo');
        showUndo = await getUiConfigs('workspace.visibility.undo');
        showCanvasInfoTop = await getUiConfigs('workspace.visibility.canvasinfotop');
        showCanvasInfoHaW = await getUiConfigs('workspace.visibility.canvasinfoheightandwidth');
        return true;
    }

    function getConfigValueFor(configField, dataInput) {
        if (projectConfigSettings) {
            if (dataInput !== undefined && dataInput !== "") {
                return dataInput;
            } else if (projectConfig.settings[configField]) {
                return projectConfig.settings[configField];
            }
        } else {
            if (dataInput !== undefined && dataInput !== "") {
                return dataInput;
            }
        }
        return null;
    }

    onMount(async () => {
        showLoader = true;
        if(productId === undefined || productId === "" || productName === undefined || productName === "" ){
            error = true
            return;
        }
        var mySource = document.getElementById("colop-creator6-ui").getAttribute("src");
        let domain = (new URL(mySource));
        let projBaseUrl = domain.origin;
        let hasStatic = domain.pathname.indexOf("/static/");
        if(hasStatic > 0){
            projBaseUrl = projBaseUrl + domain.pathname.substring(0, hasStatic) + "/";
        }
        projBaseUrl = projBaseUrl.replace(/\/$/, "");

            localeUrl = projBaseUrl+"/creator/locale?code="+language;
            saveUrl = projBaseUrl+"/creator/save";
            logUrl = projBaseUrl+"/creator/log";
            previewBaseUrl = projBaseUrl+"/";  // TODO needed?

            downloadProductionFile = projBaseUrl+"/creator/production-file?docId="; //TODO checkConfig
            c6ProjBaseUrl.set(getConfigValueFor("projBaseUrl", projBaseUrl));
            c6CartValues.set(getConfigValueFor("cartValues", cartValues));
            let configUrl = projBaseUrl+"/creator/config";
            await fetch(configUrl)
                .then((response) => response.json())
                .then((creatorconfig) => {
                    accessToken = creatorconfig.accessToken;
                    apiBaseUrl = creatorconfig.baseUrl;  //api.colop-online.com
                    ct = creatorconfig.ct;
                    repo = creatorconfig.repository;
                    //optional:
                    projectConfig = creatorconfig;
                    let uiSettings = {};
                    if(creatorconfig.settings && creatorconfig.settings.ui ) {
                        uiSettings = creatorconfig.settings.ui;
                        projectConfigSettings = true;
                    }
                    c6ProjectUiSettings.set(uiSettings);

                    previewPdfTemplateUrl = (creatorconfig.previewPdfTemplateUrl) ? projBaseUrl+creatorconfig.previewPdfTemplateUrl : "";
                    c6AccessToken.set(accessToken);
                    c6PreviewPdfTemplateUrl.set(getConfigValueFor("previewPdfTemplateUrl", previewPdfTemplateUrl));
                    c6ImageProxyEndpoint.set(apiBaseUrl+`/image-proxy-api/api`);
                    c6HalftoneEndpoint.set(apiBaseUrl+`/halftone-api/api`);
                    c6PreviewPdfEndpoint.set(!(creatorconfig.previewPdfEndpoint) ? projBaseUrl+`/plate-preview/` : creatorconfig.previewPdfEndpoint);
                    c6PermaLinksEndpoint.set(!(creatorconfig.permaLinksEndpoint) ? projBaseUrl+`/preview/` : creatorconfig.permaLinksEndpoint);
                    c6ProductionPdfEndpoint.set(!(creatorconfig.productionPdfEndpoint && ct === "eos") ? projBaseUrl+"/production-service/" : creatorconfig.productionPdfEndpoint);
                    c6RtlBaseUrl.set(!(creatorconfig.rtlEndpoint) ? projBaseUrl+"/rtl/" : creatorconfig.rtlEndpoint);

                }).catch(error => {
                    //console.log("could not load project Config");
                    projectConfig = {};
                });


        c6TranslationsUrl.set(getConfigValueFor("localeUrl", localeUrl));
        c6LanguageIso.set(getConfigValueFor("language", language));
        c6LanguageShort.set(getConfigValueFor("language", language.substring(0, 2)));
        c6Repo.set(getConfigValueFor("repo", repo));
        c6SaveUrl.set(getConfigValueFor("saveUrl", saveUrl));
        c6ReturnFormUrl.set(getConfigValueFor("returnUrl", returnUrl));
        c6DownloadProductionFile.set(getConfigValueFor("downloadProductionFile", downloadProductionFile));

        c6UiVersion.set(version);

        c6LogUrl.set(getConfigValueFor("logUrl", logUrl));
        c6ApiBaseUrl.set(getConfigValueFor("apiBaseUrl", apiBaseUrl));
        c6PreviewBaseUrl.set(getConfigValueFor("previewBaseUrl", previewBaseUrl));
        c6SolutionType.set(getConfigValueFor("ct", ct));

        let customcCanvasId = getConfigValueFor("canvasId", canvasId);
        (customcCanvasId) ? c6CanvasId.set(customcCanvasId) : null;

        let customLoaderColor = getConfigValueFor("loaderColor", loaderColor);
        (customLoaderColor) ? c6LoaderColor.set(customLoaderColor) : null;

        let c6ConfigReturnIds = getConfigValueFor("returnIds", returnIds);
        (c6ConfigReturnIds) ? c6ReturnIds.set(c6ConfigReturnIds.split(",")) : null;

        let c6ConfigLoadedShowClasses = getConfigValueFor("loadedShowClasses", loadedShowClasses);
        (c6ConfigLoadedShowClasses) ? c6LoadedShowClasses.set(c6ConfigLoadedShowClasses.split(",")) : null;

        c6TextReplacementdropDowns.set(getConfigValueFor("textReplacementdropDowns"));

        const loadTranslations = await setupI18n({
            withLocale: $c6LanguageIso,
            fromUrl: $c6TranslationsUrl
        });

        const init = await initCreator6($c6ApiBaseUrl, $c6PreviewBaseUrl, $c6Repo, $c6AccessToken, canvasId);

        c6ProductId.set(productId);
        c6ProductName.set(productName);
        c6ProductCanvasInfo.set(canvasInfo);
        c6ProductCanvasInfoTop.set(canvasInfoTop);
        c6ProductCanvasInfoHeight.set(canvasInfoHeight);
        c6ProductCanvasInfoWidth.set(canvasInfoWidth);
        c6ProductionServiceId.set(productionServiceId);

        c6ProductPadColors.set(padColors);
        c6ProductPadColorsXpress.set((xpressPadColors && xpressPadColors != "") ? xpressPadColors.split(",") : []);

        if (padColorSelected === undefined && padColors) {
            padColorSelected = padColors.split(",")[0];
        }
        else if (padColorSelected === undefined ) {
            padColorSelected = "none";
        }
        c6SelectedPadColor.set(padColorSelected);

        c6WorkspaceMaxLines.set((maxLines) ? maxLines : 0);

        c6ProductIcUrl.set(icUrl);
        c6ProductionMediasheet.set(mediasheet);

        if (templateIds === undefined && documentId) {
            templateIds = documentId;
        }

        c6ProductTemplates.set(templateIds);
        c6ProductDocumentId.set(documentId);
        let allowDocumentOverwrite = !!(allowOverwrite && documentId);
        c6ProductDocumentAllowOverwrite.set(allowDocumentOverwrite);

        if (inputText) {
            c6ProductInputTextlines.set(inputText);
        }

        await c6SelectedTemplate.set(null);
        showLoader = false;
        hideCanvas = "display:none;";

        templateList = showTemplateChooser($c6ProductTemplates);
    });

    function showTemplateChooserAgain() {
        //templateId = null;
        c6SelectedTemplate.set(null);
        enableCanvas = false;
        c6CanvasId.update(id => id + "1");
        templateList = showTemplateChooser($c6ProductTemplates);
        if (templateList) {
            hideCanvas = "display:none;";
        }
    }

    function toggleFullscreen() {
        creator6class = (creator6class === "creator6-inline") ? "creator6-fullscreen" : "creator6-inline";
        creator6fullscreenicon = (creator6class === "creator6-inline") ? "colop-icon-resize" : "colop-icon-remove";
        startProductBarPos = (creator6class === "creator6-inline") ? -1 : startProductBarPos;
    }

    function checkCanvasCssClass(pageYOffset) {
        let canvasArea = document.getElementById('creator6-canvas-area');
        let canvasBottom = document.getElementById('creator6-canvas-bottom');
        let tabs = document.getElementById('creator6-tab-list');
        if (creator6class === "creator6-fullscreen") {
            if (startProductBarPos < 0) {
                startProductBarPos = findPosY(canvasArea);
            }
            if (pageYOffset > (startProductBarPos)) {
                creator6fixedclass = "creator6-preview-fixed";
                canvasBottom.style.paddingTop = (canvasArea.offsetHeight) + "px";
                let startBar2Pos = startProductBarPos + canvasBottom.clientHeight - canvasArea.offsetHeight;
                if (pageYOffset > (startBar2Pos)) {
                    tabs.style.position = 'fixed';
                    tabs.style.top = canvasArea.offsetHeight + "px";
                } else {
                    tabs.style.position = 'relative';
                    tabs.style.top = 0;
                }
            } else {
                creator6fixedclass = "";
                canvasBottom.style.paddingTop = 0;
                tabs.style.position = 'relative';
                tabs.style.top = 0;
            }
        }
    }
    async function beforeUnload(event) {
        if($c6WorkspaceReady2Leave){
            return;
        }
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
        // more compatibility
        return '';
    }

</script>
<svelte:window bind:scrollY={y} on:beforeunload={beforeUnload}/>
{#if error}
    PRODUCT ID MISSING
    {:else}

<Modal show={$c6ConfirmationModal}>
    <main class="creator6-main-page {($dir === 'rtl') ? 'creator6-main-rtl': ''} {creator6class} {creator6fixedclass}">
        <GlobalCss/>
        <div class="creator6-preview creator6-preview-center" style="{hideCanvas}">
            <div class="creator6-preview-top-buttons">
                <div class="creator6-templatechooser-button">
                    {#if showTemplateList}
                        <button on:click={() => showTemplateChooserAgain()}><span class="colop-icon-prev"></span>
                        </button>
                    {/if}
                </div>
                <div class="creator6-fullscreen-button">
                    <button on:click={() => toggleFullscreen()}><span class="{creator6fullscreenicon}"></span></button>
                </div>
            </div>
            {#if $c6SelectedTemplate}
                {#if showZoomControls}
                    <Zoom/>
                {/if}
            {/if}
            {#if $c6ProductCanvasInfoTop && showCanvasInfoTop}
                <div class="creator6-canvasinfo">{$c6ProductCanvasInfoTop}</div>
            {/if}
            {#if enableCanvas }
                <div id="creator6-canvas-area" class="creator6-canvas-area {$c6WorkspacePreviewPadColorClass}">
                    {#if showUndo }
                        <Undo/>
                    {/if}
                    <div class="creator6-line-container-wrapper">
                        <div class="creator6-line-container-top">
                            {#if $c6ProductCanvasInfoHeight && showCanvasInfoHaW}
                                <div class="creator6-line-container-vertical">
                                    <div class="line arrow-top"></div>
                                    <div class="creator6-canvas-height-value">{$c6ProductCanvasInfoHeight}</div>
                                    <div class="line arrow-bottom"></div>
                                </div>
                            {/if}
                            <canvas id="{$c6CanvasId}"></canvas>
                        </div>
                        <div class="creator6-line-container-bottom">
                            {#if $c6ProductCanvasInfoHeight && showCanvasInfoHaW}
                                <div class="creator6-line-container-spacer">
                                </div>
                            {/if}
                            {#if $c6ProductCanvasInfoWidth && showCanvasInfoHaW}

                                <div class="creator6-line-container">
                                    <span class="line arrow-left"></span>
                                    <span class="creator6-canvas-width-value">{$c6ProductCanvasInfoWidth}</span>
                                    <span class="line arrow-right"></span>
                                </div>
                            {/if}
                        </div>
                    </div>
                    {#if showRedo }
                        <Redo/>
                    {/if}
                </div>
            {/if}
            <div id="creator6-canvas-bottom">
                {#if padColors}
                    {#if xpress}
                    <PreviewActionsArea availablePadColors={xpressPadColors} selectedPadColor="{$c6SelectedPadColor}"/>
                        {:else }
                        <PreviewActionsArea availablePadColors={padColors} selectedPadColor="{$c6SelectedPadColor}"/>
                    {/if}
                {/if}
            </div>
            {#if $c6ProductCanvasInfoTemplate}
                <div class="creator6-canvas-info-bottom">{$c6ProductCanvasInfoTemplate}</div>
            {/if}
        </div>
        {#if $c6SelectedTemplate}
            {#await prepareControls() }
                LOAD CONTROLS
            {:then controlsLoaded}
                <div class="creator6-controls">
                    {#if showControls}
                        <TabsArea/>
                        {#if showControlsRight}
                            <div class="creator6-controls-right">
                                {#if ct === "eos"}
                                    <EosOutput bind:xpress/>
                                {/if}
                                {#if ct === "integration"}
                                    <Integration/>
                                {/if}
                            </div>
                        {/if}
                    {/if}
                </div>
            {/await}
        {:else if templateList}
            <TemplateChooser bind:templateList="{templateList}" bind:enableCanvas={enableCanvas}
                             bind:showTemplateList={showTemplateList} bind:hideCanvas/>
        {:else }
            MISSING TEMPLATE INPUT
        {/if}


        {#if showLoader}
            <Loader fullscreen="true"/>
        {/if}


        {#if debugIt}
            <hr>
            <label for="workspaceId">Workspace Id:</label><input type="text" id="workspaceId"/>
            <label for="elementId">Selected Element:</label><input type="text" id="elementId"/>
            <hr>
            <p><em>accessToken:</em>
                {#if accessToken} {accessToken} {:else } <small>n/a</small> {/if}
            </p>
            <p><em>templateIds:</em>
                {#if templateIds} {templateIds} {:else } <small>n/a</small> {/if}
            </p>
            <p><em>language:</em>
                {#if language} {language} {:else } <small>n/a</small> {/if}
            </p>
            <p><em>productName:</em>
                {#if productName} {productName} {:else } <small>n/a</small> {/if}
            </p>
            <p><em>returnUrl:</em>
                {#if returnUrl} {returnUrl} {:else } <small>n/a</small> {/if}
            </p>
            <p><em>repo:</em>
                {#if repo} {repo} {:else } <small>n/a</small> {/if}
            </p>
            <p><em>apiBaseUrl:</em>
                {#if apiBaseUrl} {apiBaseUrl} {:else } <small>n/a</small> {/if}
            </p>
            <p><em>previewBaseUrl:</em>
                {#if previewBaseUrl} {previewBaseUrl} {:else } <small>n/a</small> {/if}
            </p>
            <p><em>productId:</em>
                {#if productId} {productId} {:else } <small>n/a</small> {/if}
            </p>
            <p><em>userId:</em>
                {#if userId} {userId} {:else } <small>n/a</small> {/if}
            </p>
        {:else}

            <input type="hidden" id="workspaceId"/>
            <input type="hidden" id="elementId"/>
        {/if}


    </main>
</Modal>
    {/if}
<style>

    .creator6-line-container-wrapper, .creator6-line-container-top, .creator6-line-container-bottom {
        display: flex;
    }

    .creator6-line-container-wrapper {
        flex-direction: column;
        color: #666
    }

    .creator6-line-container-vertical {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        width: 50px;
    }

    .creator6-line-container-spacer {
        width: 50px;
    }

    .creator6-line-container-bottom .creator6-line-container-spacer {
        width: 60px;
    }

    .creator6-canvas-width-value, .creator6-canvas-height-value {
        font-size: var(--creator6-fontsize-small);
        color: var(--creator6-textcolor-primary);
    }

    .creator6-line-container-vertical .line {
        flex-grow: 1;
        width: 1px;
        left: 25px;
        background: var(--creator6-textcolor-primary);
        position: relative;
        display: inline-block;
    }

    .creator6-line-container-vertical .line.arrow-bottom:after {
        position: absolute;
        content: '';
        bottom: -3px;
        right: -3px;
        width: 0;
        height: 0;
        border-top: 3px solid var(--creator6-textcolor-primary);
        border-bottom: 3px solid transparent;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
    }

    .creator6-line-container-vertical .line.arrow-top:after {
        position: absolute;
        content: '';
        top: -3px;
        left: -3px;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid var(--creator6-textcolor-primary);
        border-right: 3px solid transparent;
        border-left: 3px solid transparent;
    }

    .creator6-line-container {
        display: flex;
        width: 100%;
        margin: 10px auto;
        align-items: center;
    }

    .creator6-line-container .line {
        flex-grow: 1;
        height: 1px;
        background: var(--creator6-textcolor-primary);
        position: relative;
    }

    .creator6-line-container .line.arrow-right:after {
        position: absolute;
        content: '';
        bottom: -3px;
        right: 0;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid var(--creator6-textcolor-primary);
    }

    .creator6-line-container .line.arrow-left:after {
        position: absolute;
        content: '';
        top: -3px;
        left: 0;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-right: 3px solid var(--creator6-textcolor-primary);
    }


    main {
        padding: 0;
        margin: 0 auto;
        position: relative;
    }

    main.creator6-main-rtl {
        direction: rtl;
    }

    main.creator6-fullscreen {
        position: static;
        top: 0;
        left: 0;
        z-index: 100000;
        width: 100%;
        height: 100vh;
    }

    main.creator6-fullscreen .creator6-canvas-area {
        padding-top: 5px;
    }

    main.creator6-fullscreen.creator6-preview-fixed .creator6-canvas-area {
        background: var(--creator6-preview-area);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        justify-content: center;
    }

    .creator6-preview-top-buttons button {
        border: none;
        background: none;
        padding: 0;
        font-size: var(--creator6-fontsize-big)
    }

    .creator6-preview-top-buttons {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
        top: 0;
    }

    .creator6-fullscreen-button {
        text-align: right;

    }

    .creator6-canvas-area {
        display: inline-flex;
        align-items: center;
        margin-bottom: 5px;
    }


    .creator6-canvasinfo {
        font-size: var(--creator6-fontsize-small);
        opacity: 40%;
    }

    .creator6-preview {
        background-color: var(--creator6-preview-area);
        z-index: 101;
        position: relative;
        padding-top: 20px;
    }

    .creator6-preview.creator6-preview-center {
        text-align: center;
    }

    #creator6-canvas-bottom {
        min-height: 30px;
    }

    .creator6-controls {
        display: flex;
        width: 100%;
        margin: 20px auto;
        align-items: stretch;
    }

    .creator6-controls-right {
        width: 350px;
        background: var(--creator6-controls-bg);
        margin: 29px 20px 20px calc((100% - 1140px) / 4);
    }

    @media (max-width: 1390px) {
        .creator6-controls-right {
            margin: 29px 0 0 0;
        }
    }

    @media (max-width: 768px) {
        .creator6-controls {
            display: block;
        }

        .creator6-controls-right {
            width: 100%;
        }

        .creator6-canvas-area {
            display: flex;
            align-items: center;
            overflow-x: auto;
            margin-bottom: 15px;
            justify-content: center;
        }
    }

    @media (max-width: 400px) {
        .creator6-canvas-area {
            justify-content: unset;
        }
    }

</style>