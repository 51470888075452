<script>
    import { getContext } from 'svelte';
    import { TABS } from './Tabs.svelte';

    const panel = {};
    const { registerPanel, selectedPanel } = getContext(TABS);

    registerPanel(panel);
</script>
<div class="creator6-tab-panel">
    {#if $selectedPanel === panel}
        <slot></slot>
    {/if}
</div>


<style>
    :global(.creator6-panel-content){
        padding:20px 0 0 0;
    }
    :global(.creator6-tab-panel .creator6-panel-content) {
        border-bottom: 1px solid var(--creator6-color-blue);
    }
    :global(.creator6-tab-panel .creator6-panel-content:last-child) {
        border-bottom: none !important;
    }

</style>

