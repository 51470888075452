import {getWorkspaceFromId, getCurrentPage, getCurrentWorkspace} from './creator6-helper';
import {renderPage} from './creator6-core';
import {workspace, page, Unexpected} from "@tagading/scenery-fabricjs";
import { get } from 'svelte/store';
import {c6ApiConnector, c6Repo, c6WorkspaceId, c6WorkspaceMedium} from "./stores";

async function uploadImage(file, existingImageId) {
    let mediaPoolId;
    let uploadDone;
    let activePage = getCurrentPage();
    let ws = get(c6WorkspaceId);
    try {
        mediaPoolId = await createAsset(file);
    } catch (err) {
        console.log(err);
    }
    try {
        uploadDone = await uploadFile(mediaPoolId, file);
        if(uploadDone){
            if(existingImageId !== undefined && existingImageId !== "empty"){
                await updateMediaId(activePage, mediaPoolId, ws, existingImageId);
                return existingImageId;
            }
            else {
                let newImageId = await addImage(activePage, mediaPoolId);
                return newImageId;
            }
        }
    } catch (err) {
        console.log(err);
    }
    return true;
}

async function createAsset(file) {
    return new Promise((resolve, reject) => {
        var ws = getWorkspaceFromId(get(c6ApiConnector), get(c6WorkspaceId));
        ws.createAsset(file.name).subscribe(function (newAsset) {
            if (newAsset instanceof Unexpected) {
                console.error('Could not create workspace asset');
                reject(null);
            }
            else {
                resolve(newAsset.id);
            }
        });
    });
}

async function uploadClipart(file) {
    let mediaPoolId;
    let uploadDone;
    let activePage = getCurrentPage();
    let ws = get(c6WorkspaceId);
    try {
        mediaPoolId = await createSharedAsset(file);
    } catch (err) {
        console.log(err);
    }
    try {
        uploadDone = await uploadFile(mediaPoolId, file);
        if(uploadDone){
            let newImageId = await addImage(activePage, mediaPoolId);
            return newImageId;
        }
    } catch (err) {
        console.log(err);
    }
    return true;
}

function getAssetUrl(assetId){
    let apiConnector = get(c6ApiConnector);
    return apiConnector.getGateway().getFrontendApi().connection.baseUrl + "/data-packages/"+assetId+"/data";
}

async function deleteSharedAsset(assetId) {

    //return new Promise((resolve, reject) => {

        let apiConnector = get(c6ApiConnector);
        var connection = apiConnector.getGateway().getFrontendApi().connection;
        var resource = "workspaces/" + get(c6WorkspaceId) + "/assets/"+assetId+"?shared=true";
        var apiKey = connection.getApiKey();
        let raw = await fetch(connection.baseUrl + "/" + resource, {
            method: 'DELETE',
            headers: {
                Authorization: "Bearer " + apiKey,
                Accept: 'application/json'
            },
            body: JSON.stringify({})
        });

        let data = await raw.json();
        return true;


    //});
}
async function createSharedAsset(file) {

    //return new Promise((resolve, reject) => {

        let apiConnector = get(c6ApiConnector);
        var connection = apiConnector.getGateway().getFrontendApi().connection;
        var resource = "workspaces/" + get(c6WorkspaceId) + "/assets?shared=true&group="+get(c6Repo);
        var apiKey = connection.getApiKey();
        let raw = await fetch(connection.baseUrl + "/" + resource, {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + apiKey,
                Accept: 'application/json'
            },
            body: JSON.stringify({name: file.name})
        });

        let data = await raw.json();
        return data.id;


    //});
}

async function getSharedAssets() {

    //return new Promise((resolve, reject) => {

    let apiConnector = get(c6ApiConnector);
    var connection = apiConnector.getGateway().getFrontendApi().connection;
    var resource = "workspaces/" + get(c6WorkspaceId) + "/assets?shared=true&group="+get(c6Repo);
    var apiKey = connection.getApiKey();
    let raw = await fetch(connection.baseUrl + "/" + resource, {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + apiKey,
            Accept: 'application/json'
        }
    });
    let data = await raw.json();
    return data;
    //});
}

async function uploadFile(mediaPoolId, file){
    return new Promise((resolve, reject) => {

        let apiConnector = get(c6ApiConnector);
        var connection = apiConnector.getGateway().getFrontendApi().connection;
        var formData = new FormData();
        formData.append('file', file);
        var fileExt = file.name.split('.').pop();
        var destinationFormat = (fileExt === "pdf") ? "?destinationFormat=png" : "";
        var resource = "data-packages/" + mediaPoolId + "/data"+destinationFormat;
        //var resource = "data-packages/" + mediaPoolId + "/data";
        var apiKey = connection.getApiKey();
        fetch(connection.baseUrl + "/" + resource, {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + apiKey,
                Accept: 'application/json'
            },
            body: formData
        }).then(function (response) {
            if (response.ok) {
                resolve(true);
            } else {
                reject(false);
            }
        });
    });
}

async function addImage(selectedPage, imageId, geometry = undefined) {
    return new Promise((resolve, reject) => {
        selectedPage.imageElementProvider().createImage(
            imageId,
            workspace.service.image.ImageType.Default,
            [page.update.ImageFilter.Recolor],
            geometry
        ).subscribe(function (newImage) {
            if (newImage instanceof Unexpected) {
                console.error('Could not create workspace asset');
            }
            else {
                resolve(newImage);
            }
        });
    });
}

function uploadPhoto(file) {
    let apiConnector = get(c6ApiConnector);
    var connection = apiConnector.getGateway().getFrontendApi().connection;
    var formData = new FormData();
    formData.append('file', file);
    var halftoneUrl = "https://api.colop-online.com/v6/service/halftone-api/api/filter";
    var apiKey = connection.getApiKey();
    fetch(halftoneUrl, {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + apiKey,
            Accept: 'application/json'
        },
        body: formData
    }).then(function (response) {
        //console.log(response);
    });
}

/**
 * Calculates the position and size for an item so that it fits into a box to its max extend by keeping the ratio of the
 * item. The actual size of the item is irrelevant and is only used to calculate the aspect ratio.
 *
 * @param {number} itemWidth The item width.
 * @param {number} itemHeight The item height.
 * @param {number} offsetX The horizontal offset of the box. Defaults to 0.
 * @param {number} offsetY The vertical offset of the box. Defaults to 0.
 * @param {number} boxWidth The box width.
 * @param {number} boxHeight The box height.
 *
 * @return {{x: number, width: number, y: number, height: number}}
 */
function getBoxFittingGeometry({item: {width: itemWidth, height: itemHeight}, box: {x: offsetX = 0.0, y: offsetY= 0.0, width: boxWidth, height: boxHeight}})
{
    if(!boxWidth || !boxHeight || !itemWidth || !itemHeight) {
        throw "Invalid parameters given";
    }

    const limitingOrientation = itemWidth / itemHeight === boxWidth / boxHeight
        ? 'none'
        : itemWidth / itemHeight > boxWidth / boxHeight
            ? 'horizontal'
            : 'vertical'

    if (limitingOrientation === 'none') {
        return {x: offsetX, y: offsetY, width: boxWidth, height: boxHeight};
    } else {
        const itemAspectRatio = itemWidth / itemHeight;
        const width = limitingOrientation === 'horizontal' ? boxWidth : boxHeight * itemAspectRatio;
        const height = limitingOrientation === 'vertical' ? boxHeight : boxWidth / itemAspectRatio;
        const x = offsetX + (limitingOrientation === 'horizontal' ? 0 : (boxWidth - width) / 2.0);
        const y = offsetY + (limitingOrientation === 'vertical' ? 0 : (boxHeight - height) / 2.0);

        return {x, y, width, height};
    }
}

async function fitImageIntoCanvas(selectedImageId, marginHeight = 0.0, marginWidth = 0.0){
    return new Promise((resolve, reject) => {
        const update = getCurrentPage().imageElementUpdate();
        update.getImage(selectedImageId).subscribe(function (elementModel) {
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get image element model');
            } else {
                const imageWidth = elementModel.geometry.width;
                const imageHeight = elementModel.geometry.height;
                const canvasHeight = get(c6WorkspaceMedium).height;
                const canvasWidth = get(c6WorkspaceMedium).width;
                Object.assign(
                    elementModel.geometry, getBoxFittingGeometry({
                        item: {width: imageWidth, height: imageHeight},
                        box: {width: canvasWidth - marginWidth, height: canvasHeight - marginHeight}
                    }), {rotation: 0}
                );

                update.updateImageGeometry(elementModel).subscribe(function (result) {
                    if (result) {
                        renderPage();
                        resolve(true);
                    } else {
                        reject("Could not update Image with ID " + selectedImageId);
                    }
                });
            }
        });
    });
}

/**
 * Calculates the position and size for an item if it should get scaled up or down by a certain factor. The new position
 * and size will be calculated and returned. The center or the item will be kept, i.e. scaling up/down happens around
 * the center.
 *
 * Hint: Scaling up by 5% and then scaling down by 5% in two sequent function calls does not lead to the original size.
 *
 * @param x The horizontal position of the item.
 * @param y The vertical position of the item.
 * @param width The width of the item.
 * @param height The height of the item.
 * @param factor A relative value that can be positive or negative. E.g. a value of 0.05 will increase the
 * image by 5 percent. -0.1 will shrink it by 10 percent. Must be a value greater -1.0.
 *
 * @return {{width: number, x: number, y: number, height: number}}
 */
function getScaledItemGeometry({x, y, width, height}, factor) {
    if(factor < -1.0) {
        throw "Factor cannot be less the -1.";
    }
    const newWidth = width * (1 + factor);
    const newHeight = height * (1 + factor);
    const newX = x + (width - newWidth) / 2;
    const newY = y + (height - newHeight) / 2;

    return {width: newWidth, height: newHeight, x: newX, y: newY};
}

async function updateImageGeometryByFactor(selectedImageId, factor){
    return new Promise((resolve, reject) => {
        const update = getCurrentPage().imageElementUpdate();
        update.getImage(selectedImageId).subscribe(function (elementModel) {
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get image element model');
            } else {
                Object.assign(
                    elementModel.geometry,
                    getScaledItemGeometry(elementModel.geometry, factor)
                );
                update.updateImageGeometry(elementModel).subscribe(function (result) {
                    if (result) {
                        renderPage();
                        resolve(true);
                    } else {
                        reject("Could not update Image with ID " + selectedImageId);
                    }
                });
            }
        });
    });
}

async function updateImageGeometry(selectedImageId, data){
    return new Promise((resolve, reject) => {
        var update = getCurrentPage().imageElementUpdate();
        update.getImage(selectedImageId).subscribe(function (elementModel) {
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get image element model');
            }
            else {
                let currentWidth = elementModel.geometry.width;
                let currentHeight = elementModel.geometry.height;
                for (const [key, value] of Object.entries(data)) {
                    if(key !== "scaleheight"){
                        elementModel.geometry[key] = value;
                    }
                }

                if('scaleheight' in data){
                    //elementModel.geometry.height = elementModel.geometry.height * elementModel.geometry.width /  currentWidth;
                }
                update.updateImageGeometry(elementModel).subscribe(function (result) {
                    if (result) {
                        resolve(true);
                    }
                    else {
                        reject("Could not update Image with ID " + selectedImageId);
                    }
                });
            }
        });
    });
}

function deleteImage(selectedImageId) {
    let workspaceId = get(c6WorkspaceId);
    let activePage = getCurrentPage().pageIndex;
    let apiConnector = get(c6ApiConnector);
    apiConnector.getGateway().getFrontendApi().deleteImageElement(workspaceId, activePage, selectedImageId).subscribe(function (result) {
        if (result.response.success) {
            renderPage();
        }
        else {
            console.error("Could not delete Image with ID " + selectedImageId);
        }
    });
}

async function updateMediaId(selectedPage, newMediaPoolId, ws, existingImageId) {
    return new Promise((resolve, reject) => {
        let workspaceId = get(c6WorkspaceId);
        let activePage = getCurrentPage().pageIndex;
        let apiConnector = get(c6ApiConnector);
        apiConnector.getGateway().getFrontendApi().getImage(workspaceId, activePage, existingImageId).subscribe(function(existingImage){
            existingImage.response.mediaPoolId = newMediaPoolId;
            apiConnector.getGateway().getFrontendApi().patchImageElement(workspaceId, activePage, existingImageId, existingImage.response).subscribe(function (result) {
                if (result.response.success) {
                    resolve(true);
                }
                else {
                    console.error("Could not Update Image with ID " + existingImageId);
                    reject(false);

                }
            });
        });
    });
}

async function deleteImageMediaPoolId(existingImageId){
    if(existingImageId !== undefined) {
        let activePage = getCurrentPage().pageIndex;
        let workspace = getCurrentWorkspace();
        await updateMediaId(activePage, null, workspace, existingImageId);
        renderPage();
    }
}

async function getImageElementData(apiConnector, engineCanvas, workspaceId, sceneElementId){
    return new Promise((resolve, reject) => {
        let activePage = getCurrentPage().pageIndex;
        apiConnector.getGateway().getFrontendApi().getImage(workspaceId, activePage, sceneElementId).subscribe(function(existingImage){
            if (existingImage instanceof Unexpected) {
                console.error('Updater could not get image element model');
                reject(false);
            }
            else {
                resolve(existingImage.response);
            }
        });
    });
}

async function submitImageFilter(imageId, filter, doUpdate = true){
    if(!doUpdate) return true;
    return new Promise((resolve, reject) => {
        let workspaceId = get(c6WorkspaceId);
        let activePage = getCurrentPage().pageIndex;
        let apiConnector = get(c6ApiConnector);
        apiConnector.getGateway().getFrontendApi().patchElement(workspaceId, activePage, imageId, {"imageFilters":[filter]})
            .subscribe(function (result) {
                renderPage();
                resolve(true);
            });
    });
}

export { getAssetUrl, getSharedAssets, deleteSharedAsset, uploadClipart, uploadImage, updateImageGeometry, addImage, deleteImage, updateMediaId, deleteImageMediaPoolId, uploadPhoto, getImageElementData, submitImageFilter, fitImageIntoCanvas, updateImageGeometryByFactor };