const getFabricValue = (value) => {
    return value * 4.724409449;
}

const getFabricElementById = (canvasFabric, elementId) => {
    let requiredObject = {};
    let BreakException = {};
    try {
        canvasFabric.forEachObject(function(object){
            //console.log("find object");
            //console.log(object);
            if(object.sceneElement && object.sceneElement.id === elementId){
                requiredObject = object;
                throw BreakException;
            }
        });
    } catch (e) {
        if (e !== BreakException) throw e;
    }
    return requiredObject;
}

export {getFabricElementById, getFabricValue};