<script>
    import PrintVellum from "./Eos/PrintVellum.svelte";
    import SaveToArchive from "./Eos/SaveToArchive.svelte";
    import {c6ProductName} from "../../resources/js/stores";
    import {_} from '../../services/i18n';

    export let xpress;
</script>

<section>
    <div class="creator6-production-eos-productname">{$c6ProductName}</div>
</section>
<section>
    <PrintVellum bind:xpress/>
</section>

<section>
    <SaveToArchive />
</section>

<style>
    section {
        padding: 0 20px;
    }
    hr {border:1px solid var(--creator6-button-bg);}

    .creator6-production-eos-productname {
        margin: 10px auto 0;
        font-size: var(--creator6-fontsize-bigger);
        text-align: center;
        color: var(--creator6-textcolor-primary);
    }
</style>