import {renderPage} from "./creator6-core";
import {getCurrentPage} from './creator6-helper';
import {Unexpected, rxjsOperators} from "@tagading/scenery-fabricjs";

async function updatePadColor(value, doPageRender = true, selectedPage = getCurrentPage()) {
    return new Promise((resolve, reject) => {
        selectedPage.setPageProperty({
            key: 'padColor',
            readOnly: false,
            visible: true,
            value: value
        }).subscribe(function (result) {
            //console.log("result pad color");
            if(result){
                if(doPageRender) {renderPage();}
                resolve(true);
            }
            else {
                reject("Could not set pad color");
            }
        });
    });

}

async function readPadColor(selectedPage = getCurrentPage()) {
    return new Promise((resolve, reject) => {
        selectedPage.getPageProperties().pipe(rxjsOperators.first()).subscribe(function (properties) {
            if (properties instanceof Unexpected) {
                reject("Could not read page properties");
            }
            else {
                resolve(properties.padColor);
            }
        });
    });
}

export {updatePadColor, readPadColor}