<script>
    import { getContext } from 'svelte';
    import { TABS } from './Tabs.svelte';

    const tab = {};
    const { registerTab, selectTab, selectedTab } = getContext(TABS);

    export let text;
    export let icon;
    export let specialclass = "";

    export let dataid;

    registerTab(tab);
</script>
<style>
    .creator6-tab-button {
        background: transparent;
        border: 1px solid var(--creator6-tabs-inactive);
        border-radius: 0;
        margin: 0;
        color: var(--creator6-textcolor-primary);
        flex-basis: 25%;
        max-width:100px;
        height:30px
    }

    .creator6-tab-button div {
        display:flex;
        align-items: center;
        margin:0 auto;
        width: max-content;
    }

    .creator6-tab-button div span{
        font-size: var(--creator6-fontsize-default);
        text-transform: uppercase;
    }

    .creator6-tab-button div span[class^="colop-icon-"]{
        font-size: var(--creator6-fontsize-bigger);
        margin-right:5px;
    }

    :global(.creator6-tab-button span) {
        display: block;
    }

    .selected {
        color: #333;
        border-top: 1px solid var(--creator6-color-blue);
        border-left: 1px solid var(--creator6-color-blue);
        border-right: 1px solid var(--creator6-color-blue);
        position:relative;
        top:1px;
        background-color:white;
        border-bottom-style:hidden;

    }

    .creator6-right {float:right;}
</style>
<button class:selected="{$selectedTab === tab}" class="creator6-tab-button {specialclass}" data-id="{dataid}" on:click="{() => selectTab(tab)}"><div>{#if icon}<span class="{icon}"></span>{/if}{#if text}<span class="creator6-tab-button-text">{text}</span>{/if}<slot></slot></div></button>