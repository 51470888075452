import {Unexpected} from '@tagading/scenery-fabricjs';
import {getCurrentPage} from './creator6-helper';

async function prepareMask(selectedPage = getCurrentPage()) {
    return new Promise((resolve, reject) => {
        selectedPage.getPageProperties().subscribe(function (properties) {
            if (properties instanceof Unexpected) {
                reject("Could not read page properties");
            }
            else {
                if (properties.maskColor || properties.maskId || properties.maskImageId) {
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            }
        });
    });
}

async function updateMaskColor(value, selectedPage = getCurrentPage()) {
    return new Promise((resolve, reject) => {
        selectedPage.setPageProperty({
            key: 'maskColor',
            readOnly: false,
            visible: true,
            value: value
        }).subscribe(function (result) {
            if (result instanceof Unexpected) {
                reject("Could not update mask color");
            }
            else {
                resolve(true);
            }
        });
    });
}

export {prepareMask, updateMaskColor }