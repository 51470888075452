<script>
    import {setupI18n} from './services/i18n';
    import {onDestroy, onMount} from 'svelte';
    import Zoom from './components/preview/Zoom.svelte';
    import PreviewActionsArea from "./components/preview/PreviewActionsArea.svelte";
    import TabsArea from "./components/controls/TabsArea.svelte";
    import TemplateChooser from "./components/templatechooser/TemplateChooser.svelte";
    import EosOutput from "./components/addtocart/EosOutput.svelte";
    import Modal from './components/Modal.svelte';

    import {findPosY} from "./resources/js/scrollhelper";
    import {getUiConfigs} from './resources/js/creator6-configuration';

    import {showTemplateChooser} from "./resources/js/creator6-templatechooser"
    import {initCreator6} from "./resources/js/creator6-init";

    import {
        c6Repo,
        c6LanguageShort,
        c6LanguageIso,
        c6CanvasId,
        c6ConfirmationModal,
        c6MyWorkspaces,
        c6SelectedTemplate,
        c6TranslationsUrl,
        c6AccessToken,
        c6ProductId,
        c6ProductName,
        c6ProductCanvasInfo,
        c6ProductCanvasInfoTop,
        c6ProductCanvasInfoWidth,
        c6ProductCanvasInfoHeight,
        c6ProductionServiceId,
        c6ProductPadColors,
        c6ProductPadColorsXpress,
        c6ProductIcUrl,
        c6ProductionMediasheet,
        c6ProductTemplates,
        c6ProductDocumentId,
        c6ProductInputTextlines,
        c6SaveUrl,
        c6LogUrl,
        c6SolutionType,
        c6WorkspaceId,
        c6WorkspacePreviewPadColorClass,
        c6ApiConnector,
        c6ApiBaseUrl,
        c6PreviewBaseUrl,
        c6ProjBaseUrl,
        c6ProjectUiSettings,
        c6LoaderColor,
        c6SelectedPadColor,
        c6DebugIt,
        c6ReturnIds,
        c6ProductDocumentAllowOverwrite,
        c6LoadWorkspaceWithTexts,
        c6LoadedShowClasses,
        c6TextReplacementdropDowns,
        c6ReturnFormUrl,
        c6WorkspaceElementSelected,
        c6WorkspaceElements,
        c6SceneryCanvas,
        c6EngineCanvas, c6WorkspaceLoaded, c6ImageProxyEndpoint, c6WorkspacePageActive
    } from './resources/js/stores.js';
    import Loader from "./components/Loader.svelte";
    import Undo from "./components/preview/Undo.svelte";
    import Redo from "./components/preview/Redo.svelte";
    import GlobalCss from "./components/GlobalCss.svelte";
    import Integration from "./components/addtocart/Integration.svelte";
    import {updatePadColor} from "./resources/js/creator6-padcolor";
    import {deleteCurrentWorkspace, renderPage, saveAsDocument} from "./resources/js/creator6-core";
    import Niofontsize from "./components/controls/Elements/Niofontsize.svelte";
    import ReturnFormNio from "./components/addtocart/ReturnFormNio.svelte";
    import {editTextByElementId, getTextElementData, textLineDetailsUpdate} from "./resources/js/creator6-text";
    import {getFabricElementById} from "./resources/js/fabricjshelper";
    import {getWorkspacePreviewImageUrl} from "./resources/js/creator6-preview";
    import {get} from "svelte/store";
    import {
        deleteImageMediaPoolId,
        fitImageIntoCanvas,
        updateImageGeometryByFactor,
        uploadImage
    } from "./resources/js/creator6-image";

    let version ="0.0.1";
    let canvasOnly = "hallihalloa";
    let showLoader = false;

    export let language;
    export let repo;
    export let accessToken;
    export let canvasId;
    export let loaderColor;
    export let productId;
    export let productName;
    export let productionServiceId;
    export let canvasInfo;
    export let canvasInfoTop;
    export let canvasInfoWidth;
    export let canvasInfoHeight;

    export let padColors;
    export let padColorSelected;
    export let xpressPadColors;
    export let icUrl;
    export let mediasheet;
    export let allowNup;
    export let templateIds;
    export let documentId;
    export let allowOverwrite;
    export let inputText;
    export let userId = undefined;
    export let ct = "integration";
    export let returnUrl;
    export let logUrl;
    export let saveUrl;
    export let afterSaveRedirectUrl;
    export let returnIds = "";

    export let apiBaseUrl = "https://api.colop-online.com/v6/service/";
    export let projBaseUrl = undefined;
    export let previewBaseUrl = "https://production-service.colop.com";
    export let localeUrl;
    export let loadedShowClasses;

    export let debugIt = false;
    c6DebugIt.set(false);

    let theCanvas;


    export const NO_TRANSFORMATION = 0;
    export const TO_UPPER = 1;
    export const TO_LOWER = 2;

    let workspaceLoadedHandler = null;
    let elementSelectedHandler = null;

    /**
     * Deletes the workspace. After calling this all other function will not work anymore. Callback functions that
     * were set with {@see setWorkspaceLoadedHandler} and {@see setElementSelectedHandler} will be removed as well.
     *
     * @return {Promise<void>}
     */
    export const deleteWorkspace = async () => {
        workspaceLoadedHandler = null;
        elementSelectedHandler = null;
        let workSpaceDeleted = await deleteCurrentWorkspace();
        await resetWorkspace();
        console.log("workSpaceDeleted: ");
        console.log(workSpaceDeleted);
    }

    /**
     * Sets a callback which gets called when the workspace is fully loaded, so that other functions can be called.
     * If the workspace is already loaded the passed function will be called immediately.
     *
     * If this function is called multiple times, only the last provided callback gets called.
     *
     * @param {?function:void} handler A function or null.
     * If null, any earlier provided callbacks will be dismissed.
     * @return {void}
     */
    export const setWorkspaceLoadedHandler = handler => {
        if (handler && typeof handler !== 'function') throw 'Handler is not a callback function.';
        workspaceLoadedHandler = handler;
        if (workspaceLoadedHandler && $c6WorkspaceLoaded) {
            workspaceLoadedHandler();
        }
    };

    const c6WorkspaceLoadedUnsubscribe = c6WorkspaceLoaded.subscribe(success => {
        if (success && workspaceLoadedHandler) {
            workspaceLoadedHandler();
        }
    })

    /**
     * Sets a callback which gets called when an element gets selected or if there was a selection which has been
     * cancelled. The first argument of the called function is the id of the element or null.
     *
     * If this function is called multiple times, only the last provided callback gets called.
     *
     * @param {?function(string): void} handler A function or null.
     * If null, any earlier provided callbacks will be dismissed.
     * @return {void}
     */
    export const setElementSelectedHandler = handler => {
        if(handler && typeof handler !== 'function') throw 'Handler is not a callback function.';
        elementSelectedHandler = handler;
    }

    const c6WorkspaceElementSelectedUnsubscribe = c6WorkspaceElementSelected.subscribe(elementId => {
        if (elementSelectedHandler) elementSelectedHandler(elementId);
    })

        /**
     * Creates a document from the current workspace.
     *
     * @return {Promise<{permalinkId: string, documentId: string}>}
     */
    export const saveWorkspaceToDocument = async () => {
        let response = await saveAsDocument();
        return {documentId: response.documentId, permalinkId: response.permalinkId};
    }

    /**
     * Resets the workspace to its original state, like it was after it was loaded from template.
     *
     * @return {Promise<void>}
     */
    export const resetWorkspace = async () => {
        return showTemplateChooserAgain();
    }

    /**
     * Selects an element in the canvas. The element must be selectable.
     *
     * @param {string} id If of the element that should be selected.
     * @return {void}
     */
    export const selectElement = id => {
        let canvasFabric = $c6EngineCanvas.fabricCanvas;
        let sceneryCanvas = $c6SceneryCanvas;
        let elementData = getFabricElementById(canvasFabric, id);
        if(elementData){
           sceneryCanvas.selection.selectElement(id);
        }
    }

    /**
     * Sets the pad color.
     *
     * @param {int} page Page number (first page is 1).
     * @param {string} color A valid pad color, which is known in the Creator repository.
     * @return {Promise<void>}
     */
    export const setPadColor = async (page, color) => {
        let response = await updatePadColor(color);
        if(response === true) {
            c6SelectedPadColor.set(color);
        }
        return response;
    };

    /**
     * Returns the URL to a preview image.
     *
     * @param {int} page Page number (first page is 1).
     * @return {string}
     */
    export const getPreviewImageUrl = page => {
        let workspacePreviewUrl = getWorkspacePreviewImageUrl($c6WorkspaceId, page, 300);
        return workspacePreviewUrl;
    }

    /**
     * Returns the ids of text elements on a page.
     *
     * @param {int} page Page number (first page is 1).
     * @return {[string]}
     */
    export const getTextIds = async (page = 1) => {
        return new Promise((resolve, reject) => {
            let textElements = [];
            const elements = $c6WorkspaceElements;
            elements[page].forEach(function(element){
                let elementId = element.sceneElement.id;
                if(element.sceneElement.elementType === "Text"){
                    textElements.push(elementId);
                }
            });
            resolve(textElements);
        });
    }

    /**
     * Returns meta information about a text element.
     *
     * @param {string} id Id of the text element for which the information should be returned.
     * @return {{id: string, size: number, text: string, textCase: number, font: string, color:string}}
     */
    export const getTextInfo = async (id) => {
        let textElement = await getTextElementData(id);
        let customData = (textElement.dataString) ? JSON.parse(textElement.dataString) : false;
        let textCase = NO_TRANSFORMATION;
        if (customData.transformationRules && customData.transformationRules.textCase){
            textCase = (customData.transformationRules.textCase === "upper") ? TO_UPPER : TO_LOWER;
        }

        return {
            id,
            text: (textElement.lines) ? textElement.lines[0] : "",
            size: textElement.details.textLines[0].fontSize,
            font: textElement.details.textLines[0].fontFamily,
            color: textElement.details.textLines[0].hexColor,
            textCase
        };
    }

    /**
     * Sets the text of a text element.
     *
     * @param {string} id Id of the text element that needs to be adopted.
     * @param {string} text The new text. Must be a string with at least one character.
     * @param {boolean} rtl If true, the text will right-to-left.
     * @return {Promise<void>}
     */
    export const setText = async (id, text, rtl = false) => {
        return editTextByElementId(id, 0, text, rtl);
    }

    /**
     * Sets the text size of a text element.
     *
     * @param {string} id Id of the text element that needs to be adopted.
     * @param {number} size New size. Must be greater 0.
     * @return {Promise<void>}
     */
    export const setTextSize = async (id, size) => {
        return textLineDetailsUpdate(id, [true], 'fontSize', size, false);
    }

    /**
     * Adds an image onto a page. It will be fitted into the page.
     *
     * @param {int} page Page number (first page is 1).
     * @param {File} file A file object that contains an image, which can be: JPG, PNG or PDF.
     * @return {Promise<string>} The id of the new image.
     */
    export const addImage = async (page, file) => {
        let newImageId = await uploadImage(file);
        console.log("image added");
        let fitImage = await fitImageIntoCanvas(newImageId);
       // let sceneryCanvas = await renderPage();
        return newImageId;
    }

    /**
     * Deletes an image from page and workspace.
     *
     * @param {string} id Id of the image that needs to be deleted.
     * @return {Promise<void>}
     */
    export const deleteImage = async id => {
        await deleteImageMediaPoolId(id);
    }

    /**
     * Resizes an image to the greatest extent. A possible rotation will be set to 0.
     *
     * @param {string} id Id of the image that needs to be adopted.
     * @return {Promise<void>}
     */
    export const resetImage = async id => {
        await fitImageIntoCanvas(id);
    }

    /**
     * Resizes an image with the current size and a factor.
     *
     * @param {string} id Id of the image that needs to be adopted.
     * @param {number} factor A relative value that can be positive or negative. E.g. a value of 0.05 will increase the
     * image by 5 percent. -0.1 will shrink it by 10 percent. Must be a value greater -1.0.
     * @return {Promise<void>}
     */
    export const resizeImage = async (id, factor) => {
        await updateImageGeometryByFactor(id, factor);
    }

    export function getVersion(){
        return version;
    }

    let y;
    //let templateId = undefined;
    let hideCanvas = "display:none;";
    let enableCanvas = false;
    $:y, checkCanvasCssClass(y);
    let templateList;
    let projectConfig;
    let projectConfigSettings = false;

    let creator6class = "creator6-inline";
    let creator6fullscreenicon = "creator6-icon_resize";
    //let myWorkspaces = [];
    let creator6fixedclass = "";
    let startProductBarPos = -1;

    let showControls;
    let showControlsRight;
    let showZoomControls;
    let showRedo;
    let showUndo;
    let showTemplateList = false;

    async function prepareControls() {
        showControls = await getUiConfigs('workspace.visibility.controls');
        showControlsRight = await getUiConfigs('workspace.visibility.controlsright');
        showZoomControls = await getUiConfigs('workspace.visibility.zoom');
        showRedo = await getUiConfigs('workspace.visibility.redo');
        showUndo = await getUiConfigs('workspace.visibility.undo');

        return true;
    }

    /*
    async function prepareZoom(){
        showZoom = await getUiConfigs('workspace.visibility.zoom');
        console.log("showZoom");
        console.log(showZoom);
        return true;
    }*/

    function getConfigValueFor(configField, dataInput) {

        if (projectConfigSettings) {
            if (dataInput !== undefined && dataInput !== "") {
                return dataInput;
            } else if (projectConfig.settings[configField]) {
                return projectConfig.settings[configField];
            }
        } else {
            if (dataInput !== undefined && dataInput !== "") {
                return dataInput;
            }
        }
        return null;
    }

    onMount(async () => {
        c6WorkspaceLoaded.set(false);

        showLoader = true;

        if (projBaseUrl === undefined) {
            projBaseUrl = apiBaseUrl;
        }

        if (projBaseUrl.substr(-1) !== "/") {
            projBaseUrl = projBaseUrl + "/";
        }

        if(ct !== "canvas-only") {
            c6ProjBaseUrl.set(getConfigValueFor("projBaseUrl", projBaseUrl));
            await fetch($c6ProjBaseUrl)
                .then((response) => response.json())
                .then((creatorconfig) => {
                    projectConfig = creatorconfig;
                    c6ProjectUiSettings.set(creatorconfig.settings.ui);
                    projectConfigSettings = true;
                }).catch(error => {
                    console.log("could not load project Config");
                    projectConfig = {};
                });
        }

        c6TranslationsUrl.set(getConfigValueFor("localeUrl", localeUrl));
        c6LanguageIso.set(getConfigValueFor("language", language));
        c6LanguageShort.set(getConfigValueFor("language", language.substring(0, 2)));
        c6Repo.set(getConfigValueFor("repo", repo));
        c6SaveUrl.set(getConfigValueFor("saveUrl", saveUrl));
        c6ReturnFormUrl.set(getConfigValueFor("returnUrl", returnUrl));

        c6LogUrl.set(getConfigValueFor("logUrl", logUrl));
        c6ApiBaseUrl.set(getConfigValueFor("apiBaseUrl", apiBaseUrl));
        c6PreviewBaseUrl.set(getConfigValueFor("previewBaseUrl", previewBaseUrl));
        c6SolutionType.set(getConfigValueFor("ct", ct));
        c6ImageProxyEndpoint.set(apiBaseUrl+`/image-proxy-api/api`);

        let customcCanvasId = getConfigValueFor("canvasId", canvasId);
        (customcCanvasId) ? c6CanvasId.set(customcCanvasId) : null;

        let customLoaderColor = getConfigValueFor("loaderColor", loaderColor);
        (customLoaderColor) ? c6LoaderColor.set(customLoaderColor) : null;

        let c6ConfigReturnIds = getConfigValueFor("returnIds", returnIds);
        (c6ConfigReturnIds) ? c6ReturnIds.set(c6ConfigReturnIds.split(",")) : null;

        let c6ConfigLoadedShowClasses = getConfigValueFor("loadedShowClasses", loadedShowClasses);
        (c6ConfigLoadedShowClasses) ? c6LoadedShowClasses.set(c6ConfigLoadedShowClasses.split(",")) : null;

        c6TextReplacementdropDowns.set(getConfigValueFor("textReplacementdropDowns"));
        //eosSaveToArchiveUrl = saveUrl;

        if (accessToken !== undefined && accessToken !== $c6AccessToken) {
            c6AccessToken.set(accessToken);
        }
        if(ct !== "canvas-only"){
            await fetch($c6ProjBaseUrl + 'accesstoken/?status=' + $c6AccessToken)
                .then((response) => response.json())
                .then((statusToken) => {
                    if (statusToken.accessToken !== $c6AccessToken) {
                        c6AccessToken.set(statusToken.accessToken);
                    }
                }).catch(error => {
                    console.log("could not load Token Info");
                });
        }

        await setupI18n();


        const init = await initCreator6($c6ApiBaseUrl, $c6PreviewBaseUrl, $c6Repo, $c6AccessToken, canvasId);

        c6ProductId.set(productId);
        c6ProductName.set(productName);
        c6ProductCanvasInfo.set(canvasInfo);
        c6ProductCanvasInfoTop.set(canvasInfoTop);
        c6ProductCanvasInfoHeight.set(canvasInfoHeight);
        c6ProductCanvasInfoWidth.set(canvasInfoWidth);
        c6ProductionServiceId.set(productionServiceId);
        c6ProductPadColors.set(padColors);
        c6ProductPadColorsXpress.set((xpressPadColors && xpressPadColors != "") ? xpressPadColors.split(",") : []);

        c6SelectedPadColor.set(padColorSelected);

        c6ProductIcUrl.set(icUrl);
        c6ProductionMediasheet.set(mediasheet);

        if (templateIds === undefined && documentId) {
            templateIds = documentId;
        }

        c6ProductTemplates.set(templateIds);
        c6ProductDocumentId.set(documentId);
        let allowDocumentOverwrite = !!(allowOverwrite && documentId);
        c6ProductDocumentAllowOverwrite.set(allowDocumentOverwrite);

        if (inputText) {
            c6ProductInputTextlines.set(inputText);
            console.log("inputText fount");
            console.log(c6ProductInputTextlines);
        }

        await c6SelectedTemplate.set(null);
        showLoader = false;
        hideCanvas = "display:none;";

        templateList = showTemplateChooser($c6ProductTemplates);
    });

    onDestroy(() => {
        c6WorkspaceLoadedUnsubscribe();
        c6WorkspaceElementSelectedUnsubscribe();
    });

    /*
    let init = initCreator6(apiBaseUrl, previewBaseUrl, repo, accessToken, canvasId);
    setupI18n({withLocale: language});
    $: { document.dir = $dir; }


    let templateList = showTemplateChooser(templateIds);
    if(templateList){
        hideCanvas = "display:none;";
    }*/

    function showTemplateChooserAgain() {
        //templateId = null;
        c6SelectedTemplate.set(null);
        enableCanvas = false;
        c6CanvasId.update(id => id + "1");
        templateList = showTemplateChooser($c6ProductTemplates);
        if (templateList) {
            hideCanvas = "display:none;";
        }
    }

    function toggleFullscreen() {
        creator6class = (creator6class === "creator6-inline") ? "creator6-fullscreen" : "creator6-inline";
        creator6fullscreenicon = (creator6class === "creator6-inline") ? "creator6-icon_resize" : "creator6-icon_delete";
    }

    function checkCanvasCssClass(pageYOffset) {
        let canvasArea = document.getElementById('creator6-canvas-area');
        let canvasBottom = document.getElementById('creator6-canvas-bottom');
        let tabs = document.getElementById('creator6-tab-list');
        if (creator6class === "creator6-fullscreen") {
            if (startProductBarPos < 0) {
                startProductBarPos = findPosY(canvasArea);
            }
            if (pageYOffset > (startProductBarPos)) {
                creator6fixedclass = "creator6-preview-fixed";
                canvasBottom.style.paddingTop = (canvasArea.offsetHeight) + "px";
                let startBar2Pos = startProductBarPos + canvasBottom.clientHeight - canvasArea.offsetHeight;
                if (pageYOffset > (startBar2Pos)) {
                    tabs.style.position = 'fixed';
                    tabs.style.top = canvasArea.offsetHeight + "px";
                } else {
                    tabs.style.position = 'relative';
                    tabs.style.top = 0;
                }
            } else {
                creator6fixedclass = "";
                canvasBottom.style.paddingTop = 0;
            }
        }
    }


</script>
<main class="creator6-main-page {creator6class} {creator6fixedclass}" >
    <GlobalCss />
        <div class="creator6-caac">
            {#if enableCanvas }
                <div class="creator6-nio-canvas">
                    <canvas id="{$c6CanvasId}"></canvas>
                </div>
            {/if}
            {#if $c6SelectedTemplate}

            {:else if templateList}
                <TemplateChooser templateList="{templateList}" bind:enableCanvas={enableCanvas} bind:showTemplateList={showTemplateList} bind:hideCanvas/>
            {:else }
                MISSING TEMPLATE INPUT
            {/if}
        </div>


    {#if showLoader}
        <Loader fullscreen="true" />
    {/if}


    {#if debugIt}
        <hr>
        <label for="workspaceId">Workspace Id:</label><input type="text" id="workspaceId"  />
        <label for="elementId">Selected Element:</label><input type="text" id="elementId" />
        <hr>
        <p><em>accessToken:</em>
            {#if accessToken} {accessToken} {:else } <small>n/a</small> {/if}
        </p>
        <p><em>templateIds:</em>
            {#if templateIds} {templateIds} {:else } <small>n/a</small> {/if}
        </p>
        <p><em>language:</em>
            {#if language} {language} {:else } <small>n/a</small> {/if}
        </p>
        <p><em>productName:</em>
            {#if productName} {productName} {:else } <small>n/a</small> {/if}
        </p>
        <p><em>returnUrl:</em>
            {#if returnUrl} {returnUrl} {:else } <small>n/a</small> {/if}
        </p>
        <p><em>repo:</em>
            {#if repo} {repo} {:else } <small>n/a</small> {/if}
        </p>
        <p><em>apiBaseUrl:</em>
            {#if apiBaseUrl} {apiBaseUrl} {:else } <small>n/a</small> {/if}
        </p>
        <p><em>previewBaseUrl:</em>
            {#if previewBaseUrl} {previewBaseUrl} {:else } <small>n/a</small> {/if}
        </p>
        <p><em>productId:</em>
            {#if productId} {productId} {:else } <small>n/a</small> {/if}
        </p>
        <p><em>userId:</em>
            {#if userId} {userId} {:else } <small>n/a</small> {/if}
        </p>
        {:else}

        <input type="hidden" id="workspaceId" />
        <input type="hidden" id="elementId" />
        {/if}


</main>

<style>

    .creator6-line-container-wrapper, .creator6-line-container-top, .creator6-line-container-bottom {
        display:flex;
    }

    .creator6-line-container-wrapper{flex-direction: column; color:#666}

    .creator6-line-container-vertical {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        width:50px;
    }

    .creator6-line-container-spacer{width:50px;}
    .creator6-line-container-bottom .creator6-line-container-spacer{width:60px;}

    .creator6-canvas-width-value, .creator6-canvas-height-value {font-size:var(--creator6-fontsize-small); color:var(--creator6-textcolor-primary);}
    .creator6-line-container-vertical .line {
        flex-grow: 1;
        width: 1px;
        left:25px;
        background: var(--creator6-textcolor-primary);
        position: relative;
        display:inline-block;
    }

    .creator6-line-container-vertical .line.arrow-bottom:after {
        position: absolute;
        content: '';
        bottom: -3px;
        right: -3px;
        width: 0;
        height: 0;
        border-top: 3px solid var(--creator6-textcolor-primary);
        border-bottom: 3px solid transparent;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
    }

    .creator6-line-container-vertical .line.arrow-top:after {
        position: absolute;
        content: '';
        top: -3px;
        left: -3px;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid var(--creator6-textcolor-primary);
        border-right: 3px solid transparent;
        border-left: 3px solid transparent;
    }

    .creator6-line-container {
        display: flex;
        width: 100%;
        margin: 10px auto;
        align-items: center;
    }

    .creator6-line-container .line {
        flex-grow: 1;
        height: 1px;
        background: var(--creator6-textcolor-primary);
        position: relative;
    }

    .creator6-line-container .line.arrow-right:after {
        position: absolute;
        content: '';
        bottom: -3px;
        right: 0;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid var(--creator6-textcolor-primary);
    }

    .creator6-line-container .line.arrow-left:after {
        position: absolute;
        content: '';
        top: -3px;
        left: 0;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-right: 3px solid var(--creator6-textcolor-primary);
    }


    main {
        padding: 0;
        margin: 0 auto;
        position:relative;
    }

    main.creator6-main-rtl {
        direction: rtl;
    }
    main.creator6-fullscreen {
        position:static;
        top:0;
        left:0;
        z-index: 100000;
        width:100%;
        height:100vh;
    }

    main.creator6-fullscreen .creator6-canvas-area {
        padding-top: 5px;
    }

    main.creator6-fullscreen.creator6-preview-fixed .creator6-canvas-area {
        background: var(--creator6-preview-area);
        position: fixed;
        top: 0;
        left:0;
        width:100%;
        z-index:100;
        justify-content: center;
    }

    .creator6-preview-top-buttons button {
        border:none;
        background:none;
        padding:0;
        font-size:var(--creator6-fontsize-big)
    }

    .creator6-preview-top-buttons {
        position:absolute;
        width:100%;
        display:flex;
        justify-content:space-between;
        top:0;
    }

    .creator6-fullscreen-button {
        text-align:right;

    }

    .creator6-canvas-area {
        display: inline-flex;
        align-items: center;
        margin-bottom:5px;
    }


    .creator6-canvasinfo {
        font-size: var(--creator6-fontsize-small);
        opacity: 40%;
    }

    .creator6-preview {
        background-color: var(--creator6-preview-area);
        z-index: 101;
        position: relative;
        padding-top:20px;
    }

    .creator6-preview.creator6-preview-center {
        text-align:center;
    }

    #creator6-canvas-bottom {min-height: 30px;}

    .creator6-controls {
        display:flex;
        width: 100%;
        margin: 20px auto;
        align-items: stretch;
    }

    .creator6-controls-right{
        width:350px;
        background:var(--creator6-controls-bg);
        margin: 29px 20px 20px calc((100% - 1140px)/4);
    }

    @media (max-width: 1390px) {
        .creator6-controls-right{margin: 29px 0 0 0;}
    }

    @media (max-width: 768px) {
        .creator6-controls {display:block;}
        .creator6-controls-right{width:100%;}
        .creator6-canvas-area {
            display: flex;
            align-items: center;
            overflow-x: auto;
            margin-bottom:15px;
            justify-content: center;
        }
    }

    @media (max-width: 400px) {
        .creator6-canvas-area {
            justify-content:unset;
        }
    }

</style>