<script>
    import {saveAsDocument, overwriteDocument, loadElements} from '../../../resources/js/creator6-core';
    import {
        c6WorkspaceId,
        c6SaveUrl,
        c6SelectedTemplate,
        c6SelectedPadColor,
        c6ImprintTexts,
        c6WorkspaceValidation,
        c6ProductDocumentAllowOverwrite,
        c6ProductDocumentId,
        c6SceneryCanvas,
        c6ImprintImages,
        c6ImprintFrames,
        c6WorkspaceElements,
        c6ProductId,
        c6ImprintDatefields,
        c6ApiConnector,
        c6EngineCanvas,
        c6ImprintPhotos,
        c6WorkspaceReady2Leave
    } from "../../../resources/js/stores";
    import { getContext } from 'svelte';
    import {_} from '../../../services/i18n';
    import Dialog from '../../Dialog.svelte';
    import {getImageElementData} from "../../../resources/js/creator6-image";

    const { open } = getContext('simple-modal');

    let opening = false;
    let opened = false;
    let closing = false;
    let closed = false;

    let name;
    let status = 0;
    let errorMessageArchive = "";
    let errorMessageOverwrite = "";

    const onCancel = (text) => {
        name = '';
        status = 0;
    }

    async function onOkay(text){
        name = text;
        errorMessageArchive = "";
        c6WorkspaceReady2Leave.set(true);
        await saveImprint(text).then(cdocData => {
            var xhr = new XMLHttpRequest();
            xhr.open("POST", $c6SaveUrl);
            xhr.setRequestHeader("Accept", "application/json");
            //xhr.setRequestHeader("Content-Type", "application/json");

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    //console.log("Save to archive response");
                    //console.log(xhr.status);
                    if(xhr.status === 204){
                        status = 1;
                    }
                    else {
                        let errorMessage = xhr.error;
                        errorMessageOverwrite = $_('eosoutput.savetoarchive.error_'+{errorMessage});
                        status = -1
                    }
                    //console.log(xhr.responseText);
                }};

            //let imprinttext = JSON.stringify($c6ImprintTexts);
            let imprinttext = [];
            let imprintTexts = $c6ImprintTexts;
            Object.keys(imprintTexts).forEach(key => {
                imprintTexts[key].forEach(text =>{
                    imprinttext.push(text);
                });
            });

            var data = new Object();
            data.documentId = cdocData.documentId;
            data.documentPermalink = cdocData.permalinkId;
            data.sourceDocumentId = $c6SelectedTemplate;
            data.productId = $c6ProductId;
            data.padColor = $c6SelectedPadColor;
            data.text = imprinttext;
            data.dateCount = $c6ImprintDatefields;
            data.imageCount = $c6ImprintImages;
            data.frameCount = $c6ImprintFrames;
            data.photoCount = $c6ImprintPhotos;
            data.name = text;

            let saveData = JSON.stringify(data);

            xhr.send(saveData);
        });
    }

    const showDialog = () => {
        open(
            Dialog,
            {
                message: $_('eosoutput.savetoarchive.popupmessage'),
                hasForm: true,
                onCancel,
                onOkay
            },
            {
                closeButton: false,
                closeOnEsc: false,
                closeOnOuterClick: false,
            }
        );
    };

    const showDialogOverwrite = () => {
        async function onOkay(){
            c6WorkspaceReady2Leave.set(true);
            await overwriteImprint().then(cdocData => {
                var xhr = new XMLHttpRequest();
                xhr.open("POST", $c6SaveUrl);
                xhr.setRequestHeader("Accept", "application/json");
                //xhr.setRequestHeader("Content-Type", "application/json");

                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        //console.log("Save to archive response");
                        //console.log(xhr.status);
                        if(xhr.status === 204){
                            status = 2;
                        }
                        else {
                            let errorMessage = xhr.error;
                            errorMessageArchive = $_('eosoutput.overwritearchive.error_'+{errorMessage});
                            status = -2
                        }
                        //console.log(xhr.responseText);
                    }};
                //let imprinttext = JSON.stringify($c6ImprintTexts);
                let imprinttext = [];
                let imprintTexts = $c6ImprintTexts;
                Object.keys(imprintTexts).forEach(key => {
                    imprintTexts[key].forEach(text =>{
                        imprinttext.push(text);
                    });
                });


                var data = new Object();
                data.documentId = $c6ProductDocumentId;
                data.padColor = $c6SelectedPadColor;
                data.text = imprinttext;
                data.dateCount = $c6ImprintDatefields;
                data.imageCount = $c6ImprintImages;
                data.frameCount = $c6ImprintFrames;
                data.photoCount = $c6ImprintPhotos;
                data.overwrite = true;
                //data.name = text;
                let saveData = JSON.stringify(data);
                xhr.send(saveData);
            });
        }

        open(
            Dialog,
            {
                message: $_('eosoutput.overwritearchive.popupmessage'),
                hasForm: false,
                onCancel,
                onOkay
            },
            {
                closeButton: false,
                closeOnEsc: false,
                closeOnOuterClick: false,
            }
        );
    };

    async function loadSaveDetails(){
        let elements = await loadElements($c6SceneryCanvas);
        let images = 0;
        let frames = 0;
        let datefields = 0;
        let photofilter = 0;

        for (const element of elements) {
            if(element.sceneElement.elementType === "Image"){
                images++;
                let currentElementData = await getImageElementData($c6ApiConnector, $c6EngineCanvas, $c6WorkspaceId, element.id);
                let imageFilter = (currentElementData.imageFilters) ? currentElementData.imageFilters[0] : "None";
                if(imageFilter === "Halftone"){
                    photofilter++
                }
            }
            else if(element.sceneElement.elementType === "Frame" && element.sceneElement.model.strokeWidth !== 0){
                frames++;
            }
            else if(element.sceneElement.model.textType === "Dater"){
                datefields ++;
            }
        }

        c6ImprintImages.set(images);
        c6ImprintPhotos.set(photofilter);
        c6ImprintFrames.set(frames);
        c6ImprintDatefields.set(datefields);

        return true;
    }

    async function overwriteImprint() {
        let cdocData = await overwriteDocument($c6WorkspaceId);
        let loadDetails = await loadSaveDetails();
        return cdocData;
    }

    async function saveImprint(imprintname) {
        let cdocData = await saveAsDocument(imprintname);
        let loadDetails = await loadSaveDetails();
        return cdocData;
    }


</script>

<div class="creator6-section-div">

{#if $c6WorkspaceValidation.errors }
     <div class="creator6-errors">
        {$_('eosoutput.savetoarchive.textplateerrors')}
     </div>
{/if}

{#if $c6WorkspaceValidation.warnings }
    <div class="creator6-warnings">
        {$_('eosoutput.savetoarchive.textplatewarnings')}
    </div>
{/if}

{#if $c6ProductDocumentAllowOverwrite}
    <button class="creator6-overwritebutton" disabled="{($c6WorkspaceValidation.errors) ? 'disabled' : '' }" on:click={showDialogOverwrite}>{$_('eosoutput.overwritearchive.button')}</button>
    {#if status === 2}
        <p class="creator6-save-success">{$_('eosoutput.overwritearchive.okmessage')}</p>
    {:else if status === -2}
        <p class="creator6-save-error">{$_('eosoutput.overwritearchive.errormessage')}</p>
        <p class="creator6-save-error">{errorMessageOverwrite}</p>
    {/if}
{/if}

    {#if $c6SaveUrl}
<button class="creator6-savebutton" disabled="{($c6WorkspaceValidation.errors) ? 'disabled' : '' }" on:click={showDialog}>{#if $c6ProductDocumentAllowOverwrite}
    {$_('eosoutput.savetoarchive.asnewdocument')}
    {:else}
    {$_('eosoutput.savetoarchive.button')}
{/if}</button>
    {/if}

    {#if status === 1}
    <p class="creator6-save-success">{$_('eosoutput.savetoarchive.okmessage')}</p>
{:else if status === -1}
    <p class="creator6-save-error">{$_('eosoutput.savetoarchive.errormessage')}</p>
        <p class="creator6-save-error">{errorMessageArchive}</p>
    {/if}

</div>

<style>
    .creator6-section-div {text-align: center;}
    .creator6-save-error {color: var(--creator6-error-color)}
    .creator6-save-success {color: var(--creator6-success-color)}
    button {
        background: transparent;
        border: 1px solid #666;
        border-radius: 10px;
        padding: 0.4em;
        color: #666;
        font-weight: bold;
        width: 90%;
        margin: 1em;
        display: inline-block;
        cursor: pointer;

    }
</style>