<div class="creator6-tab-list" id="creator6-tab-list">
    <slot></slot>
</div>

<style>
    .creator6-tab-list {
        background-color:#fff;
        display: flex;
        flex-direction: row;
        justify-content: start;
        margin: 0 10px;
        z-index:1001;
        border-bottom:1px solid var(--creator6-color-blue);
        width: calc(100% - 20px);
    }
</style>