<script>
    import RangeSlider from "svelte-range-slider-pips";
    import Togglebuttons from './Togglebuttons.svelte';
    import {getFabricElementById, getFabricValue} from '../../../resources/js/fabricjshelper';
    import {_} from '../../../services/i18n';
    import {
        c6WorkspaceFonts,
        c6ApiConnector,
        c6EngineCanvas,
        c6WorkspaceId,
        c6SceneryCanvas,
        c6WorkspaceContainerIds,
        c6ImageProxyEndpoint,
        c6WorkspaceMedium,
        c6WorkspaceConfiguration,
        c6ImprintLines,
        c6WorkspaceMediumHeight,
        c6WorkspaceMediumWidth
    } from "../../../resources/js/stores";
    import Loader from "../../Loader.svelte";
    import {onMount} from "svelte";
    import {getUiConfigs} from "../../../resources/js/creator6-configuration";
    import {setLineGeometry, setLineStrokeData, getLineElementData} from "../../../resources/js/creator6-line";

    export let lineId;
    export let elementsTotal;

    let validationElements = false;

    let minLineStroke = 0.2;
    let maxLineStroke = 2;
    let lineStroke = [0.2]; // TODO


    let useInch = false;
    let unit = "mm";
    let lineOrientation = false;
    let currentLineOrientation = false;

    $:lineOrientation, submitLineOrientation(event, lineOrientation, currentLineOrientation);
    let lineOrientationOptions = [];

    lineOrientationOptions.push({
        value: 'None',
        label: $_('line.button.none'),
    });

    lineOrientationOptions.push({
        value: 'horizontal',
        label: $_('line.button.horizontal'),

    });
    lineOrientationOptions.push({
        value: 'vertical',
        label: $_('line.button.vertical'),

    });

    /*let shape;
    $:shape, submitLineShape(event, shape, false);
    let lineOptions = [];

    lineOptions.push({
        value: 'None',
        label: $_('line.button.none'),
    });
    lineOptions.push({
        value: 'Show',
        label: $_('line.button.show'),
    });*/

   /* async function submitLineShape(event, value, startvalue) {
        console.log("submit now Line Shape for element " + lineId + " from " + startvalue + " to " + value);
        console.log(event);
        if (value !== startvalue && value !== undefined) {
            try {
                if (value === "None") {
                    lineStroke = [0];
                    c6ImprintLines.update(n => n - 1);
                    let updatedStroke = await submitLineStrokeData(event, 'strokeWidth', lineStroke[0]);
                } else {
                    if (lineStroke[0] === 0) {
                        lineStroke = [0.2];
                        c6ImprintLines.update(n => n + 1);
                        let updatedStroke = await submitLineStrokeData(event, 'strokeWidth', lineStroke[0]);
                    }
                    //let updated = await setLineShape(frameId, value);
                }
            } catch (err) {
                console.log(err);
            }
        }
    }*/

    let lineInfoShow = (!($_('line.info.bottomlabel') === " " && $_('line.info.bottomdetails') === " "));

    let lineX1 = [0];
    let minLineX1 = 1;
    let maxLineX1 = $c6WorkspaceMediumWidth - 2;

    let lineX2 = [0];
    let minLineX2;
    let maxLineX2 = $c6WorkspaceMediumWidth - 2;

    let lineY1 = [0];
    let minLineY1 = 1;
    let maxLineY1 = $c6WorkspaceMediumHeight - 2;

    let lineY2 = [0];
    let minLineY2;
    let maxLineY2 = $c6WorkspaceMediumHeight - 2;

    let lineLength = [0];
    let minLineLength = 1;
    let maxLineLength = $c6WorkspaceMediumWidth - 2;


    const previewLineGeometry = (event, key, value) => {
        console.log("preview Line Geometry "+key+" for element " + lineId + " to " + value);
        let newValue = getFabricValue(value);
        let canvasFabric = $c6EngineCanvas.fabricCanvas;
        let object = getFabricElementById(canvasFabric, lineId);
        console.log(object);
        if(key === "x1"){
            object.set('left', newValue);
            lineX1 = [value];
            if(currentLineOrientation === "vertical"){
                lineX2 = [value];
            }
        }
        else if(key === "y1"){
            object.set('top', newValue);
            lineY1 = [value];
            if(currentLineOrientation === "horizontal"){
                lineY2 = [value];
            }
        }
        else if(key === "length"){
            object.set('width', newValue);
            lineLength = [value];
            if(currentLineOrientation === "horizontal"){
                lineX2 = [lineX1[0] + value];
            }
            else if(currentLineOrientation === "vertical"){
                lineX2 = [lineX1[0] + value];
            }
        }
        canvasFabric.requestRenderAll();
    }

    async function submitLineGeometry(event, key, value, startvalue) {
        console.log("submit now Line Geometry "+key+" for element " + lineId + " from " + startvalue + " to " + value);

        if (value !== startvalue && value !== undefined) {
            try {
                if(key === "x1"){
                    lineX1 = [value];
                    if(currentLineOrientation === "vertical"){
                        lineX2 = [value];
                    }
                    else {
                        lineX2 = [value + lineLength[0]];
                    }
                }
                else if(key === "y1"){
                    lineY1 = [value];
                    if(currentLineOrientation === "horizontal"){
                        lineY2 = [value];
                    }
                    else {
                        lineY2 = [value + lineLength[0]];
                    }
                }
                else if(key === "length"){
                    lineLength = [value];
                    if(currentLineOrientation === "horizontal"){
                        lineX2 = [lineX1[0] + value];
                    }
                    else if(currentLineOrientation === "vertical"){
                        lineY2 = [lineY1[0] + value];
                    }
                }

                const data = {};
                data.y1 = lineY1[0];
                data.y2 = lineY2[0];
                data.x1 = lineX1[0];
                data.x2 = lineX2[0];
                data.width = lineLength[0];

                let updated = await setLineGeometry(lineId, data);
            } catch (err) {
                console.log(err);
            }
        }
    }

    async function submitLineOrientation(event, value, startvalue) {
        console.log("submit now Line Shape for element " + lineId + " from " + startvalue + " to " + value);
        console.log(event);
        if (value !== startvalue && value !== undefined ) {
            try {
                console.log("lineX1");
                console.log(lineX1);

                console.log("lineX2");
                console.log(lineX2);

                console.log("lineY1");
                console.log(lineY1);

                console.log("lineY2");
                console.log(lineY2);

                console.log("lineLength");
                console.log(lineLength);

                if (value === "None") {
                    lineStroke = [0];
                    c6ImprintLines.update(n => n - 1);
                    let updatedStroke = await submitLineStrokeData(event, 'strokeWidth', lineStroke[0]);
                } else {

                    if(value === "horizontal" && (currentLineOrientation !== "None" || lineX1[0] === lineX2[0])){
                        let newY = (lineY2[0] - lineY1[0]) / 2;
                        let newX =  lineX1[0] - lineLength[0] / 2;
                        lineY1 = lineY2 = [newY];
                        lineX1 = [newX];
                        lineX2 = [newX + lineLength[0]];
                    }
                    else if(value === "vertical" && (currentLineOrientation !== "None" || lineY1[0] === lineY2[0])) {

                        let newX = (lineX2[0] - lineX1[0]) / 2;
                        let newY =  lineY1[0] - lineLength[0] / 2;
                        lineX1 = lineX2 = [newX];
                        lineY1 = [newY];
                        lineY2 = [newY + lineLength[0]];
                    }
                    const data = {};
                    data.y1 = lineY1[0];
                    data.y2 = lineY2[0];
                    data.x1 = lineX1[0];
                    data.x2 = lineX2[0];
                    data.width = lineLength[0];

                    console.log(data);

                    let updated = await setLineGeometry(lineId, data);

                    if (lineStroke[0] === 0) {
                        lineStroke = [0.2];
                        c6ImprintLines.update(n => n + 1);
                        let updatedStroke = await submitLineStrokeData(event, 'strokeWidth', lineStroke[0]);
                    }
                }
                currentLineOrientation = value;
            } catch (err) {
                console.log(err);
            }
        }
    }



    let minLineStrokeLength = 0.2;
    let maxLineStrokeLength = 10;
    let lineStrokeLength = [0.2]; // TODO

    let minLineStrokeSpace = 0.2;
    let maxLineStrokeSpace = 10;
    let lineStrokeSpace = [0.2]; // TODO

    let strokeStyle = "SolidStroke";
    $:strokeStyle, submitLineStrokeData(event, 'strokeStyle', strokeStyle, false);

    const strokeStyleOptions = [{
        value: 'SolidStroke',
        label: $_('frame.button.solidStroke'),
        //icon: 'colop-icon-frame_simple_hoizontal icon-large',
    }, {
        value: 'DashedStroke',
        label: $_('frame.button.dashedStroke'),
        //icon: 'colop-icon-frame_dashed_hoizontal icon-large',
    }, {
        value: 'DottedStroke',
        label: $_('frame.button.dottedStroke'),
        //icon: 'colop-icon-frame_dotted_hoizontal icon-large',
    }];


    const previewLineStrokeData = (event, key, value) => {
        console.log("preview Line stroke " + key + " for element " + lineId + " to " + value);
        let newValue = getFabricValue(value);
        let canvasFabric = $c6EngineCanvas.fabricCanvas;
        let object = getFabricElementById(canvasFabric, lineId);
        let currentLineStroke = object.strokeWidth/4.724409449;
        lineStroke = [value];

        object.set(key, newValue);
        canvasFabric.requestRenderAll();
    }

    async function submitLineStrokeData(event, key, value, startvalue) {
        console.log("submit now Line Stroke Date " + key + " for element " + lineId + " from " + startvalue + " to " + value);
        if (value !== startvalue && value !== undefined) {
            try {

                if (key === "strokeStyle" && value === "DottedStroke") {
                    lineStrokeSpace = [1];
                }
                if (key === "strokeStyle" && value === "DashedStroke") {
                    lineStrokeSpace = [3];
                    lineStrokeLength = [3];
                }
                let updated = await setLineStrokeData(lineId, key, value);
            } catch (err) {
                console.log(err);
            }
        }
    }

    const previewLineDashArray = (event, key, value) => {
        console.log("preview Line stroke " + key + " for element " + lineId + " to " + value);
        let newValue = getFabricValue(value);
        let canvasFabric = $c6EngineCanvas.fabricCanvas;
        let object = getFabricElementById(canvasFabric, lineId);

        let length = object.strokeDashArray[0];
        let space = object.strokeDashArray[1];

        if (key === "space") {
            space = newValue;
            lineStrokeSpace= [value];
        }

        if (key === "length") {
            length = newValue;
            lineStrokeLength= [value];
        }

        object.set("strokeDashArray", [length, space]);
        canvasFabric.requestRenderAll();
    }

    async function loadElementData(lineId) {
        if (lineId) {

        }
        let currentElementData = await getLineElementData(lineId);
        console.log("currentElementData");
        console.log(currentElementData);
/*
        if(currentElementData.stroke.strokeWidth === 0){
            shape = 'None';
        }
        else {
            shape = 'Show';
        }*/

        lineX1 = [currentElementData.geometry.x1];
        lineX2 = [currentElementData.geometry.x2];
        lineY1 = [currentElementData.geometry.y1];
        lineY2 = [currentElementData.geometry.y2];

        if(currentElementData.stroke.strokeWidth === 0){
            currentLineOrientation = "None";
            lineOrientation = currentLineOrientation;
        }
        else if(currentElementData.geometry.x1 === currentElementData.geometry.x2){
            currentLineOrientation = "vertical";
            lineOrientation = currentLineOrientation;
            lineLength = [currentElementData.geometry.y2 - currentElementData.geometry.y1];
        }
        else {
            currentLineOrientation = "horizontal";
            lineOrientation = currentLineOrientation;
            lineLength = [currentElementData.geometry.x2 - currentElementData.geometry.x1];
        }

        if ("length" in currentElementData) {
            lineStrokeLength = [currentElementData.stroke.length];
        } else {
            lineStrokeLength = [0];
        }

        if ("space" in currentElementData) {
            lineStrokeSpace = [currentElementData.stroke.space];
        } else {
            lineStrokeSpace = [0];
        }

        lineStroke = [currentElementData.stroke.strokeWidth];

        strokeStyle = currentElementData.stroke.strokeStyle;

        return currentElementData;
    }

    onMount(async () => {
        useInch = !await getUiConfigs('workspace.visibility.canvasinfomm');
        unit = (useInch) ? "" : "mm";
        return () => {
        };
    });


</script>

{#await loadElementData(lineId) }
    <Loader />
{:then lineLoaded}
    <div class="creator6-line" data-c6lineId="{lineId}">
        {#if elementsTotal > 1}
            <div class="creator6-line-preview">
                <span class="colop-icon-tab_form icon-large"></span>
            </div>
        {/if}
    <div class="creator6-warnings-errors">
        <div class="creator6-line-warnings">
            {#if validationElements && lineId in validationElements && validationElements[lineId]}
                {$_('frame.warning.default')}
            {/if}
        </div>
        <div class="creator6-line-alerts"></div>
    </div>
    <div class="creator6-line-controls">
        <div class="creator6-line-control creator6-line-control-orientation">
            <div class="creator6-control-label">{$_('line.control.orientation')}</div>
            <div class="creator6-line-control-togglebuttongroup">
                <Togglebuttons options={lineOrientationOptions} fontSize={16}  bind:valueSelected={lineOrientation} />
            </div>
        </div>
        {#if currentLineOrientation !== "None"}

            <div class="creator6-line-control creator6-line-control-strokestyle">
                <div class="creator6-control-label">{$_('frame.control.strokestyle')}</div>
                <div class="creator6-line-control-togglebuttongroup">
                    <Togglebuttons options={strokeStyleOptions} fontSize={16}  bind:valueSelected={strokeStyle}/>
                </div>
            </div>

            <div class="creator6-line-control creator6-line-control-width">
                <div class="creator6-line-label-input">
                    <div class="creator6-control-label">{$_('frame.control.width')}</div>
                    <div class="creator6-input-only creator6-input-only-{unit}">
                        <input type="number" step="0.1" bind:value={lineLength[0]} min="{minLineLength}" max={maxLineLength} on:keyup={(e) => previewLineGeometry(e, 'length', e.target.valueAsNumber, lineLength[0])} on:focusout={(e) => submitLineGeometry(e, 'length', e.target.valueAsNumber)}/>
                    </div>
                </div>
                <div class="creator6-line-control-slider">
                    <RangeSlider suffix=" {unit}" values={lineLength} bind:minLineLength bind:maxLineLength pips first='label' last='label'  step={0.5} min={minLineLength} max={maxLineLength} float on:change={(e) => previewLineGeometry(e, 'length', e.detail.value)} on:stop={(e) => submitLineGeometry(e, 'length', e.detail.value, e.detail.startvalue)} />
                </div>
            </div>
            <div class="creator6-line-control creator6-line-control-x">
                <div class="creator6-line-label-input">
                    <div class="creator6-control-label">{$_('frame.control.x')}</div>
                    <div class="creator6-input-only creator6-input-only-{unit}">
                        <input type="number" step="0.1" bind:value={lineX1[0]} min="{minLineX1}" max={maxLineX1} on:keyup={(e) => previewLineGeometry(e, 'x1', e.target.valueAsNumber, lineX1[0])} on:focusout={(e) => submitLineGeometry(e, 'x1', e.target.valueAsNumber)}/>
                    </div>
                </div>
                <div class="creator6-line-control-slider">
                    <RangeSlider suffix=" {unit}" values={lineX1} bind:minLineX1 bind:maxLineX1 pips first='label' last='label'  step={0.5} min={minLineX1} max={maxLineX1} float on:change={(e) => previewLineGeometry(e, 'x1', e.detail.value)} on:stop={(e) => submitLineGeometry(e, 'x1', e.detail.value, e.detail.startvalue)} />
                </div>
            </div>
            <div class="creator6-line-control creator6-line-control-y">
                <div class="creator6-line-label-input">
                    <div class="creator6-control-label">{$_('frame.control.y')}</div>
                    <div class="creator6-input-only creator6-input-only-{unit}">
                        <input type="number" step="0.1" bind:value={lineY1[0]} min="{minLineY1}" max={maxLineY1} on:keyup={(e) => previewLineGeometry(e, 'y1', e.target.valueAsNumber, lineY1[0])} on:focusout={(e) => submitLineGeometry(e, 'y1', e.target.valueAsNumber)}/>
                    </div>
                </div>
                <div class="creator6-line-control-slider">
                    <RangeSlider suffix=" {unit}" values={lineY1} bind:minLineY1 bind:maxLineY1 pips first='label' last='label'  step={0.5} min={minLineY1} max={maxLineY1} float on:change={(e) => previewLineGeometry(e, 'y1', e.detail.value)} on:stop={(e) => submitLineGeometry(e, 'y1', e.detail.value, e.detail.startvalue)} />
                </div>
            </div>

            <div class="creator6-line-control creator6-line-control-strokewidth">
                <div class="creator6-line-label-input">
                    <div class="creator6-control-label">{$_('frame.control.strokewidth')}</div>
                    <div class="creator6-input-only creator6-input-only-{unit}">
                        <input type="number" step="0.1" bind:value={lineStroke[0]} min="{minLineStroke}" max={maxLineStroke} on:keyup={(e) => previewLineStrokeData(e, 'strokeWidth', e.target.valueAsNumber, lineStroke[0])} on:focusout={(e) => submitLineStrokeData(e, 'strokeWidth', e.target.valueAsNumber)}/>
                    </div>
                </div>
                <div class="creator6-line-control-slider">
                    <RangeSlider suffix=" {unit}" values={lineStroke} bind:minLineStroke bind:maxLineStroke pips first='label' last='label'  step={0.1} min={minLineStroke} max={maxLineStroke} float on:change={(e) => previewLineStrokeData(e, 'strokeWidth', e.detail.value)} on:stop={(e) => submitLineStrokeData(e, 'strokeWidth', e.detail.value, e.detail.startvalue)} />
                </div>
            </div>

        {/if}
        {#if strokeStyle === "DashedStroke"}
        <div class="creator6-line-control creator6-line-control-strokelength">
            <div class="creator6-line-label-input">
                <div class="creator6-control-label">{$_('frame.control.strokelength')}</div>
                <div class="creator6-input-only creator6-input-only-{unit}">
                    <input type="number" step="0.1" bind:value={lineStrokeLength[0]} min="{minLineStrokeLength}" max={maxLineStrokeLength} on:keyup={(e) => previewLineDashArray(e, 'length', e.target.valueAsNumber)} on:focusout={(e) => submitLineStrokeData(e, 'length', e.target.valueAsNumber)}/>
                </div>
            </div>
            <div class="creator6-line-control-slider">
                <RangeSlider suffix=" {unit}" values={lineStrokeLength} bind:minLineStrokeLength bind:maxLineStrokeLength pips first='label' last='label' step={0.1} pipstep="{5}" min={minLineStrokeLength} max={maxLineStrokeLength} float on:change={(e) => previewLineDashArray(e, 'length', e.detail.value)} on:stop={(e) => submitLineStrokeData(e, 'length', e.detail.value, e.detail.startvalue)} />
            </div>
        </div>
        {/if}
        {#if strokeStyle === "DashedStroke" || strokeStyle === "DottedStroke"}
        <div class="creator6-line-control creator6-line-control-strokespace">
            <div class="creator6-line-label-input">
                <div class="creator6-control-label">{$_('frame.control.strokespace')}</div>
                <div class="creator6-input-only creator6-input-only-{unit}">
                    <input type="number" step="0.1" bind:value={lineStrokeSpace[0]} min="{minLineStrokeSpace}" max={maxLineStrokeSpace} on:keyup={(e) => previewLineDashArray(e, 'space', e.target.valueAsNumber)} on:focusout={(e) => submitLineStrokeData(e, 'space', e.target.valueAsNumber)}/>
                </div>
            </div>
            <div class="creator6-line-control-slider">
                <RangeSlider suffix=" {unit}" values={lineStrokeSpace} bind:minLineStrokeSpace bind:maxLineStrokeSpace pips first='label' last='label' step={0.1} pipstep="{5}" min={minLineStrokeSpace} max={maxLineStrokeSpace} float on:change={(e) => previewLineDashArray(e, 'space', e.detail.value)} on:stop={(e) => submitFrameStrokeData(e, 'space', e.detail.value, e.detail.startvalue)} />
            </div>
        </div>
        {/if}
    </div>
    {#if lineInfoShow}
        <div class="creator6-line-info">
            <div class="creator6-line-info-label">{@html $_('frame.info.bottomlabel')}</div>
            <div class="creator6-line-info-details">{@html $_('frame.info.bottomdetails')}</div>
        </div>
    {/if}
</div>

    {/await}
<style>
    @media (min-width: 768px) {

        .creator6-line-control {
            display: inline-flex;
            align-items: stretch;
            width: 100%;
        }
        .creator6-line-control-strokewidth {
            margin-top:0;
        }
    }

    .creator6-line {
        display:block;
        border-top: 1px solid #ddd;
        position:relative;
    }
    .creator6-line:first-child {
        border-top:none;
        padding-top:10px
    }

    .creator6-line-controls {
        width:100%;
        padding: 0 10px;
    }

    .creator6-line-preview {
        width: 100%;
        padding: 10px;
        display: inline-flex;
        align-items: center;
        opacity: 0.5;
    }

    .creator6-line-control {
        border-top: 1px solid var(--creator6-controls-spacer);
        margin: 0 0 10px 0;
    }

    .creator6-line-control.creator6-line-control-shape {
        border-top: none;
    }

    .creator6-line-control input {
        width: 60px;
        -moz-appearance: textfield;
        text-align: right;
        border: none;
        background: var(--creator6-controls-input-bg);
        height: 29px;
        border-bottom: 1px solid #f3f3f3;
        padding-right:22px;
        color: var(--creator6-color-blue);
        margin: 0 10px;
    }

    .creator6-line-control .icon-large {
        margin-right:1em;
    }

    .creator6-line-control-togglebuttongroup {
        display: inline-block;
        height: 40px;
        width: 100%;
        margin-top: 0.5em;
    }

    .creator6-line-control-slider {
        width:90%;
        margin-top: 0.5em;
        margin-left: 1em;
        margin-right: 1em;
    }

    :global(.creator6-line-control-slider .pipVal) {
        font-size:var(--creator6-fontsize-small);
    }

    .creator6-control-label {
        min-width: 150px;
    }

    .creator6-line-label-input {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .creator6-input-only{
        border: 1px solid var(--creator6-controls-bg);
        border-radius: var(--creator6-border-radius);
        background: var(--creator6-controls-bg);
        margin: 5px;
        white-space: nowrap;
        height:40px;
        display:flex;
        position: relative;
        align-items: center;
    }

    .creator6-input-only-mm::after {
        position: absolute;
        right: 10px;
        font-size: var(--creator6-fontsize-small);
        color: #aaa;
        text-align: left;
        content:"mm";
    }

    .creator6-line-info{
        border: 1px solid var(--creator6-color-blue);
        background: #2b77bc;
        margin: 10px 0 0 0;
        padding: 10px;
        color: white;
    }



</style>