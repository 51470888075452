<script>
    import {_} from '../../services/i18n';
    import {convert2Inch} from '../../resources/js/creator6-mm2inch';
    import {onMount} from 'svelte';
    import {engine} from '@tagading/scenery-fabricjs';
    import {
        resetCreatorData,
        getWorkspaceMedium,
        prepareWorkspaceAsync,
        validateImprint,
        renderPageInitialAsync,
        loadElements, getPageProperties, getWorkspaceConfiguration
    } from "../../resources/js/creator6-core";
    import {prepareMask, updateMaskColor} from "../../resources/js/creator6-mask";
    import {getUiConfigs, readConfigurationMap} from "../../resources/js/creator6-configuration";
    import {readPadColor, updatePadColor} from "../../resources/js/creator6-padcolor";
    import {prepareFonts} from "../../resources/js/creator6-fonts";
    import {getWorkspacePreviewImageUrl} from "../../resources/js/creator6-preview";

    import {
        c6MyWorkspaces,
        c6SelectedTemplate,
        c6ApiConnector,
        c6Repo,
        c6CanvasId,
        c6ImageProxyEndpoint,
        c6PermaLinksEndpoint,
        c6EngineCanvas,
        c6WorkspaceId,
        c6WorkspaceFonts,
        c6SceneryCanvas,
        c6WorkspaceElements,
        c6ProductId,
        c6ProductDocumentId,
        c6WorkspaceValidation,
        c6WorkspaceConfiguration,
        c6SelectedPadColor,
        c6WorkspaceMedium,
        c6LoadWorkspaceWithTexts,
        c6ProductInputTextlines,
        c6ReturnIds,
        c6LoadedShowClasses,
        c6SolutionType,
        c6WorkspaceLoaded,
        c6ProductCanvasInfoWidth,
        c6ProductCanvasInfoHeight,
        c6ProductCanvasInfo,
        c6ProjectUiSettings,
        c6WorkspacePages,
        c6WorkspaceMediumWidth,
        c6WorkspaceMediumHeight,
        c6ProductCanvasInfoTop, c6WorkspaceZoom, c6WorkspaceZoomFactor, c6WorkspaceCliparts, c6WorkspacePageActive,
        c6WorkspaceConfigurationMeta, c6ProductCanvasInfoTemplate, c6WorkspaceMaxLines
    } from "../../resources/js/stores";
    import Loader from "../Loader.svelte";
    import {getSharedAssets} from "../../resources/js/creator6-image";
    import {resetZoomData} from "../../resources/js/creator6-zoom";

    export let templateList;
    let templateId;
    export let hideCanvas;
    export let enableCanvas;
    export let showTemplateList;
    let myWorkspaces = [];
    let showLoader = false;

    async function selectTemplate(template, existingWS = undefined, docId = undefined) {
        if (templateList) {
            showLoader = true;
            let startCreator = await startCreator6($c6ApiConnector, $c6Repo, template, $c6CanvasId, existingWS, docId);
            if (startCreator) {
                templateId = template;
                c6SelectedTemplate.set(template);
                hideCanvas = "";
                templateList = false;
            }
            else {
                templateList = false;
                c6SelectedTemplate.set(null);
                console.log("wrong template id");
                showLoader = false;
            }
        } else {
            console.log("selectTemplate started twice");
        }
    }

    async function startCreator6(apiConnector, repo, templateId, canvasId, existingWS = undefined, docId = undefined, registerSelection, userId = undefined) {
        await resetCreatorData();
        resetZoomData();

        if (templateId !== undefined || existingWS !== undefined) {
            let ws;
            let sceneryCanvas ;
            let engineCanvas = await new engine.FabricEngineCanvas(canvasId, undefined, $c6ImageProxyEndpoint);
            engineCanvas.fabricCanvas.selection = false;
            c6EngineCanvas.set(engineCanvas);
            if($c6ProductInputTextlines != null){
                let inputTexts = JSON.parse($c6ProductInputTextlines);
                if(inputTexts.lines.length){
                    c6LoadWorkspaceWithTexts.set(inputTexts.lines);
                }
            }

            try {
                ws = await prepareWorkspaceAsync(apiConnector, userId, repo, engineCanvas, templateId, registerSelection, existingWS, docId);
                c6WorkspacePages.set(ws.workspaceInfo.numberOfPages);
                c6WorkspaceId.set(ws.workspaceInfo.workspaceId);

                if($c6ReturnIds.length){
                    $c6ReturnIds.forEach(function (returnId){
                        if(returnId.startsWith("workspaceId:")){
                            var workspaceId = returnId.replace("workspaceId:","");
                            var workspaceInput = document.getElementById(workspaceId);
                            if(workspaceInput) {
                                workspaceInput.value = ws.workspaceInfo.workspaceId;
                            }
                        }
                    });
                }
            } catch (err) {
                console.log(err);
                return false;
            }

            try {
                let wsInfo = await getWorkspaceMedium(apiConnector, ws.workspaceInfo.workspaceId);
                c6WorkspaceMedium.set(wsInfo);
                c6WorkspaceMediumWidth.set(wsInfo.width);
                c6WorkspaceMediumHeight.set(wsInfo.height);
                if($c6SolutionType === "c6manager"){
                    c6WorkspaceMaxLines.set(Math.round(wsInfo.height / (5 / 2.835)));
                }
                var useInch = !await getUiConfigs('workspace.visibility.canvasinfomm');
                if($c6ProductCanvasInfoWidth === undefined ) {
                    c6ProductCanvasInfoWidth.set((useInch) ? (convert2Inch(wsInfo.width))+ '"' : wsInfo.width+ " mm");
                }
                if($c6ProductCanvasInfoHeight === undefined) {
                    c6ProductCanvasInfoHeight.set((useInch) ? (convert2Inch(wsInfo.height))+ '"' :wsInfo.height+ " mm");
                }
                if($c6ProductCanvasInfoTop === undefined) {
                    c6ProductCanvasInfoTop.set((useInch) ? (convert2Inch(wsInfo.width))+ " x " + (convert2Inch(wsInfo.height)) + '"' :wsInfo.width + " x " + wsInfo.height + " mm");
                }

            } catch (err) {
                console.log(err);
            }

            if($c6SolutionType !== "canvas-only") {
                try {

                    let workspaceConfiguration = await readConfigurationMap(apiConnector, $c6WorkspaceId);
                    c6WorkspaceConfiguration.set(workspaceConfiguration);

                    let workspaceConfigurationName = await getWorkspaceConfiguration(ws.workspaceInfo.workspaceId);
                    c6WorkspaceConfigurationMeta.set(workspaceConfigurationName);

                } catch (err) {
                    console.log(err);
                }
            }

            if($c6SolutionType !== "canvas-only") {
                try {
                    let recolorMask = await prepareMask();
                    if (recolorMask) {
                        let neededMaskColor = getComputedStyle(document.body).getPropertyValue('--creator6-preview-area');
                        await updateMaskColor(neededMaskColor);
                    }
                } catch (err) {
                    console.log(err);
                }
            }

            if ($c6SelectedPadColor) {
                await updatePadColor($c6SelectedPadColor, false);
            } else {
                var preSelectedPadColor = await readPadColor();
                c6SelectedPadColor.set(preSelectedPadColor);
            }

            try {
                sceneryCanvas = await renderPageInitialAsync(ws, engineCanvas);
                if(sceneryCanvas) {
                    c6SceneryCanvas.set(sceneryCanvas);
                    let pageProperties = await getPageProperties(sceneryCanvas);
                    c6WorkspaceZoom.set((pageProperties.zoomFactor) ? pageProperties.zoomFactor * 100 : 100);
                    c6WorkspaceZoomFactor.set((pageProperties.zoomFactor) ? pageProperties.zoomFactor : 1);
                    c6ProductCanvasInfoTemplate.set((pageProperties.userInfo) ? pageProperties.userInfo : "" );

                    var elements = await loadElements(sceneryCanvas);
                    let existingElements = ($c6WorkspaceElements) ? $c6WorkspaceElements : {};
                    existingElements[$c6WorkspacePageActive]=elements;
                    c6WorkspaceElements.set(existingElements);

                    if($c6SolutionType !== "canvas-only") {
                        let workspaceValidation = await validateImprint(sceneryCanvas);
                        c6WorkspaceValidation.set(workspaceValidation);
                        if (await getUiConfigs('workspace.visibility.textcontrols')) {
                            try {
                                let limitFonts = [];
                                if ($c6WorkspaceConfiguration.get("workspace.fonts.allowed")) {
                                    limitFonts = $c6WorkspaceConfiguration.get("workspace.fonts.allowed").values;
                                }
                                let workspaceFonts = await prepareFonts(limitFonts);
                                c6WorkspaceFonts.set(workspaceFonts);
                            } catch (err) {
                                console.log(err);
                            }
                        }

                        if (await getUiConfigs('workspace.visibility.cliparts')) {
                            let cliparts = await getSharedAssets();
                            c6WorkspaceCliparts.set(Object.values(cliparts));
                        }

                        let currentWorkspace = [];
                        if (existingWS === undefined) {
                            currentWorkspace.push({
                                productId: $c6ProductId,
                                workspaceId: $c6WorkspaceId,
                            });
                        }
                        c6MyWorkspaces.set(JSON.stringify(currentWorkspace));
                        if ($c6LoadedShowClasses.length) {
                            $c6LoadedShowClasses.forEach(function (showClassName) {
                                const collection = document.getElementsByClassName(showClassName);
                                for (let i = 0; i < collection.length; i++) {
                                    collection[i].style.display = "block";
                                }
                            });
                        }
                    }
                    c6WorkspaceLoaded.set(true);

                    return true;
                }
            } catch (err) {
                console.log(err);
            }
            c6WorkspaceLoaded.set(false);
            return false;
        }
    }

    function removeMyWorkspace(index){
        myWorkspaces = [...myWorkspaces.slice(0,index), ...myWorkspaces.slice(index+1)];
        c6MyWorkspaces.set(JSON.stringify(myWorkspaces));
    }

    onMount(() => {

        //intervals functions
        enableCanvas = true;

        if($c6ProductDocumentId){
            selectTemplate($c6ProductDocumentId, undefined, $c6ProductDocumentId);
        }
        else {
            if($c6MyWorkspaces === ""){
                myWorkspaces = [];
            }
            else {
                myWorkspaces = JSON.parse($c6MyWorkspaces);
                if(myWorkspaces && myWorkspaces.productId && myWorkspaces.productId === $c6ProductId){
                    showTemplateList = false;
                    selectTemplate(myWorkspaces.workspaceId, myWorkspaces.workspaceId);
                }
                else {
                    c6MyWorkspaces.set("");
                }
            }

            if (templateList.length === 1){
                showTemplateList = false;
                if(templateList[0] === $c6WorkspaceId){
                    selectTemplate(templateList[0], $c6WorkspaceId);
                }
                else {
                    selectTemplate(templateList[0]);
                }
            }
            /*
            if (templateList.length === 1 && myWorkspaces.length === 0) {
                selectTemplate(templateList[0]);
            }*/
        }
        return () => {
        };

    });

</script>

<div class="creator6-templatechooser">
    {#if templateList.length > 1}
        <div class="creator6-templatechooser-headline">{$_('templatechooser.headline')}</div>
        <div class="creator6-templates">
            {#if templateList.length > 0 }
                {#each templateList as template}
                    <div class="creator6-core-template">
                        <button class="creator6-template" on:click={() => selectTemplate(template) }>
                            <img src="{$c6PermaLinksEndpoint}{template}" alt="{$_('templatechooser.template.alttext')}">
                        </button>
                    </div>
                {/each}
            {/if}
        </div>
    {/if}
    {#if showLoader}
        <Loader opacity="true" text="{$_('templatechooser.loading')}" fullscreen="true"/>
    {/if}
</div>

<style>

    .creator6-templatechooser{position:relative; padding: var(--creator6-padding-standard); background-color: var(--creator6-controls-bg);}
    .creator6-templates {
        display: flex;
        flex-wrap: wrap;
        align-items:center;
    }
    .creator6-template {
        cursor:pointer;
        display:inline-block;
        border: 0;
        margin: 0.5em 1em;
        padding: 0;
        background: transparent;
    }

    .creator6-template:hover {
        background: var(--creator6-template-hover);
    }

    .creator6-template:hover img {
        background: transparent;
    }
    .creator6-template input {visibility: hidden;}
    .creator6-template img {background-color: var(--creator6-template-bg); margin-bottom:-4px;}

</style>