import Esrever from 'esrever';
import {get} from "svelte/store";
import {c6RtlBaseUrl} from "./stores";


function convertRtlRoundText(rtlRoundTextToConvert = "TEST") {
    let rtlRoundTextConverterUrl = get(c6RtlBaseUrl)+'?value='+rtlRoundTextToConvert;

    const xhttp = new XMLHttpRequest();
    /*xhttp.onload = function() {
        return Esrever.reverse(this.responseText);
    }*/
    xhttp.open("GET", rtlRoundTextConverterUrl, false);
    xhttp.send();
    return Esrever.reverse(xhttp.responseText);
}

export { convertRtlRoundText };
