import {get} from "svelte/store";
import {c6ApiConnector, c6PermaLinksEndpoint, c6SolutionType, c6WorkspaceFonts, c6WorkspaceId} from "./stores";

function getFontPreviewImageUrl(imageLinkId) {
    return get(c6PermaLinksEndpoint)+imageLinkId;
}

function getFontVariantNameByWeight(weight){
    let fontVariantName = {};
    fontVariantName["100"] = "Thin";
    fontVariantName["200"] = "Extra Light";
    fontVariantName["300"] = "Light";
    fontVariantName["400"] = "Regular";
    fontVariantName["500"] = "Medium";
    fontVariantName["600"] = "Semi Bold";
    fontVariantName["950"] = "Extra Black";
    fontVariantName["700"] = "Bold";
    fontVariantName["800"] = "Extra Bold";
    fontVariantName["900"] = "Black";
    return fontVariantName[weight];
}

async function getFontPreview(fonts, fontname, long){
    return new Promise((resolve, reject) => {
        fonts.forEach(function(font){
            if(font.fontFamily === fontname){
                if(long && font.sampleLong !== ""){
                    resolve(font.sampleLong);
                }
                else {
                   resolve(font.sample);
                }
            }
        });
        reject("");
    });
}

async function getFontStylePreview(fonts, fontname, weight, italic, long){
    return new Promise((resolve, reject) => {
        fonts.forEach(function(font){
            if(font.weight === weight && font.italic === italic){
                if(long && font.sampleLong !== ""){
                    resolve(font.sampleLong);
                }
                else {
                    resolve(font.sample);
                }
            }
        });
        reject("");
    });
}

function getFontVariants(fonts, fontname){
    return new Promise((resolve, reject) => {
        fonts.forEach(function(font){
            if(font.fontFamily === fontname){
                resolve(font.variants);
            }
        });
        resolve([]);
    });
}

function sortAndLimitFontFamilyNames(fonts, showOnlyTheseFonts){
    let unsorted = {};
    fonts.forEach(function(font){
        unsorted[font.fontFamily]=font;
    });
    const ordered = Object.keys(unsorted).sort().reduce(
        (array = [], key) => {
            //obj[key] = unsorted[key];
            if(showOnlyTheseFonts.length){
                if(showOnlyTheseFonts.includes(key)){
                    array.push(unsorted[key]);
                }
            }
            else {
                if(key !== "COLOP DATER FONT"){
                    array.push(unsorted[key]);
                }
            }
            return array;
        },
        []
    );
    return ordered;
}

async function prepareFonts(limitFonts) {
    return new Promise((resolve, reject) => {
        /* Workspace */
        //var workspaceProvider = new workspace.WorkspaceProvider(apiConnector.getWorkspaceService());
        get(c6ApiConnector).getGateway().getFrontendApi().getTypefaces(get(c6WorkspaceId)).subscribe({
            next: function (typeFaces) {
                if(typeFaces.response ){
                    typeFaces.response.forEach(function(font){
                        font.variants = [];
                        font.sample = font.fonts[0].permalinkId;
                        font.sampleLong = ('alternativePermalinkId' in font.fonts[0]) ? font.fonts[0].alternativePermalinkId : "";
                        font.fonts.forEach(function (variant) {
                            //console.log("variant");
                            //console.log(variant);
                            let fontVariant = {};
                            fontVariant.weight = parseInt(variant.featureTags[0].replace("weight-", ""),10);
                            fontVariant.italic = (variant.featureTags.length === 2);
                            fontVariant.sample = variant.permalinkId;
                            fontVariant.sampleLong = ('alternativePermalinkId' in variant) ? variant.alternativePermalinkId : "";
                            if((fontVariant.weight === "400" || fontVariant.weight === 400)  && !fontVariant.italic){
                                font.sample = fontVariant.sample;
                                font.sampleLong = fontVariant.sampleLong;
                            }
                            font.variants.push(fontVariant);
                        });
                    });
                    resolve(sortAndLimitFontFamilyNames(typeFaces.response, limitFonts));
                }
                else {
                    reject("Could not load typefaces");
                }
            }
        });
    });
}

export {getFontPreviewImageUrl, getFontVariantNameByWeight, getFontPreview, getFontStylePreview, getFontVariants, prepareFonts}