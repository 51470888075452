import { derived } from 'svelte/store';
import { dictionary, locale, _} from 'svelte-i18n';

const MESSAGE_FILE_URL_TEMPLATE = 'i18n/{locale}.json';
const dir = derived(locale, $locale => ($locale === 'ar' || $locale === 'he') ? 'rtl' : 'ltr');
let cachedLocale;
/*
function setupI18n2({
                       withLocale: _locale
                   } = {
    withLocale: 'en'
}) {
    dictionary.set({
        en: {
            "templatechooser": {
                "headline": "Select a template",
                "template": {
                    "alttext": "Creator 6 Template"
                }
            },
            "tabs": {
                "text": "Text",
                "image": "Image"
            },
            "buttons": {
                "image": {
                    "upload": "Select and upload image",
                    "replace": "Replace image",
                    "replace_warning": "Do you really want to replace the existing image?",
                    "layerup": "One level forward",
                    "layerdown": "One level back",
                    "delete": "Delete Image",
                    "delete_warning": "Do you really want to delete this image?"
                },
                "frame": {
                    "none": "none",
                    "rectangular": "rectangular",
                    "elliptic": "round/elliptic",
                    "solidStroke": "solid",
                    "dashedStroke": "dashed",
                    "dottedStroke": "dotted",
                },
            },
            "preview": {
                "zoom": "Zoom"
            }
        },
        de: {
            "templatechooser": {
                "headline": "Wählen Sie eine Vorlage aus",
                "template": {
                    "alttext": "Creator 6 Vorlage"
                }
            },
            "tabs": {
                "text": "Text",
                "image": "Bild"
            },
            "buttons": {
                "image": {
                    "upload": "Bild auswählen und hochladen",
                    "replace": "Bild ersetzen",
                    "replace_warning": "Das vorhandene Bild wirlich ersetzen?",
                    "layerup": "Eine Ebene nach vorne",
                    "layerdown": "Eine Ebene nach hinten",
                    "delete": "Bild löschen",
                    "delete_warning": "Wollen sie dieses Bild wirklich löschen?"
                },
                "frame": {
                    "none": "keiner",
                    "rectangular": "rechteckig",
                    "elliptic": "rund",
                    "solidStroke": "durchgehend",
                    "dashedStroke": "strichliert",
                    "dottedStroke": "gepunktet",
                },
            },
            "preview": {
                "zoom": "Zoom"
            }
        }
    });
    locale.set(_locale);
}*/

async function setupI18n( {withLocale: _locale, fromUrl: translationsUrl } = {withLocale: 'en', fromUrl: "NONE" }) {
    if(translationsUrl === "NONE"){
        dictionary.set({
            [_locale]: {
                "templatechooser": {
                    "loading": "LOADING"
                }
            }
        });
        locale.set(_locale);
    }
    else {
        const messsagesFileUrl = translationsUrl.replace('{locale}', _locale);
        //console.log("translationsUrl "+ messsagesFileUrl);
        return fetch(messsagesFileUrl)
            .then(response => response.json())
            .then((messages) => {
                dictionary.set({ [_locale]: messages });
                cachedLocale = _locale;
                locale.set(_locale);
                return messages;
            }).catch(error => {
                dictionary.set({
                    [_locale]: {
                        "templatechooser": {
                            "loading": "LOADING"
                        }
                    }
                });
                locale.set(_locale);
            });
    }

}
export {_, dir, locale, setupI18n};