/* Configuration snippets */
import { rxjsOperators, Unexpected, workspace } from '@tagading/scenery-fabricjs';
import { get } from 'svelte/store';
import {c6ProjectUiSettings, c6WorkspaceConfiguration} from "./stores";


async function readConfigurationMap(apiConnector, workspaceId) {
    return new Promise((resolve, reject) => {
        var workspaceProvider = new workspace.WorkspaceProvider(apiConnector.getWorkspaceService());
        workspaceProvider.getConfigurationAttributesAsMap(workspaceId).pipe(rxjsOperators.first()).subscribe(function (result) {
            if (result instanceof Unexpected) {
                reject('Could not load workspace configuration');
            }
            else {
                resolve(result);
            }
        });
    });

}

async function readConfiguration(apiConnector, workspaceId) {
    return new Promise((resolve, reject) => {
        apiConnector.getWorkspaceService().getWorkspaceConfigurationAttributes(workspaceId).subscribe(function (attributes) {
            if (attributes instanceof Unexpected) {
                reject("could not read configuration");
            }
            else {
                for (var [key, area] of Object.entries(attributes.response)) {
                    if(area.key == "workspace"){
                        for (var [childkey, childarea] of Object.entries(area.childAttributes)) {
                            if(childarea.key == "workspace.visibility"){
                                for (var [wvkey, wvarea] of Object.entries(childarea.childAttributes)) {
                                    c6_template_config[wvarea.key]=wvarea.values[0];
                                }
                            }
                        }
                    }
                }
                resolve(attributes.response);
            }
        });
    });
}

async function getUiConfigs(configField){
    return new Promise((resolve, reject) => {
        let workspaceConfigValue = true;
        let projcectConfigValue = true;
        let workspaceConfiguration = get(c6WorkspaceConfiguration);
        if(workspaceConfiguration && workspaceConfiguration.get(configField) && workspaceConfiguration.get(configField).values) {
            workspaceConfigValue = (workspaceConfiguration.get(configField).values[0] === "true") ? true : false;
        }
        if(get(c6ProjectUiSettings) && get(c6ProjectUiSettings).hasOwnProperty(configField)){
            projcectConfigValue = get(c6ProjectUiSettings)[configField];
        }
        resolve (workspaceConfigValue && projcectConfigValue);
    });
}

export { readConfiguration, readConfigurationMap, getUiConfigs };