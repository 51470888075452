<script>
    import RangeSlider from "svelte-range-slider-pips";
    import Togglebuttons from './Togglebuttons.svelte';
    import {
        setFrameStrokeData,
        setFrameShape,
        setFrameBorderRadius,
        getFrameElementData, setFramePadding, setFrameGeometry
    } from '../../../resources/js/creator6-frame';
    import {getFabricElementById, getFabricValue} from '../../../resources/js/fabricjshelper';
    import {_} from '../../../services/i18n';
    //import {getWorkspaceFromId, getWorkspaceId} from '../../../resources/js/creator6-helper';
    //import { c6_validationElements, c6_sceneryCanvas, validateImprint, renderPage, registerSelection, c6_engineCanvas  } from '../../../resources/js/creator6-core';
    //import {  c6_imageProxyEndpoint, c6_apiConnector } from '../../../resources/js/creator6-init';
    import {
        c6WorkspaceFonts,
        c6ApiConnector,
        c6EngineCanvas,
        c6WorkspaceId,
        c6SceneryCanvas,
        c6WorkspaceContainerIds,
        c6ImageProxyEndpoint,
        c6WorkspaceMedium,
        c6WorkspaceConfiguration,
        c6ImprintFrames,
        c6WorkspaceMediumHeight,
        c6WorkspaceMediumWidth, c6SolutionType
    } from "../../../resources/js/stores";
    import Loader from "../../Loader.svelte";
    import {onMount} from "svelte";
    import {getUiConfigs} from "../../../resources/js/creator6-configuration";

    export let frameId;
    export let elementsTotal;

    let autolayouttext = ([frameId] in $c6WorkspaceContainerIds) ? $c6WorkspaceContainerIds[frameId] : null;
    let validationElements = false;

    let frameouter = true;
    let framedater = false;
    let minFrameStroke = 0.2;
    let maxFrameStroke = 2;
    let frameStroke = [0.2]; // TODO

    let borderRadius = [0];
    let maxBorderRadius = 10;
    let minBorderRadius = 0;

    let minFrameStrokeLength = 0.2;
    let maxFrameStrokeLength = 10;
    let frameStrokeLength = [0.2]; // TODO

    let minFrameStrokeSpace = 0.2;
    let maxFrameStrokeSpace = 10;
    let frameStrokeSpace = [0.2]; // TODO
    //$:frameStrokeWidth = $frameStroke;

    let autolayoutTextTop = 0.2 + frameStroke[0] + 0.2;
    let autolayoutTextLeft = 0.2 + frameStroke[0] + 0.2;

    let shape;
    let useInch = false;
    let unit = "mm";

    $:shape, submitFrameShape(event, shape, false);
    let shapeOptions = [];

    shapeOptions.push({
        value: 'None',
        label: $_('frame.button.none'),
    });
    shapeOptions.push({
        value: 'Rectangular',
        label: $_('frame.button.rectangular'),
    });

    let strokeStyle = "SolidStroke";
    $:strokeStyle, submitFrameStrokeData(event, 'strokeStyle', strokeStyle, false);

    const strokeStyleOptions = [{
        value: 'SolidStroke',
        label: $_('frame.button.solidStroke'),
        //icon: 'colop-icon-frame_simple_hoizontal icon-large',
    }, {
        value: 'DashedStroke',
        label: $_('frame.button.dashedStroke'),
        //icon: 'colop-icon-frame_dashed_hoizontal icon-large',
    }, {
        value: 'DottedStroke',
        label: $_('frame.button.dottedStroke'),
        //icon: 'colop-icon-frame_dotted_hoizontal icon-large',
    }];

    let frameInfoShow = (!($_('frame.info.bottomlabel') === " " && $_('frame.info.bottomdetails') === " "));

    let frameWidth = [0];
    let minFrameWidth;
    let maxFrameWidth = $c6WorkspaceMediumWidth - 1;

    let frameHeight = [0];
    let minFrameHeight;
    let maxFrameHeight = $c6WorkspaceMediumHeight - 1;

    let frameX = [0];
    let minFrameX = 1;
    let maxFrameX = $c6WorkspaceMediumWidth - 1;

    let frameY = [0];
    let minFrameY = 1;
    let maxFrameY = $c6WorkspaceMediumHeight - 1;

    let framePadding = [0];
    let minFramePadding = 0.2;
    let maxFramePadding = 2;

    const previewFrameStrokeData = (event, key, value) => {
        let newValue = getFabricValue(value);
        let canvasFabric = $c6EngineCanvas.fabricCanvas;
        let object = getFabricElementById(canvasFabric, frameId);
        let currentFrameStroke = object.strokeWidth/4.724409449;

        if (key === "strokeWidth" && autolayouttext) {
            let autolayouttextObject = getFabricElementById(canvasFabric, autolayouttext);
            let autolayouttextMove = getFabricValue(value);
            autolayouttextObject.left = autolayouttextObject.left + autolayouttextMove - getFabricValue(currentFrameStroke);
            autolayouttextObject.setCoords();
            object.set("width", object.width - newValue + getFabricValue(currentFrameStroke));
            object.setCoords();
        }
        frameStroke = [currentFrameStroke];
        object.set(key, newValue);
        canvasFabric.requestRenderAll();
    }

    const previewFrameDashArray = (event, key, value) => {
        let newValue = getFabricValue(value);
        let canvasFabric = $c6EngineCanvas.fabricCanvas;
        let object = getFabricElementById(canvasFabric, frameId);

        let length = object.strokeDashArray[0];
        let space = object.strokeDashArray[1];

        if (key === "space") {
            space = newValue;
            frameStrokeSpace= [value];
        }

        if (key === "length") {
            length = newValue;
            frameStrokeLength= [value];
        }

        object.set("strokeDashArray", [length, space]);
        canvasFabric.requestRenderAll();
    }

    async function submitFrameShape(event, value, startvalue) {
        if (value !== startvalue && value !== undefined) {
            try {
                if (value === "None") {
                    frameStroke = [0];
                    c6ImprintFrames.update(n => n - 1);
                    let updatedStroke = await submitFrameStrokeData(event, 'strokeWidth', frameStroke[0]);
                } else {
                    if (frameStroke[0] === 0) {
                        frameStroke = [0.2];
                        c6ImprintFrames.update(n => n + 1);
                        let updatedStroke = await submitFrameStrokeData(event, 'strokeWidth', frameStroke[0]);
                    }
                    let updated = await setFrameShape(frameId, value);
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    async function submitFrameStrokeData(event, key, value, startvalue) {
        if (value !== startvalue && value !== undefined) {
            try {

                if (key === "strokeStyle" && value === "DottedStroke") {
                    frameStrokeSpace = [1];
                }
                if (key === "strokeStyle" && value === "DashedStroke") {
                    frameStrokeSpace = [3];
                    frameStrokeLength = [3];
                }
                let updated = await setFrameStrokeData(frameId, key, value);
            } catch (err) {
                console.log(err);
            }
        }
    }


    const previewFrameBorderRadius = (event, value) => {
        let newValue = getFabricValue(value);
        let canvasFabric = $c6EngineCanvas.fabricCanvas;
        let object = getFabricElementById(canvasFabric, frameId);
        borderRadius = [value];
        object.set('rx', newValue);
        object.set('ry', newValue);
        canvasFabric.requestRenderAll();
    }

    async function submitFrameBorderRadius(event, value, startvalue) {
        if (value !== startvalue && value !== undefined) {
            try {
                let updated = await setFrameBorderRadius(frameId, value);
            } catch (err) {
                console.log(err);
            }
        }
    }

    const previewFrameSize = (event, key, value) => {
        let newValue = getFabricValue(value);
        let canvasFabric = $c6EngineCanvas.fabricCanvas;
        let object = getFabricElementById(canvasFabric, frameId);
        if(key === "x"){
            object.set('left', newValue);
            frameX = [value];
        }
        else if(key === "y"){
            object.set('top', newValue);
            frameY = [value];
        }
        else if(key === "height"){
            object.set('height', newValue);
            frameHeight = [value];
        }
        else if(key === "width"){
            object.set('width', newValue);
            frameWidth = [value];
        }
        canvasFabric.requestRenderAll();
    }

    async function submitFrameSize(event, key, value, startvalue) {
        if (value !== startvalue && value !== undefined) {
            try {
                const data = {};
                data[key]=value;
                let updated = await setFrameGeometry(frameId, data);
            } catch (err) {
                console.log(err);
            }
        }
    }

    const previewFramePadding = (event, value) => {
        framePadding = [value];
    }

    async function submitFramePadding(event, value, startvalue) {
        if (value !== startvalue && value !== undefined) {
            try {
                let updated = await setFramePadding(frameId, value);
            } catch (err) {
                console.log(err);
            }
        }
    }

    async function loadElementData(frameId) {
        if (frameId) {

        }
        let currentElementData = await getFrameElementData(frameId);

        shape = currentElementData.shape;
        if(currentElementData.stroke.strokeWidth === 0){
            shape = 'None';
        }

        framedater = false;
        if ("dataString" in currentElementData) {
            let customData = JSON.parse(currentElementData.dataString);
            if ("bordertype" in customData) {
                if (customData.bordertype === "datefield") {
                    framedater = true;
                }
            }
        }
        if(!framedater){
            shapeOptions.push({
                value: 'Elliptic',
                label: $_('frame.button.elliptic'),
            });
        }

        frameouter = !!(autolayouttext);
        frameStroke = [currentElementData.stroke.strokeWidth];
        if ("borderRadius" in currentElementData) {
            borderRadius = [currentElementData.borderRadius];
        } else {
            borderRadius = [0];
        }

        if ("length" in currentElementData) {
            frameStrokeLength = [currentElementData.stroke.length];
        } else {
            frameStrokeLength = [0];
        }

        if ("space" in currentElementData) {
            frameStrokeSpace = [currentElementData.stroke.space];
        } else {
            frameStrokeSpace = [0];
        }

        autolayoutTextTop = 0.2 + frameStroke[0] + 0.2;
        autolayoutTextLeft = 0.2 + frameStroke[0] + 0.2;
        strokeStyle = currentElementData.stroke.strokeStyle;

        frameWidth = [currentElementData.geometry.width];
        frameHeight = [currentElementData.geometry.height];
        frameX = [currentElementData.geometry.x];
        frameY = [currentElementData.geometry.y];
        framePadding = [currentElementData.padding];

        return currentElementData;
    }

    onMount(async () => {
        useInch = !await getUiConfigs('workspace.visibility.canvasinfomm');
        unit = (useInch) ? "" : "mm";
        return () => {
        };
    });


</script>

{#await loadElementData(frameId) }
    <Loader />
{:then frameLoaded}
    <div class="creator6-frame" data-c6frameid="{frameId}">
        {#if elementsTotal > 1}
            <div class="creator6-frame-preview">
                {#if frameouter}
                    <span class="colop-icon-tab_form icon-large"></span>
                    {$_('frame.info.outer')}
                {:else if framedater}
                    <span class="colop-icon-tab_form"></span>
                    {$_('frame.info.datefield')}
                {:else}
                    <span class="colop-icon-tab_form icon-large"></span>
                {/if}
            </div>
        {/if}
    <div class="creator6-warnings-errors">
        <div class="creator6-frame-warnings">
            {#if validationElements && frameId in validationElements && validationElements[frameId]}
                {$_('frame.warning.default')}
            {/if}
        </div>
        <div class="creator6-frame-alerts"></div>
    </div>

    <div class="creator6-frame-controls">
        <div class="creator6-frame-control creator6-frame-control-shape">
            <div class="creator6-control-label">{$_('frame.control.shape')}</div>
            <div class="creator6-frame-control-togglebuttongroup">
                <Togglebuttons options={shapeOptions} fontSize={16}  bind:valueSelected={shape} />
            </div>
        </div>
        {#if shape !== "None"}
            <div class="creator6-frame-control creator6-frame-control-strokestyle">
                <div class="creator6-control-label">{$_('frame.control.strokestyle')}</div>
                <div class="creator6-frame-control-togglebuttongroup">
                    <Togglebuttons options={strokeStyleOptions} fontSize={16}  bind:valueSelected={strokeStyle}/>
                </div>
            </div>
            {#if $c6SolutionType === "c6manager"}
                <div class="creator6-frame-control creator6-frame-control-width">
                    <div class="creator6-frame-label-input">
                        <div class="creator6-control-label">{$_('frame.control.width')}</div>
                        <div class="creator6-input-only creator6-input-only-{unit}">
                            <input type="number" step="0.1" bind:value={frameWidth[0]} min="{minFrameWidth}" max={maxFrameWidth} on:keyup={(e) => previewFrameSize(e, 'width', e.target.valueAsNumber, frameWidth[0])} on:focusout={(e) => submitFrameSize(e, 'width', e.target.valueAsNumber)}/>
                        </div>
                    </div>
                    <div class="creator6-frame-control-slider">
                        <RangeSlider suffix=" {unit}" values={frameWidth} bind:minFrameWidth bind:maxFrameWidth pips first='label' last='label'  step={0.1} min={minFrameWidth} max={maxFrameWidth} float on:change={(e) => previewFrameSize(e, 'width', e.detail.value)} on:stop={(e) => submitFrameSize(e, 'width', e.detail.value, e.detail.startvalue)} />
                    </div>
                </div>

                <div class="creator6-frame-control creator6-frame-control-height">
                    <div class="creator6-frame-label-input">
                        <div class="creator6-control-label">{$_('frame.control.height')}</div>
                        <div class="creator6-input-only creator6-input-only-{unit}">
                            <input type="number" step="0.1" bind:value={frameHeight[0]} min="{minFrameHeight}" max={maxFrameHeight} on:keyup={(e) => previewFrameSize(e, 'height', e.target.valueAsNumber, frameHeight[0])} on:focusout={(e) => submitFrameSize(e, 'height', e.target.valueAsNumber)}/>
                        </div>
                    </div>
                    <div class="creator6-frame-control-slider">
                        <RangeSlider suffix=" {unit}" values={frameHeight} bind:minFrameHeight bind:maxFrameHeight pips first='label' last='label'  step={0.1} min={minFrameHeight} max={maxFrameHeight} float on:change={(e) => previewFrameSize(e, 'height', e.detail.value)} on:stop={(e) => submitFrameSize(e, 'height', e.detail.value, e.detail.startvalue)} />
                    </div>
                </div>

                <div class="creator6-frame-control creator6-frame-control-x">
                    <div class="creator6-frame-label-input">
                        <div class="creator6-control-label">{$_('frame.control.x')}</div>
                        <div class="creator6-input-only creator6-input-only-{unit}">
                            <input type="number" step="0.1" bind:value={frameX[0]} min="{minFrameX}" max={maxFrameX} on:keyup={(e) => previewFrameSize(e, 'x', e.target.valueAsNumber, frameX[0])} on:focusout={(e) => submitFrameSize(e, 'x', e.target.valueAsNumber)}/>
                        </div>
                    </div>
                    <div class="creator6-frame-control-slider">
                        <RangeSlider suffix=" {unit}" values={frameX} bind:minFrameX bind:maxFrameX pips first='label' last='label'  step={0.1} min={minFrameX} max={maxFrameX} float on:change={(e) => previewFrameSize(e, 'x', e.detail.value)} on:stop={(e) => submitFrameSize(e, 'x', e.detail.value, e.detail.startvalue)} />
                    </div>
                </div>

                <div class="creator6-frame-control creator6-frame-control-y">
                    <div class="creator6-frame-label-input">
                        <div class="creator6-control-label">{$_('frame.control.y')}</div>
                        <div class="creator6-input-only creator6-input-only-{unit}">
                            <input type="number" step="0.1" bind:value={frameY[0]} min="{minFrameY}" max={maxFrameY} on:keyup={(e) => previewFrameSize(e, 'y', e.target.valueAsNumber, frameY[0])} on:focusout={(e) => submitFrameSize(e, 'y', e.target.valueAsNumber)}/>
                        </div>
                    </div>
                    <div class="creator6-frame-control-slider">
                        <RangeSlider suffix=" {unit}" values={frameY} bind:minFrameY bind:maxFrameY pips first='label' last='label'  step={0.1} min={minFrameY} max={maxFrameY} float on:change={(e) => previewFrameSize(e, 'y', e.detail.value)} on:stop={(e) => submitFrameSize(e, 'y', e.detail.value, e.detail.startvalue)} />
                    </div>
                </div>

                <div class="creator6-frame-control creator6-frame-control-padding">
                    <div class="creator6-frame-label-input">
                        <div class="creator6-control-label">{$_('frame.control.framepadding')}</div>
                        <div class="creator6-input-only creator6-input-only-{unit}">
                            <input type="number" step="0.1" bind:value={framePadding[0]} min="{minFramePadding}" max={maxFramePadding} on:keyup={(e) => previewFramePadding(e,  e.target.valueAsNumber, frameY[0])} on:focusout={(e) => submitFramePadding(e, e.target.valueAsNumber)}/>
                        </div>
                    </div>
                    <div class="creator6-frame-control-slider">
                        <RangeSlider suffix=" {unit}" values={framePadding} bind:minFramePadding bind:maxFramePadding pips first='label' last='label'  step={0.1} min={minFramePadding} max={maxFramePadding} float on:change={(e) => previewFramePadding(e, e.detail.value)} on:stop={(e) => submitFramePadding(e, e.detail.value, e.detail.startvalue)} />
                    </div>
                </div>
            {/if}
            {#if shape === "Rectangular" && !framedater}
                <div class="creator6-frame-control creator6-frame-control-borderradius">
                    <div class="creator6-frame-label-input">
                        <div class="creator6-control-label">{$_('frame.control.borderradius')}</div>
                        <div class="creator6-input-only creator6-input-only-{unit}">
                            <input type="number" step="0.5" bind:value={borderRadius[0]} min={minBorderRadius} max={maxBorderRadius} on:keyup={(e) => previewFrameBorderRadius(e, e.target.valueAsNumber)} on:focusout={(e) => submitFrameBorderRadius(e, e.target.valueAsNumber)}/>
                        </div>
                    </div>
                    <div class="creator6-frame-control-slider">
                        <RangeSlider suffix=" {unit}" values={borderRadius} bind:minBorderRadius bind:maxBorderRadius pips first='label' last='label'  step={0.5} min={minBorderRadius} max={maxBorderRadius} float on:change={(e) => previewFrameBorderRadius(e, e.detail.value)} on:stop={(e) => submitFrameBorderRadius(e, e.detail.value, e.detail.startvalue)} />
                    </div>
                </div>
            {/if}
        <div class="creator6-frame-control creator6-frame-control-strokewidth">
            <div class="creator6-frame-label-input">
                <div class="creator6-control-label">{$_('frame.control.strokewidth')}</div>
                <div class="creator6-input-only creator6-input-only-{unit}">
                    <input type="number" step="0.1" bind:value={frameStroke[0]} min="{minFrameStroke}" max={maxFrameStroke} on:keyup={(e) => previewFrameStrokeData(e, 'strokeWidth', e.target.valueAsNumber, frameStroke[0])} on:focusout={(e) => submitFrameStrokeData(e, 'strokeWidth', e.target.valueAsNumber)}/>
                </div>
            </div>
            <div class="creator6-frame-control-slider">
                <RangeSlider suffix=" {unit}" values={frameStroke} bind:minFrameStroke bind:maxFrameStroke pips first='label' last='label'  step={0.1} min={minFrameStroke} max={maxFrameStroke} float on:change={(e) => previewFrameStrokeData(e, 'strokeWidth', e.detail.value)} on:stop={(e) => submitFrameStrokeData(e, 'strokeWidth', e.detail.value, e.detail.startvalue)} />
            </div>
        </div>
        {/if}
        {#if strokeStyle === "DashedStroke"}
        <div class="creator6-frame-control creator6-frame-control-strokelength">
            <div class="creator6-frame-label-input">
                <div class="creator6-control-label">{$_('frame.control.strokelength')}</div>
                <div class="creator6-input-only creator6-input-only-{unit}">
                    <input type="number" step="0.1" bind:value={frameStrokeLength[0]} min="{minFrameStrokeLength}" max={maxFrameStrokeLength} on:keyup={(e) => previewFrameDashArray(e, 'length', e.target.valueAsNumber)} on:focusout={(e) => submitFrameStrokeData(e, 'length', e.target.valueAsNumber)}/>
                </div>
            </div>
            <div class="creator6-frame-control-slider">
                <RangeSlider suffix=" {unit}" values={frameStrokeLength} bind:minFrameStrokeLength bind:maxFrameStrokeLength pips first='label' last='label' step={0.1} pipstep="{5}" min={minFrameStrokeLength} max={maxFrameStrokeLength} float on:change={(e) => previewFrameDashArray(e, 'length', e.detail.value)} on:stop={(e) => submitFrameStrokeData(e, 'length', e.detail.value, e.detail.startvalue)} />
            </div>
        </div>
        {/if}
        {#if strokeStyle === "DashedStroke" || strokeStyle === "DottedStroke"}
        <div class="creator6-frame-control creator6-frame-control-strokespace">
            <div class="creator6-frame-label-input">
                <div class="creator6-control-label">{$_('frame.control.strokespace')}</div>
                <div class="creator6-input-only creator6-input-only-{unit}">
                    <input type="number" step="0.1" bind:value={frameStrokeSpace[0]} min="{minFrameStrokeSpace}" max={maxFrameStrokeSpace} on:keyup={(e) => previewFrameDashArray(e, 'space', e.target.valueAsNumber)} on:focusout={(e) => submitFrameStrokeData(e, 'space', e.target.valueAsNumber)}/>
                </div>
            </div>
            <div class="creator6-frame-control-slider">
                <RangeSlider suffix=" {unit}" values={frameStrokeSpace} bind:minFrameStrokeSpace bind:maxFrameStrokeSpace pips first='label' last='label' step={0.1} pipstep="{5}" min={minFrameStrokeSpace} max={maxFrameStrokeSpace} float on:change={(e) => previewFrameDashArray(e, 'space', e.detail.value)} on:stop={(e) => submitFrameStrokeData(e, 'space', e.detail.value, e.detail.startvalue)} />
            </div>
        </div>
        {/if}




    </div>
    {#if frameInfoShow}
        <div class="creator6-frame-info">
            <div class="creator6-frame-info-label">{@html $_('frame.info.bottomlabel')}</div>
            <div class="creator6-frame-info-details">{@html $_('frame.info.bottomdetails')}</div>
        </div>
    {/if}
</div>

    {/await}
<style>
    @media (min-width: 768px) {

        .creator6-frame-control {
            display: inline-flex;
            align-items: stretch;
            width: 100%;
        }
        .creator6-frame-control-strokewidth {
            margin-top:0;
        }
    }

    .creator6-frame {
        display:block;
        border-top: 1px solid #ddd;
        position:relative;
    }
    .creator6-frame:first-child {
        border-top:none;
        padding-top:10px
    }

    .creator6-frame-controls {
        width:100%;
        padding: 0 10px;
    }

    .creator6-frame-preview {
        width: 100%;
        padding: 10px;
        display: inline-flex;
        align-items: center;
        opacity: 0.5;
    }

    .creator6-frame-control {
        border-top: 1px solid var(--creator6-controls-spacer);
        margin: 0 0 10px 0;
    }

    .creator6-frame-control.creator6-frame-control-shape {
        border-top: none;
    }

    .creator6-frame-control input {
        width: 60px;
        -moz-appearance: textfield;
        text-align: right;
        border: none;
        background: var(--creator6-controls-input-bg);
        height: 29px;
        border-bottom: 1px solid #f3f3f3;
        padding-right:22px;
        color: var(--creator6-color-blue);
        margin: 0 10px;
    }

    .creator6-frame-control .icon-large {
        margin-right:1em;
    }

    .creator6-frame-control-togglebuttongroup {
        display: inline-block;
        height: 40px;
        width: 100%;
        margin-top: 0.5em;
    }

    .creator6-frame-control-slider {
        width:90%;
        margin-top: 0.5em;
        margin-left: 1em;
        margin-right: 1em;
    }

    :global(.creator6-frame-control-slider .pipVal) {
        font-size:var(--creator6-fontsize-small);
    }

    .creator6-control-label {
        min-width: 150px;
    }

    .creator6-frame-label-input {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .creator6-input-only{
        border: 1px solid var(--creator6-controls-bg);
        border-radius: var(--creator6-border-radius);
        background: var(--creator6-controls-bg);
        margin: 5px;
        white-space: nowrap;
        height:40px;
        display:flex;
        position: relative;
        align-items: center;
    }

    .creator6-input-only-mm::after {
        position: absolute;
        right: 10px;
        font-size: var(--creator6-fontsize-small);
        color: #aaa;
        text-align: left;
        content:"mm";
    }

    .creator6-frame-info{
        border: 1px solid var(--creator6-color-blue);
        background: #2b77bc;
        margin: 10px 0 0 0;
        padding: 10px;
        color: white;
    }



</style>