import {renderPage, registerSelection} from "./creator6-core";
import {getWorkspaceFromId, getPageFromWorkspaceId, getCurrentPage, setWorkspaceId, getWorkspaceId, setSelectedElement, getSelectedElement} from './creator6-helper';
import {Unexpected, rxjsOperators} from "@tagading/scenery-fabricjs";
import { get } from 'svelte/store';
import {c6ImprintTexts, c6ApiConnector, c6EngineCanvas, c6WorkspaceId, c6PermaLinksEndpoint} from "./stores";
import {convertRtlRoundText} from "./rtlroundtext";

let timeouttext = 0;
let partialRendering = true;

function updateText(event, forceRtl = false, autoFillUp = null){
    var input = event.target;

    input.checkValidity();
    if(input.validity.valid) {
        if(event.keyCode === 9){
            editText(event, forceRtl, input.validity.valid, autoFillUp);
        }
        else {
            clearTimeout(timeouttext);
            timeouttext = setTimeout(function() {
                // enter code here or a execute function.
                editText(event, forceRtl, input.validity.valid, autoFillUp);
            }, 1000);
        }
    }
    else {
        console.log("invalid text --> NO UPDATE");
        return;
    }
}

async function getTextElementData(sceneElementId){
    return new Promise((resolve, reject) => {
        let update = getCurrentPage().textElementUpdate();
        update.getText(sceneElementId).subscribe(function (elementModel) {
            //console.log(elementModel);
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get text elment model');
                reject(false);
            }
            else {
                let currentImprintLines = get(c6ImprintTexts);
                currentImprintLines[elementModel.id] = elementModel.lines;
                c6ImprintTexts.set(currentImprintLines);
                resolve(elementModel);
            }
        });
    });
}

async function setTextElementDataString(sceneElementId, dataString){
    return new Promise((resolve, reject) => {
        let update = getCurrentPage().textElementUpdate();
        update.getText(sceneElementId).subscribe(function (elementModel) {
            //console.log(elementModel);
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get text elment model');
                reject(false);
            }
            else {
                //console.log("elementModel");
                //console.log(elementModel);
                elementModel.dataString = JSON.stringify(dataString);
                update.patchTextElement(elementModel, {dataString : JSON.stringify(dataString)}).subscribe(function (result) {
                    resolve(true);
                });
            }
        });
    });
}

async function setTextLines(sceneElementId, elementModel, forceRendering = false){
    //console.log("change text lines result: "+sceneElementId);
    //console.log(elementModel);
    return new Promise((resolve, reject) => {
        let update = getCurrentPage().textElementUpdate();
        update.updateTextDetails(elementModel, elementModel.details).subscribe(function (result) {
            //console.log(result);
            let currentImprintLines = get(c6ImprintTexts);
            currentImprintLines[elementModel.id] = elementModel.lines;
            c6ImprintTexts.set(currentImprintLines);
            // renderPage();
            if(forceRendering){renderPage(partialRendering);}

            resolve(true);
        });
    });
}

function contains_heb(str) {
    return (/[\u0590-\u05FF]/).test(str);
}

function editText(event, forceRtl, valid = true, autoFillUp = null) {
    if (valid && event.target && event.target.className.includes('autolayout')) {
        var sceneElementId = event.target.getAttribute('data-elementid');
        var update = getCurrentPage().textElementUpdate();
        update.getText(sceneElementId).subscribe(function (elementModel) {
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get text elment model');
            }
            else {
                if(!forceRtl){
                    forceRtl = contains_heb(event.target.value);
                }
                let newText = (forceRtl) ? convertRtlRoundText(event.target.value) : event.target.value;
                if(autoFillUp){
                    if(newText.length == autoFillUp.length){
                        if(autoFillUp.prefix) {
                            newText = autoFillUp.char + newText;
                        }
                        if(autoFillUp.suffix) {
                            newText = newText + autoFillUp.char;
                        }
                    }
                }

                elementModel.lines[event.target.getAttribute('data-textline')]=newText;
                update.updateTextDetails(elementModel, elementModel.details).subscribe(function (result) {
                    let currentImprintLines = get(c6ImprintTexts);
                    currentImprintLines[elementModel.id] = elementModel.lines;
                    c6ImprintTexts.set(currentImprintLines);
                    renderPage(partialRendering);
                });
            }
        });
    }
}

function editTextByElementId(sceneElementId, line, newText, forceRtl = false) {
    //console.log("edit TEXT valid: "+ valid);
    var update = getCurrentPage().textElementUpdate();

    update.getText(sceneElementId).subscribe(function (elementModel) {
        //console.log(elementModel);
        if (elementModel instanceof Unexpected) {
            console.error('Updater could not get text elment model');
            throw 'Updater could not get text elment model';
        }
        else {
            newText = (forceRtl) ? convertRtlRoundText(newText) : newText;
            elementModel.lines[line]=newText;
            update.updateTextDetails(elementModel, elementModel.details).subscribe(function (result) {
                if(result === true){
                    let currentImprintLines = get(c6ImprintTexts);
                    currentImprintLines[elementModel.id] = elementModel.lines;
                    c6ImprintTexts.set(currentImprintLines);
                    renderPage(partialRendering);
                    return true;
                }
                else {
                    throw 'Updater could not get text elment model';
                }
            });
        }
    });
}

function textSize(sceneElementId, line, newSize, relativ, changeAll){
    var update = getCurrentPage().textElementUpdate();
    update.getText(sceneElementId).pipe(rxjsOperators.first()).subscribe(function (elementModel) {
        if (elementModel instanceof Unexpected) {
            console.error('Updater could not get text elment model');
        }
        else {
            if(relativ) newSize = elementModel.details.textLines[line].fontSize + newSize;
            elementModel.details.textLines[line].fontSize = newSize;
            //elementModel.details.textLines[line].letterSpacing = 10;

            update.updateTextDetails(elementModel, elementModel.details).pipe(rxjsOperators.first()).subscribe(function (result) {
                renderPage(partialRendering);
            });
        }
    });
}

function textSizeAbsolute(sceneElementId, lines, value){
    textLineDetailsUpdate(sceneElementId, lines, 'fontSize', value);
}



function textLineHeightAbsolute(sceneElementId, lines, value){
    textLineDetailsUpdate(sceneElementId, lines, 'lineHeight', value);
}

function textBlockDetailsUpdate(sceneElementId, key, value, doUpdate = true){

    if(value === undefined) return;
    if(!doUpdate) return;

    //console.log("sceneElementId:"+sceneElementId + " key:"+key+ " value:"+value+ " realtiv:"+relativ);
    var update = getCurrentPage().textElementUpdate();
    update.getText(sceneElementId).pipe(rxjsOperators.first()).subscribe(function (elementModel) {
        //console.log(elementModel);
        if (elementModel instanceof Unexpected) {
            console.error('Updater could not get text elment model');
        }
        else {
            let needUpdateTextDetails = (elementModel.details[key] !== value);

            if(needUpdateTextDetails) {
                elementModel.details[key]= value;
                update.updateTextDetails(elementModel, elementModel.details).pipe(rxjsOperators.first()).subscribe(function (result) {
                    if(result === true){
                        renderPage(partialRendering);
                        return true;
                    }
                    else {
                        throw 'Could not update block details.'
                    }
                });

            }
            else {
                return true;
            }
        }
    });
}

function textLineDetailsUpdate(sceneElementId, lines, key, value, relativ, doUpdate = true){

    //console.log("doUpdate for key "+key+": "+ doUpdate);
    //console.log("update lines:");
    //console.log(lines);

    if(value === undefined || lines === 0) return;
    if(!doUpdate && key === "alignment") return;
    if(!doUpdate && key === "hexColor") return;

    //console.log("sceneElementId:"+sceneElementId + " key:"+key+ " value:"+value+ " realtiv:"+relativ);
    var update = getCurrentPage().textElementUpdate();
    update.getText(sceneElementId).pipe(rxjsOperators.first()).subscribe(function (elementModel) {
        //console.log(elementModel);
        if (elementModel instanceof Unexpected) {
            console.error('Updater could not get text elment model');
        }
        else {
            let needUpdateTextDetails = false;
            lines.forEach(function (tobechanged, line) {
                //console.log("Line: "+ line + " --> "+key+" tobechanged = "+tobechanged + " to: "+value);
                if(tobechanged){
                    let prevValue = elementModel.details.textLines[line][key];
                    if(relativ) value = elementModel.details.textLines[line][key] + relativ;

                    if(key == "hexColor" && value === "") {
                        delete elementModel.details.textLines[line][key];
                    }
                    else {
                        elementModel.details.textLines[line][key] = value;
                    }

                    let newValue = elementModel.details.textLines[line][key];
                    needUpdateTextDetails = (prevValue !== newValue);
                    //console.log("Line: "+ line + " --> "+key+" tobechanged = "+tobechanged + " from: "+prevValue+" to: "+newValue);
                }
            });
            /*
            let prevValue = elementModel.details.textLines[line][key];
            if(relativ) value = elementModel.details.textLines[line][key] + value;
            elementModel.details.textLines[line][key] = value;
            let newValue = elementModel.details.textLines[line][key];*/
            if( needUpdateTextDetails){
                update.updateTextDetails(elementModel, elementModel.details).pipe(rxjsOperators.first()).subscribe(function (result) {
                    if(result === true){
                        renderPage(partialRendering);
                        return true;
                    }
                    else {
                        throw 'Could not update font size.'
                    }
                });
            }
            else {
                return true;
            }
        }
    });
}

function textLineFontUpdate(sceneElementId, line, family, weight, italic, doUpdate = true){
    //console.log("set Font: sceneElementId:"+sceneElementId+" line:"+line+" family:"+family + " weight:"+weight+" italic:"+italic + "-> doUpdate:"+doUpdate);
    if(!doUpdate) return;

    if(family === undefined || weight === undefined || italic === undefined ) return;

    var update = getCurrentPage(get(c6ApiConnector), get(c6EngineCanvas), get(c6WorkspaceId)).textElementUpdate();
    update.getText(sceneElementId).pipe(rxjsOperators.first()).subscribe(function (elementModel) {
        //console.log(elementModel);
        //console.log("vs");
        //console.log("sceneElementId:"+sceneElementId+" line:"+line+" family:"+family + " weight:"+weight+" italic:"+italic);
        if (elementModel instanceof Unexpected) {
            console.error('Updater could not get text elment model');
        }
        else {

            //console.log('Updater loaded -> do update');
            //console.log(elementModel.details.textLines[line]);
            //console.log("family: "+ ("fontFamily" in elementModel.details.textLines[line] && elementModel.details.textLines[line]['fontFamily'] !== family));
            //console.log("weight: "+ ("fontWeight" in elementModel.details.textLines[line] && elementModel.details.textLines[line]['fontWeight'] !== weight));
            //console.log("italic: "+ ("fontItalic" in elementModel.details.textLines[line] && elementModel.details.textLines[line]['fontItalic'] !== italic));

            if(
                ("fontFamily" in elementModel.details.textLines[line] && elementModel.details.textLines[line]['fontFamily'] !== family) ||
                ("fontWeight" in elementModel.details.textLines[line] && elementModel.details.textLines[line]['fontWeight'] !== weight) ||
                ("fontItalic" in elementModel.details.textLines[line] && elementModel.details.textLines[line]['fontItalic'] !== italic)
            ) {

                elementModel.details.textLines[line]['fontFamily'] = family;
                elementModel.details.textLines[line]['fontWeight'] = weight;
                elementModel.details.textLines[line]['fontItalic'] = italic;

                //console.log("text font update to");
                //console.log(elementModel.details);

                update.updateTextDetails(elementModel, elementModel.details).pipe(rxjsOperators.first()).subscribe(function (result) {
                    renderPage(partialRendering);
                });
            }

        }
    });
}

function textElementGeometryUpdate(sceneElementId, field, value){
    if(value === undefined) return;

    var update = getCurrentPage(get(c6ApiConnector), get(c6EngineCanvas), get(c6WorkspaceId)).textElementUpdate();
    update.getText(sceneElementId).pipe(rxjsOperators.first()).subscribe(function (elementModel) {
        //console.log(elementModel);
        //console.log("field:"+field + " value:"+value);

        if (elementModel instanceof Unexpected) {
            console.error('Updater could not get text elment model');
        }
        else {
            if(field === "all"){
                elementModel.geometry= value;
            }
            else {
                elementModel.geometry[field]= value;
            }

            //console.log("text geometry update to");
            //console.log(elementModel.geometry);
            update.updateTextGeometry(elementModel, elementModel.geometry).pipe(rxjsOperators.first()).subscribe(function (result) {
                renderPage(partialRendering);
            });
        }
    });
}

function addTextLine(sceneElementId){
    var update = getCurrentPage().textElementUpdate();
    update.getText(sceneElementId).pipe(rxjsOperators.first()).subscribe(function (elementModel) {
        if (elementModel instanceof Unexpected) {
            console.error('Updater could not get text elment model');
        }
        else {
            //console.log("elementModel ADD LINE");
            //console.log(elementModel);

            //console.log(elementModel.details.textLines.length);
            //console.log(elementModel.lines.length);
            let textLinesLength = elementModel.details.textLines.length;
            let addTextLineData = elementModel.details.textLines[textLinesLength-1];
            elementModel.details.textLines.push(addTextLineData);
            elementModel.lines.push("");

            update.updateTextDetails(elementModel, elementModel.details).pipe(rxjsOperators.first()).subscribe(function (result) {
                renderPage(partialRendering);
            });
        }
    });
}

function removeTextLine(sceneElementId){
    var update = getCurrentPage().textElementUpdate();
    update.getText(sceneElementId).pipe(rxjsOperators.first()).subscribe(function (elementModel) {
        if (elementModel instanceof Unexpected) {
            console.error('Updater could not get text elment model');
        }
        else {
            let textLinesLength = elementModel.details.textLines.length;
            elementModel.details.textLines.splice(textLinesLength-1);
            elementModel.lines.splice(textLinesLength-1);

            update.updateTextDetails(elementModel, elementModel.details).pipe(rxjsOperators.first()).subscribe(function (result) {
                renderPage(partialRendering);
            });
        }
    });
}

export {textBlockDetailsUpdate, addTextLine, removeTextLine, editTextByElementId, getTextElementData, setTextElementDataString, setTextLines, updateText, textSizeAbsolute, textLineFontUpdate, textLineDetailsUpdate, textLineHeightAbsolute, textElementGeometryUpdate}