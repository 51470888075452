//import {c6_previewPdfEndpoint, c6_apiConnector, c6_productionPdfEndpoint} from "./creator6-init";
import {getWorkspaceId} from "./creator6-helper";
import { get } from 'svelte/store';
import {c6ApiConnector, c6ProductionPdfEndpoint, c6WorkspaceId} from "./stores";


function showFile(blob, filename){
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], {type: "application/pdf"})

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download=filename+"_file.pdf";
    link.click();
    setTimeout(function(){
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
}




    async function printEosNupProductionFile(filename, eosId, flashMachine, pathPrefix, productionServiceUrl){
        //var workSpace = getWorkspaceFromId(c6_apiConnector, getWorkspaceId());
        //var previewUrlWorkspace = workSpace.getPageExportDownloadUrl(1, 'PDF');
        var connection = get(c6ApiConnector).getGateway().getFrontendApi().connection;
        var resource = "workspaces/"+get(c6WorkspaceId)+"/pages/1/views/preview?format=pdf&padcolor=black";
        var pdfUrl = `${connection.baseUrl}/${resource}`;
        //console.log("previewURL: "+ previewUrl);
        let printMedium = "n-up";

        //printmedium = vellum-A6, mediasheet-a4,frame-only-vellum,image-card,n-up
        var getParams=printMedium+"?productId="+eosId+"&flashMachine="+flashMachine+"&pathPrefix="+pathPrefix+"&imprint[0][id]="+pdfUrl+"&imprint[0][pos][]=0&imprint[0][pos][]=1&imprint[0][pos][]=2&imprint[0][pos][]=3&imprint[0][pos][]=4&imprint[0][pos][]=5&testMode=0";

        if(productionServiceUrl === undefined){
            productionServiceUrl = get(c6ProductionPdfEndpoint);
        }
        var previewPDFUrl = productionServiceUrl+getParams;

        fetch(`${previewPDFUrl}`, {
            method: 'GET'
            //body: formData
        }).then(r => r.blob()).then(r => {showFile( r,filename) });
    }

async function getPlateProductionUrlNup(page= 1, filename, eosId, flashMachine, pathPrefix, productionServiceUrl){
    var connection = get(c6ApiConnector).getGateway().getFrontendApi().connection;
    var resource = "workspaces/"+get(c6WorkspaceId)+"/pages/"+page+"/views/preview?format=pdf&padcolor=black";
    var pdfUrl = `${connection.baseUrl}/${resource}`;
    let printMedium = "n-up";
    //printmedium = vellum-A6, mediasheet-a4,frame-only-vellum,image-card,n-up
    var getParams=printMedium+"?productId="+eosId+"&flashMachine="+flashMachine+"&pathPrefix="+pathPrefix+"&imprint[0][id]="+pdfUrl+"&imprint[0][pos][]=0&imprint[0][pos][]=1&imprint[0][pos][]=2&imprint[0][pos][]=3&imprint[0][pos][]=4&imprint[0][pos][]=5&testMode=0";

    if(productionServiceUrl === undefined){
        productionServiceUrl = get(c6ProductionPdfEndpoint);
    }
    return productionServiceUrl+getParams;
}

async function printEosProductionFile(filename, printMedium, eosId, xpress, padColor, language, productionServiceUrl){
    //var workSpace = getWorkspaceFromId(c6_apiConnector, getWorkspaceId());
    //var previewUrlWorkspace = workSpace.getPageExportDownloadUrl(1, 'PDF');
    var connection = get(c6ApiConnector).getGateway().getFrontendApi().connection;
    var resource = "workspaces/"+get(c6WorkspaceId)+"/pages/1/views/preview?format=pdf&padcolor=black";
    var pdfUrl = `${connection.baseUrl}/${resource}`;
    //console.log("previewURL: "+ previewUrl);
    let datetime = new Date().toDateString();
    //printmedium = vellum-A6, mediasheet-a4,frame-only-vellum,image-card,n-up
    var getParams=printMedium+"?productId="+eosId+"&language="+language+"&info="+datetime+"&colorInfo="+padColor+"&imprint="+pdfUrl+"&name="+filename+"&testMode=0";
    if(xpress){
        getParams = getParams + "&express="+xpress;
    }

    if(productionServiceUrl === undefined){
        productionServiceUrl = get(c6ProductionPdfEndpoint);
    }
    var previewPDFUrl = productionServiceUrl+getParams;

    fetch(`${previewPDFUrl}`, {
        method: 'GET'
        //body: formData
    }).then(r => r.blob()).then(r => {showFile( r,filename) });
}


async function getPlateProductionUrl(page= 1, filename, printMedium, eosId, imageCard, xpress, padColor, language, productionServiceUrl){
    var connection = get(c6ApiConnector).getGateway().getFrontendApi().connection;
    var resource = "workspaces/"+get(c6WorkspaceId)+"/pages/"+page+"/views/preview?format=pdf&padcolor=black";
    var pdfUrl = `${connection.baseUrl}/${resource}`;
    let datetime = new Date().toDateString();
    //printmedium = vellum-A6, mediasheet-a4,frame-only-vellum,image-card,n-up
    var getParams=printMedium+"?productId="+eosId+"&language="+language+"&info="+datetime+"&colorInfo="+padColor+"&imprint="+pdfUrl+"&imagecard="+imageCard+"&name="+filename+"&testMode=0";
    if(xpress){
        getParams = getParams + "&express="+xpress;
    }
    if(productionServiceUrl === undefined){
        productionServiceUrl = get(c6ProductionPdfEndpoint);
    }
    return productionServiceUrl+getParams;
}
export {printEosProductionFile, printEosNupProductionFile, getPlateProductionUrl, getPlateProductionUrlNup, showFile}