<script>
    import RangeSlider from "svelte-range-slider-pips";
    import { setZoom } from '../../resources/js/creator6-zoom'
    import {c6WorkspaceZoom} from "../../resources/js/stores";

    export let currentZoom = [$c6WorkspaceZoom];
    let minZoom = 50;
    let maxZoom = 300;
    let stepZoom = 10;

    function getData(e) {
        console.log("data");
        console.log(e);
    }

    let hue = [244];
    $: lightColor = `hsl(${Math.round(currentZoom[0]) - 10}, 65%, 70%)`;
    $: color = `hsl(${Math.round(currentZoom[0])}, 63%, 54%)`;


</script>

 <div class="creator6-zoomslider">
        <div class="creator6-slidecontainer">
            <RangeSlider id="zoom-pips" bind:values={currentZoom} suffix="%"
                         slider="0"
                         min={minZoom} max={maxZoom} float
                         pips step={1} pipstep={50}
                         all='label' first={false} last={false}
                         on:change={(e) => setZoom(e.detail.value)}
                />
        </div>
</div>

<style>

    :global(#zoom-pips) {
        height: 13px;
    }
    :global(#zoom-pips .rangeBar) {
        height: 13px;
    }
    :global(#zoom-pips .rangeHandle) {
        top: 7px;
        height: 30px;
        width: 30px;
    }
    :global(#zoom-pips .rangeHandle .rangeNub) {
        border: 3px solid rgb(246, 252, 255);
    }
    :global(#zoom-pips .rangePips) {
        bottom: -3px;
        z-index: 1;
    }
    :global(#zoom-pips .rangePips .pip) {
        background: rgb(246, 252, 255);
        border-radius: 10px;
        width: 5px;
        height: 5px;
        transform: translateX(-50%);
    }
    :global(#zoom-pips .rangePips .pip.in-range) {
        background: rgb(0, 0, 0);
        opacity: 0.35;
    }

    :global(#zoom-pips.rangeSlider .rangeHandle::before) {
        content: "";
        left: 3px;
        top: 9px;
        bottom: 1px;
        right: 1px;
        height: 40%;
        width: 40%;
    }

    :global(#zoom-pips.rangeSlider .rangeNub, .rangeSlider .rangeHandle::before) {
        position: absolute;
        left: 0;
        top: 5px;
        display: block;
        border-radius: 10em;
        height: 40%;
        width: 40%;
        transition: box-shadow 0.2s ease;
        box-sizing:unset;
    }

    .creator6-zoomslider {
        max-width: 350px;
        height:50px;
        opacity: 0.8;
        /*float:right;*/
    }
    .creator6-zoomslider:after{
        /*clear:right;*/
    }

    .creator6-slidecontainer {
        width: 100%;
    }

</style>