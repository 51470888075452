<script>
    import {c6ProductName} from "../../resources/js/stores";
    import {_} from '../../services/i18n';
    import PrintPreview from "./PrintPreview.svelte";
    import ReturnForm from "./ReturnForm.svelte";
</script>

<section>
    <div class="creator6-integration-productname">{$c6ProductName}</div>
</section>
<section>
    <PrintPreview />
</section>

<section>
    <ReturnForm />
</section>


<style>
    section {
        padding: 0 20px;
    }
    hr {border:1px solid var(--creator6-button-bg);}

    .creator6-integration-productname {
        margin: 10px auto 0;
        font-size: var(--creator6-fontsize-bigger);
        text-align: center;
        color: var(--creator6-textcolor-primary);
    }
</style>