<script>
    import {getFontPreviewImageUrl, getFontVariantNameByWeight, getFontPreview, getFontStylePreview, getFontVariants} from '../../../resources/js/creator6-fonts';
    import { onMount } from 'svelte';
    import {c6WorkspaceFonts} from "../../../resources/js/stores";

    export let selectedFontFamily;
    export let selectedFontWeight;
    export let selectedFontItalic;
    export let valueClicked;

    let fontdropdown;
    let fontstyledropdown;
    let ul;
    let ulstyle;
    let dt;
    let dtstyle;
    let fontpreview;
    let fontstylepreview;
    let open;
    let ulstyleopen;
    let currentVariants;
    let dropdownFontIcon = "colop-icon-arrow_down";
    let dropdownFontStyleIcon = "colop-icon-arrow_down";

    let fontFamilyPreviewImgUrl;
    let fontStylePreviewImgUrl;

    $:selectedFontFamily, updateFontFamilyDropdown();
    $:selectedFontWeight, updateFontStyleDropdown();
    $:selectedFontItalic, updateFontStyleDropdown();

    async function updateFontFamilyDropdown(){
        let fontSampleId = await getFontPreview($c6WorkspaceFonts, selectedFontFamily, true);
        //console.log("font Sample Id for " + selectedFontFamily + " is " + fontSampleId);
        fontFamilyPreviewImgUrl = getFontPreviewImageUrl(fontSampleId);
        return true;
    }

    async function updateFontStyleDropdown(){
        currentVariants = await getFontVariants($c6WorkspaceFonts, selectedFontFamily);
        let fontStylePreviewSrc = await getFontStylePreview(currentVariants, selectedFontFamily, selectedFontWeight, selectedFontItalic, false)
        fontStylePreviewImgUrl = getFontPreviewImageUrl(fontStylePreviewSrc);
        return true;
    }

    onMount(async () => {
        open = false;
        ul = fontdropdown.querySelectorAll('ul')[0];
        dt = fontdropdown.querySelectorAll('dt')[0];
        fontpreview = dt.querySelectorAll('img')[0];


        ulstyle = fontstyledropdown.querySelectorAll('ul')[0];
        dtstyle = fontstyledropdown.querySelectorAll('dt')[0];
        fontstylepreview = dt.querySelectorAll('span')[0];
        let fontSampleId = await getFontPreview($c6WorkspaceFonts, selectedFontFamily, true);
        //console.log("font Sample Id for " + selectedFontFamily + " is " + fontSampleId);
        await selectFont(selectedFontFamily, fontSampleId);
        currentVariants = await getFontVariants($c6WorkspaceFonts, selectedFontFamily); //Todo
        //selectedFontWeight = 400;//Todo
        //selectedFontItalic = false;//Todo

        let fontStylePreviewSrc = await getFontStylePreview(currentVariants, selectedFontFamily, selectedFontWeight, selectedFontItalic, false)
        fontStylePreviewImgUrl = getFontPreviewImageUrl(fontStylePreviewSrc);

    });

    function toogleUl(){
        if (ul.style.display === "none") {
            ul.style.display = "block";
            open = true;
            dropdownFontIcon = "colop-icon-arrow_up";

        } else {
            ul.style.display = "none";
            open = false;
            dropdownFontIcon = "colop-icon-arrow_down";
        }
    }

    function toogleUlStyle(){
        //console.log("font selection ul style: "+ulstyle.style.display);
        if (ulstyle.style.display === "none") {
            ulstyle.style.display = "block";
            ulstyleopen = true;
            dropdownFontStyleIcon = "colop-icon-arrow_up";

        } else {
            ulstyle.style.display = "none";
            ulstyleopen = false;
            dropdownFontStyleIcon = "colop-icon-arrow_down";
        }
    }

    async function selectFont(fontname, previewLinkId, previewLinkIdLong){
        //console.log("font selected: "+ fontname + " --> "+previewLinkId + " --> "+previewLinkIdLong);
        let fontpreviewSrc = (previewLinkIdLong) ? previewLinkIdLong : previewLinkId;
        fontFamilyPreviewImgUrl = getFontPreviewImageUrl(fontpreviewSrc);
        selectedFontFamily = fontname;
        currentVariants = await getFontVariants($c6WorkspaceFonts, fontname);
        toogleUl();
    }

   async function selectFontStyle(weight, italic){
       //console.log("set weight: "+weight + " and style italic: "+italic);
       selectedFontWeight = weight;
       selectedFontItalic = italic;
       let fontStylePreviewSrc = await getFontStylePreview(currentVariants, selectedFontFamily, weight, italic, false)
       fontStylePreviewImgUrl = getFontPreviewImageUrl(fontStylePreviewSrc);
        //console.log("fontStylePreviewSrc: "+fontStylePreviewSrc);
       //console.log("fontStylePreviewImgUrl: "+fontStylePreviewImgUrl);
       toogleUlStyle();
   }

</script>

<dl bind:this={fontdropdown} class="creator6-font-dropdown {open ? 'open' : ''}">
    <dt on:click={toogleUl}><span class="selectedfont"><img src="{fontFamilyPreviewImgUrl}" alt="{selectedFontFamily}"/></span><span class="creator6-font-dropdown-arrow {dropdownFontIcon}"></span></dt>
    <dd>
        <ul>
            {#each $c6WorkspaceFonts as availableFont}
                <li on:click={(e) => {valueClicked = true; selectFont(availableFont.fontFamily, availableFont.sample, availableFont.sampleLong)}}><img class="creator6-font-preview" src="{getFontPreviewImageUrl(availableFont.sampleLong)}" alt="{availableFont.fontFamily}" /></li>
            {/each}
        </ul>
    </dd>
</dl>


<dl bind:this={fontstyledropdown} class="creator6-font-dropdown creator6-font-style-dropdown {ulstyleopen ? 'open' : ''}">
    <dt on:click={toogleUlStyle}><span class="selectedfontstyle"><img src="{fontStylePreviewImgUrl}" alt="{getFontVariantNameByWeight(selectedFontWeight)} {(selectedFontItalic) ? 'Italic' : ''}"/></span><span class="creator6-font-dropdown-arrow {dropdownFontStyleIcon}"></span></dt>
    <dd>
        <ul style="display: none;">
            {#if currentVariants}
                {#each currentVariants as variant}
                    <li style='font-weight:{variant.weight}; {(variant.italic) ? "font-style:italic;" : ""} ' on:click={(e) => {valueClicked = true; selectFontStyle(variant.weight, variant.italic)}}><img class="creator6-font-preview" src="{getFontPreviewImageUrl(variant.sample)}" alt="variantPreview"/> {getFontVariantNameByWeight(variant.weight)} {(variant.italic) ? "Italic" : ""}</li>
                {/each}
            {/if}
        </ul>
    </dd>
</dl>

<style>
    .creator6-font-dropdown {position: relative;
        z-index: 100;
        margin: 0;
        display: inline-grid;
    }
    .creator6-font-dropdown:hover {
        background:var(--creator6-controls-hover);
        border-radius: var(--creator6-border-radius);
    }
    .creator6-font-dropdown dt span.creator6-font-dropdown-arrow {
        position:absolute;
        right:0;
        top:0;
        padding:2px;
    }
    .creator6-font-dropdown .selectedfont, .creator6-font-dropdown .selectedfontstyle, .creator6-font-dropdown dt img, .creator6-font-dropdown li img {height:20px;}
    .creator6-font-dropdown img {height:100%;}

    .creator6-font-dropdown dd, .creator6-font-dropdown dt, .creator6-font-dropdown ul { margin:0px; padding:0px; }
    .creator6-font-dropdown dd { position:relative; }
    .creator6-font-dropdown dt {display:block; padding-right:0;
        width:150px; background-position-y: center; height:40px; cursor:pointer; }

    .creator6-font-dropdown ul {display:none;}
    .creator6-font-dropdown.open ul {display:block;}
    .creator6-font-dropdown dt span {display:flex; align-items: center; padding:5px; margin-top:10px; }
    .creator6-font-dropdown dd ul { background:var(--creator6-controls-bg); color:var(--creator6-textcolor-primary); border:1px solid var(--creator6-color-blue); display:none;
        left:0px; padding:5px 0px; position:absolute; top:2px; width:auto; min-width:170px; list-style:none;}
    .creator6-font-dropdown span.value { display:none;}
    .creator6-font-dropdown dd ul li { padding:5px; display:block;
        cursor: pointer;}
    .creator6-font-dropdown dd ul li:hover { background-color:var(--creator6-controls-hover);}

    .creator6-font-dropdown img.creator6-font-preview { border:none; vertical-align:middle; margin-left:5px; }

    .creator6-font-style-dropdown dt {width:75px;}
    .creator6-font-style-dropdown dd ul {right:0; left:unset;}
</style>
