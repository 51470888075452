import {workspace} from '@tagading/scenery-fabricjs';
//import { c6_apiConnector, c6_engineCanvas} from './creator6-init';

import { get } from 'svelte/store';
import {
    c6WorkspaceFonts,
    c6ApiConnector,
    c6EngineCanvas,
    c6WorkspaceId,
    c6SceneryCanvas,
    c6WorkspacePageActive
} from "./stores";

// scenery helper
function getCurrentWorkspace() {
    const workspaceProvider = new workspace.WorkspaceProvider(get(c6ApiConnector).getWorkspaceService());
    return workspaceProvider.fromWorkspaceId(get(c6WorkspaceId));
}

function getWorkspaceFromId(apiConnector, workspaceId) {
    const workspaceProvider = new workspace.WorkspaceProvider(apiConnector.getWorkspaceService());
    return workspaceProvider.fromWorkspaceId(workspaceId);
}
function getPageFromWorkspaceId(apiConnector, engineCanvas, workspaceId) {
    const ws = getWorkspaceFromId(apiConnector, workspaceId);
    return workspace.PageProvider.fromWorkspace(engineCanvas, ws, get(c6WorkspacePageActive));
}

function getCurrentPage(apiConnector = get(c6ApiConnector), engineCanvas = get(c6EngineCanvas), workspaceId = get(c6WorkspaceId)) {
    return getPageFromWorkspaceId(apiConnector, engineCanvas, workspaceId);
}

function getWorkspacePage(page = get(c6WorkspacePageActive), engineCanvas, ws){
    return workspace.PageProvider.fromWorkspace(engineCanvas, ws, page);
}

// ui helper
function setWorkspaceId(id) {
    var workspaceIdInputElement = document.querySelector('#workspaceId');
    workspaceIdInputElement.value = id;
}

function getWorkspaceId() {
    var workspaceIdInputElement = document.querySelector('#workspaceId');
    return workspaceIdInputElement.value;
}

function setSelectedElement(id) {
    var workspaceSelectedElement = document.querySelector('#elementId');
    workspaceSelectedElement.value = id;
}

function getSelectedElement() {
    var workspaceSelectedElement = document.querySelector('#elementId');
    return workspaceSelectedElement.value;
}

export {getCurrentWorkspace, getWorkspaceFromId, getPageFromWorkspaceId, getCurrentPage, setWorkspaceId, getWorkspaceId, setSelectedElement, getSelectedElement, getWorkspacePage};