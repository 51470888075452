<script>
    import {PadColors} from '../../resources/js/padcolors';
    export let availablePadColors;
    export let selectedPadColor;

</script>

<div class="creator6-previewactions">
    <PadColors options={availablePadColors.split(",")} bind:valueSelected={selectedPadColor}  />
</div>

<style>

</style>