<script>
    import PadColor from './PadColor.svelte';
    import {updatePadColor} from '../../../resources/js/creator6-padcolor';
    import {c6EngineCanvas, c6SelectedPadColor, c6WorkspacePreviewPadColorClass} from "../../../resources/js/stores";

    export let options;
    export let legend = false;
    export let valueSelected = options[0];

    const uniqueID = Math.floor(Math.random() * 100)

    const slugify = (str = "") =>
        str.toLowerCase().replace(/ /g, "-").replace(/\./g, "");

    function submitPadColor(padColor){
        //console.log("new padcolor: "+padColor + " vs current "+ valueSelected);
        updatePadColor(padColor);
        c6SelectedPadColor.set(padColor);
    }

    function previewPadColor(padColor){
        console.log("preview pad color");
        c6WorkspacePreviewPadColorClass.set("creator6-canvas-preview-padcolor creator6-canvas-preview-color-"+padColor);
    }


</script>

<div class="c6-padcolors {(options.length === 1) ? 'c6-padcolor-one' : ''}"
     aria-labelledby={`label-${uniqueID}`}
     id={`group-${uniqueID}`}>
    {#if legend}
        <div class="legend" id={`label-${uniqueID}`}>{legend} </div>
    {/if}
    {#each options as value}
        <input
                class="sr-only"
                type="radio"
                id={slugify(value)}
                bind:group={valueSelected}
                value={value} /><label id="creator6SetPadColor{slugify(value)}" for={slugify(value)} on:click={(e) => submitPadColor(value)} on:mouseover={(e) => previewPadColor(value)} on:mouseout={(e) => previewPadColor('')}><PadColor bind:padcolor={value} /></label>
    {/each}
</div>

<style>

    .c6-padcolors {
        display:inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom:25px;
    }
    .c6-padcolors input[type="radio"] {
        visibility: hidden;
        width: 1px;
        height: 1px;
        display:none;
    }

    :global(.c6-padcolors input:checked + label .colop-icon-padcolor)  {
        background: transparent;
        padding: 0px;
        border: 2px solid #666;
        box-shadow: 1px 1px 1px #d5d5d5;
    }

    :global(.c6-padcolors input:hover + label .colop-icon-padcolor,
    .c6-padcolors input:focus + label .colop-icon-padcolor) {
        background: transparent;
        padding: 0px;
        border: 2px solid #666;

    }



</style>
