<script>
    import {Tabs, TabList, TabPanel, Tab} from '../../resources/js/tabs';
    import {getUiConfigs} from '../../resources/js/creator6-configuration';
    import {onMount} from 'svelte';
    import {_} from '../../services/i18n';
    import Autotext from "./Elements/Autotext.svelte";
    import Frame from "./Elements/Frame.svelte";
    import Image from "./Elements/Image.svelte";
    import PrintPreview from "../addtocart/PrintPreview.svelte";
    import ReturnForm from "../addtocart/ReturnForm.svelte";
    import {
        c6WorkspaceElements, c6ImprintImages, c6ImprintFrames, c6WorkspacePageActive, c6WorkspaceMaxLines
    } from "../../resources/js/stores";
    import Line from "./Elements/Line.svelte";

    let tabText = true;
    let tabImage = true;
    let tabFrame = true;
    let tabLine = true;
    let tabDater = false;
    let tabMaterial = false;
    let tabMask = false;
    let tabPages = false;
    let tabLayouts = false;
    let tabConfirmation = false;

    let textblocks = 0;
    let imageElementIds = [];
    let frameElementIds = [];
    let lineElementIds = [];
    let linearAutotextElementIds = [];
    let roundAutotextElementIds = [];
    let splineTextElementIds = [];
    let linearTextElementIds = [];
    let roundTextElementIds = [];
    let showTabs = 0;
    let elementsloaded = false;

    let textBlocks = 0;

    async function prepareTabs(){
        tabText = await getUiConfigs('workspace.visibility.text');
        tabImage = await getUiConfigs('workspace.visibility.images');
        tabFrame = await getUiConfigs('workspace.visibility.borders');
        tabLine = await getUiConfigs('workspace.visibility.lines');
        tabDater = await getUiConfigs('workspace.visibility.dater');
        tabMaterial = await getUiConfigs('workspace.visibility.material');
        tabMask = await getUiConfigs('workspace.visibility.mask');
        tabPages = await getUiConfigs('workspace.visibility.pages');
        tabLayouts = await getUiConfigs('workspace.visibility.layouts');
        tabConfirmation = await getUiConfigs('workspace.visibility.confirmation');


        if(tabText) showTabs ++;
        if(tabImage) showTabs ++;
        if(tabFrame) showTabs ++;
        if(tabLine) showTabs ++;
        if(tabDater) showTabs ++;
        if(tabMaterial) showTabs ++;
        if(tabMask) showTabs ++;
        if(tabPages) showTabs ++;
        if(tabLayouts) showTabs ++;
        if(tabConfirmation) showTabs ++;

        return true;
    }

    async function getElementIds(){
        return new Promise((resolve, reject) => {
            $c6WorkspaceElements[$c6WorkspacePageActive].forEach(function(element){
                let elementId = element.sceneElement.id;

                console.log(element);
                if(element.sceneElement.elementType === "Text"){
                    let textType = element.sceneElement.model.textType;
                    let autoLayout = element.sceneElement.controls.locked;

                    if(textType === "Linear"){
                        textBlocks++;
                        if(autoLayout){
                            linearAutotextElementIds.push(elementId);
                        }
                        else {
                            linearTextElementIds.push(elementId);
                        }
                    }
                    else if(textType === "Ellipse"){
                        textBlocks++;
                        if(autoLayout){
                            roundAutotextElementIds.push(elementId);
                        }
                        else {
                            roundTextElementIds.push(elementId);
                        }
                    }
                    else if(textType === "Spline"){
                        textBlocks++;
                        splineTextElementIds.push(elementId);
                    }
                    else if(textType === "Dater"){

                    }
                }
                else if(element.sceneElement.elementType === "Image"){
                    //console.log("image with id " + elementId + " found");
                    imageElementIds.push(elementId);
                }
                else if(element.sceneElement.elementType === "Frame"){
                    frameElementIds.push(elementId);
                }
                else if(element.sceneElement.elementType === "Line"){
                    lineElementIds.push(elementId);
                }
            });

            c6ImprintImages.set(imageElementIds.length);
            c6ImprintFrames.set(frameElementIds.length);

            c6WorkspaceMaxLines.set($c6WorkspaceMaxLines/textBlocks);

            if(imageElementIds.length === 0){
                imageElementIds.push("empty");
            }
            resolve(true);
        });
    }

    onMount(async () => {


        //console.log("mount tabs get elements:");
        //elementsloaded = await getElementIds($c6ApiBaseUrl, $c6PreviewBaseUrl, $c6Repo, $c6AccessToken, canvasId);

    });

    //let imageElementId = '925a38f1-8a76-4460-87e6-10be2d2571c1';
    //let frameElementId = '5b6766bb-d4d5-4e20-bc58-c179125969a2';
    //let autolayoutTextId = '0c24b444-3814-4762-81bd-1067e1c8dbd3';

</script>

<Tabs>
    {#await prepareTabs()}
        LOADING
    {:then tabsloaded}
        <TabList>
            {#if showTabs > 1}
                {#if tabText}<Tab icon="colop-icon-font" text={$_('tabs.text')} dataid="tabText"></Tab>{/if}{#if tabImage}<Tab icon="colop-icon-tab_img" text={$_('tabs.image')} dataid="tabImage"></Tab>{/if}{#if tabFrame}<Tab icon="colop-icon-tab_form" text={$_('tabs.frame')} dataid="tabFrame"></Tab>{/if}{#if tabLine}<Tab icon="colop-icon-tab_form" text={$_('tabs.line')} dataid="tabLine"></Tab>{/if}{#if tabDater}<Tab>Dater</Tab>{/if}{#if tabMaterial}<Tab>Material</Tab>{/if}{#if tabMask}<Tab>Mask</Tab>{/if}{#if tabPages}<Tab>Pages</Tab>{/if}{#if tabLayouts}<Tab><span class="colop-icon-settings"></span>Layouts</Tab>{/if}
                {#if tabConfirmation}<Tab specialclass="creator6-right"><span class="colop-icon-cart"></span>Continue</Tab>{/if}
            {/if}
        </TabList>
        <div class="creator6-tabs-body">
            {#await getElementIds()}
                LOADING
            {:then elementsloaded}
                {#if tabText}
                    <TabPanel>
                        {#each roundAutotextElementIds as elementId, i}
                            <Autotext elementId="{elementId}" textModel="round" autoText="true" textBlockId="{i}"/>
                        {/each}
                        {#each roundTextElementIds as elementId, i}
                            <Autotext elementId="{elementId}" textModel="round"  textBlockId="{roundAutotextElementIds.length + i}"/>
                        {/each}
                        {#each splineTextElementIds as elementId, i}
                            <Autotext elementId="{elementId}" textModel="spline" autoText="true" textBlockId="{roundAutotextElementIds.length + roundTextElementIds.length + i}"/>
                        {/each}
                        {#each linearAutotextElementIds as elementId, i}
                            <Autotext elementId="{elementId}" textModel="linear" autoText="true" textBlockId="{roundAutotextElementIds.length + roundTextElementIds.length + splineTextElementIds.length + i}"/>
                        {/each}
                        {#each linearTextElementIds as elementId, i}
                            <Autotext elementId="{elementId}" textModel="linear" textBlockId="{roundAutotextElementIds.length + roundTextElementIds.length + splineTextElementIds.length + linearAutotextElementIds.length + i}"/>
                        {/each}
                    </TabPanel>
                {/if}
                {#if tabImage}
                    <TabPanel>
                        {#each imageElementIds as elementId}
                            <Image bind:imageId={elementId} bind:elementsTotal={imageElementIds.length} />
                        {/each}
                    </TabPanel>
                {/if}
                {#if tabFrame}
                    <TabPanel>
                        {#each frameElementIds as elementId}
                            <Frame frameId={elementId} bind:elementsTotal={frameElementIds.length}/>
                        {/each}
                    </TabPanel>
                {/if}
                {#if tabLine}
                    <TabPanel>
                        {#each lineElementIds as elementId}
                            <Line frameId={elementId} bind:elementsTotal={lineElementIds.length}/>
                        {/each}
                    </TabPanel>
                {/if}
                {#if tabDater}
                    <TabPanel>
                        <h2>Dater</h2>
                    </TabPanel>
                {/if}
                {#if tabMaterial}
                    <TabPanel>
                        <h2>Material</h2>
                    </TabPanel>
                {/if}
                {#if tabMask}
                    <TabPanel>
                        <h2>Mask</h2>
                    </TabPanel>
                {/if}
                {#if tabPages}
                    <TabPanel>
                        <h2>Pages</h2>
                    </TabPanel>
                {/if}
                {#if tabLayouts}
                    <TabPanel>
                        <h2>Layouts</h2>
                    </TabPanel>
                {/if}
                {#if tabConfirmation}
                    <TabPanel>
                        <h2>Confirmation</h2>
                    </TabPanel>
                {/if}
            {/await}
        </div>
    {/await}
</Tabs>
<style>
    .creator6-tabs-body {
        margin: 0 10px;
        border:1px solid var(--creator6-color-blue);
        border-top:none;
        box-shadow: 0 0 10px var(--creator6-controls-boxshadow);
        padding: 0 var(--creator6-padding-standard) var(--creator6-padding-standard) var(--creator6-padding-standard);
    }
</style>
