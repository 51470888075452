<script>
  import { getContext } from 'svelte';
  import {_} from '../services/i18n';

  export let message;
	export let hasForm = false;
	export let onCancel = () => {};
	export let onOkay = () => {};

  const { close } = getContext('simple-modal');
	
	let value;
	let onChange = () => {};
	
	function _onCancel() {
		onCancel();
		close();
	}
	
	function _onOkay() {
		onOkay(value);
		close();
	}
	
	$: onChange(value)
</script>

<style>
  h2 {
		font-size: var(--creator6-fontsize-big);
		text-align: center;
	}
	
	input {
		width: 100%;
		border-radius: var(--creator6-border-radius);
		background: var(--creator6-controls-bg);
		border: 1px solid var(--creator6-color-blue);
		padding: 10px;
	}
	
	.buttons {
		display: flex;
		justify-content: space-between;
	}

  button.creator6-dialog-cancel {
	  background: transparent;
	  border: 1px solid #666;
	  border-radius: 10px;
	  padding: 0.4em;
	  color: #666;
	  font-weight: bold;
	  width: 90%;
	  margin: 1em;
	  display: inline-block;
	  cursor: pointer;
  }

  button.creator6-dialog-ok {
	  background: var(--creator6-color-blue);
	  border: 1px solid var(--creator6-color-blue);
	  border-radius: 10px;
	  padding: 0.4em;
	  color: white;
	  font-weight: bold;
	  width: 90%;
	  margin: 1em;
	  display: inline-block;
	  cursor: pointer;
  }

</style>

<h2>{message}</h2>

{#if hasForm}
	<input
    type="text"
	  bind:value
	  on:keydown={e => e.which === 13 && _onOkay()} />
{/if}

<div class="buttons">
	<button class="creator6-dialog-cancel" on:click={_onCancel}>
		{$_('eosoutput.savetoarchive.popupcancel')}
	</button>
	<button class="creator6-dialog-ok" on:click={_onOkay}>
		{$_('eosoutput.savetoarchive.popupcontinue')}
	</button>
</div>