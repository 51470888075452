import {Unexpected, workspace, rxjsOperators, engine} from '@tagading/scenery-fabricjs';
import {
    getCurrentWorkspace,
    getCurrentPage,
    setWorkspaceId,
    setSelectedElement,
    getSelectedElement,
    getWorkspacePage,
    getWorkspaceFromId
} from './creator6-helper';
import { setZoom } from './creator6-zoom';
import { get } from 'svelte/store';
import {
    c6ApiConnector,
    c6EngineCanvas,
    c6WorkspaceId,
    c6SceneryCanvas,
    c6Repo,
    c6WorkspaceElements,
    c6WorkspaceElementSelected,
    c6CanvasId,
    c6WorkspaceValidation, c6SolutionType,
    c6WorkspaceLoaded,
    c6WorkspaceConfirmed, c6ImageProxyEndpoint, c6WorkspacePageActive, c6WorkspaceReady2Leave
} from "./stores";

async function resetCreatorData(){
    c6SceneryCanvas.set(null);
    c6WorkspaceId.set(null);
    c6EngineCanvas.set(null);
    c6WorkspaceElements.set(null);
    c6WorkspaceElementSelected.set(null);
    c6WorkspaceLoaded.set(null);
    c6WorkspaceConfirmed.set(false);
}

async function prepareWorkspaceAsync(apiConnector, userId, repo, engineCanvas, templateId, afterRendering = undefined, workspaceId = undefined, docId = undefined) {
    return new Promise((resolve, reject) => {
        var workspaceProvider = new workspace.WorkspaceProvider(apiConnector.getWorkspaceService());
        //var canvasData = false;
        var ws;
        if ((typeof workspaceId !== 'undefined')) {
            ws = workspaceProvider.fromWorkspaceId(workspaceId, userId, repo);
            if (ws instanceof workspace.Workspace) {
                resolve(ws);
            } else {
                reject("Could not create Workspace");
            }
        } else if (docId) {
            workspaceProvider.createWorkspaceFromDocument(docId, userId, repo).pipe(rxjsOperators.first()).subscribe({
                next: function (workSpace) {
                    if (workSpace instanceof workspace.Workspace) {
                        resolve(workSpace);
                    } else {
                        reject("Could not create Workspace");
                    }
                }
            });
        } else {
            workspaceProvider.createWorkspaceFromTemplate(templateId, userId, repo).pipe(rxjsOperators.first()).subscribe({
                next: function (workSpace) {
                    if (workSpace instanceof workspace.Workspace) {
                        resolve(workSpace);
                    } else {
                        reject("Could not create Workspace");
                    }
                }
            });
        }
    });
}

async function getWorkspaceMedium(apiConnector=get(c6ApiConnector), workspaceId = get(c6WorkspaceId)){
    return new Promise((resolve, reject) => {
        apiConnector.getGateway().getFrontendApi().getWorkspaceMedium(workspaceId).subscribe(function(workspaceInfo){
            if (workspaceInfo instanceof Unexpected) {
                console.error('Could not get Workspace Medium');
                reject(false);
            }
            else {
                resolve(workspaceInfo.response);
            }
        });
    });
}


async function updateWorkspaceMedium(medium, apiConnector=get(c6ApiConnector), workspaceId = get(c6WorkspaceId)){
    return new Promise((resolve, reject) => {

        apiConnector.getGateway().getFrontendApi().updateWorkspaceMedium(workspaceId, medium).subscribe(function(workspaceInfo){
            if (workspaceInfo instanceof Unexpected) {
                console.error('Could not set Workspace Medium');
                reject(false);
            }
            else {
                resolve(workspaceInfo.response);
            }
        });
    });
}

async function reloadWorkspace(apiConnector=get(c6ApiConnector), workspaceId = get(c6WorkspaceId)){
   // await resetCreatorData();
    let engineCanvas = await new engine.FabricEngineCanvas(get(c6CanvasId), undefined, get(c6ImageProxyEndpoint));
    c6EngineCanvas.set(engineCanvas);
    await prepareWorkspaceAsync(apiConnector, undefined, get(c6Repo), engineCanvas, "no", undefined, workspaceId);
    await renderPage();
    return true;
}

async function renderPageInitialAsync(ws, engineCanvas = get(c6EngineCanvas)) {
    return new Promise((resolve, reject) => {
        ws.renderPage(engineCanvas)(get(c6WorkspacePageActive)).pipe(rxjsOperators.first()).subscribe(function (sceneryCanvas) {
            registerSelection(ws, engineCanvas, sceneryCanvas);
            if (sceneryCanvas.scene.sceneTree) {
                c6SceneryCanvas.set(sceneryCanvas);
                resolve(sceneryCanvas);
            } else {
                reject("sceneryCanvas not loaded");
            }
        });
    });
}

function registerSelection(ws, engineCanvas, sceneryCanvas) {
    subscribeSelection(sceneryCanvas);
    if(get(c6SolutionType) !== "canvas-only") {
        getCurrentPage().getPageOperationsState().subscribe(state => {
            var undoButton = document.querySelector('#undo');
            var redoButton = document.querySelector('#redo');
            if (undoButton && redoButton) {
                undoButton.disabled = !state.canUndo;
                redoButton.disabled = !state.canRedo;
            }
        });
    }
}

function subscribeSelection(sceneryCanvas) {
    sceneryCanvas.selection.selections.subscribe(function (sceneElement) {
        //console.warn("selection", sceneElement.element.elementType);
        var selectedElementId = get(c6WorkspaceElementSelected); //getSelectedElement();
        var tabs = document.getElementsByClassName("tabdetails");
        for (var i = 0; i < tabs.length; i++) {
            //tabs[i].style.display = "none";
        }
        var lastSceneElementId = sceneryCanvas.getSelectedSceneElementId();

        if (sceneElement.selected || lastSceneElementId == sceneElement.element.id) {
            c6WorkspaceElementSelected.set(sceneElement.element.id);
        }
        else {
            c6WorkspaceElementSelected.set(null);
        }
    });
}

async function loadElements(sceneryCanvas){
    return new Promise((resolve, reject) => {
        resolve(sceneryCanvas.scene.sceneTree.rootNode.subNodes[0].elements);
    });
}

async function getPageProperties(sceneryCanvas) {
    return new Promise((resolve, reject) => {
        resolve(sceneryCanvas.scene.sceneTree);
    });
}

async function renderPage(partialrendering = false, ws = getCurrentWorkspace(), engineCanvas = get(c6EngineCanvas), afterRendering = registerSelection) {
    return new Promise((resolve, reject) => {
        ws.renderPage(engineCanvas)(get(c6WorkspacePageActive), partialrendering).pipe(rxjsOperators.first()).subscribe(function (sceneryCanvas) {
            if(sceneryCanvas){
                //registerSelection(ws, engineCanvas, sceneryCanvas);
                afterRendering(ws, engineCanvas, sceneryCanvas);
                c6SceneryCanvas.set(sceneryCanvas);
                c6WorkspaceConfirmed.set(false);
                c6WorkspaceReady2Leave.set(false);
                setZoom();
                validateImprint(sceneryCanvas);
                resolve(sceneryCanvas);
            }
            else {
                reject("could not render page");
            }
        });
    });
}

async function validateImprint(sceneryCanvas){

    return new Promise((resolve, reject) => {
        let validation = sceneryCanvas.scene.sceneTree.validationResults;
        validation['warnings']=0;
        validation['errors']=0;
        let validationElements = validation.elements;
        var nodes = sceneryCanvas.scene.sceneTree.rootNode.subNodes[0].elements;
        nodes.forEach(function(element){
            let elementId = element.sceneElement.id;
            if(elementId in validationElements) {
                if(element.sceneElement.elementType === "Text"){
                    //console.log("text with id " + elementId + " has errors");
                    validationElements[elementId]["type"]="Text";
                    if(Object.keys(validationElements).length){
                        for (var [key, validationElement] of Object.entries(validationElements)) {
                            if('overlappingWidth' in validationElement && validationElement.overlappingWidth.length) {
                                validation['warnings']++;
                            }
                            if('fontSizeTooSmall' in validationElement && validationElement.fontSizeTooSmall) {
                                validation['errors']++;
                            }
                            if('linesTooLong' in validationElement && validationElement.linesTooLong.length) {
                                validation['errors']++;
                            }
                            if('outside' in validationElement && validationElement.outside) {
                                validation['errors']++;
                            }
                        }
                    }
                }
                if(element.sceneElement.elementType === "Image"){
                    //console.log("image with id " + elementId + " has errors");
                    validationElements[elementId]["type"]="Image";
                }
                if(element.sceneElement.elementType === "Frame"){
                    //console.log("frame with id " + elementId + " has errors");
                    validationElements[elementId]["type"]="Frame";
                }
            }
        });

        if (validation) {
            c6WorkspaceValidation.set(validation);
            resolve(validation);
        } else {
            reject("sceneryCanvas not loaded");
        }
    });
}

function updateElement(workspaceId, activePage, selectedElementId, newElementData){
    get(c6ApiConnector).getGateway().getFrontendApi().getElement(workspaceId, activePage, selectedElementId).subscribe(function(result){
        if(result.response){
            var elementModel = result.response;
            elementModel.selectable  = (newElementData.sceneElement.controls.selectable !== undefined) ? newElementData.sceneElement.controls.selectable : elementModel.selectable;
            elementModel.visible  = (newElementData.sceneElement.visibility.visible !== undefined) ? newElementData.sceneElement.visibility.visible : elementModel.visible;
            elementModel.showScaleHandles  = (newElementData.sceneElement.controls.showScaleHandles !== undefined) ? newElementData.sceneElement.controls.showScaleHandles : elementModel.showScaleHandles;
            elementModel.showRotationHandles  = (newElementData.sceneElement.controls.showRotationHandles !== undefined) ? newElementData.sceneElement.controls.showRotationHandles : elementModel.showRotationHandles;
            elementModel.showMoveHandles  = (newElementData.sceneElement.controls.showMoveHandles !== undefined) ? newElementData.sceneElement.controls.showMoveHandles : elementModel.showMoveHandles;
            elementModel.hideControls  = (newElementData.sceneElement.controls.showControls !== undefined) ? !newElementData.sceneElement.controls.showControls : elementModel.hideControls;
            elementModel.locked  = (newElementData.sceneElement.controls.locked !== undefined) ? newElementData.sceneElement.controls.locked : elementModel.locked;

            get(c6ApiConnector).getGateway().getFrontendApi().patchElement(workspaceId, activePage, selectedElementId, JSON.stringify(elementModel)).subscribe(function (result) {
                if (result.response.success) {
                    renderPage();
                }
                else {
                    console.error("Could not update Element with ID " + selectedElementId);
                }
            });
        }
    });
}

async function moveUpElement(workspaceId, activePage, selectedElementId){
    return new Promise((resolve, reject) => {
        get(c6ApiConnector).getGateway().getFrontendApi().moveElementUp(workspaceId, activePage, selectedElementId).subscribe(function (result) {
            if (result.response.success) {
                resolve(true);
            }
            else {
                let errorText = "Could not move UP Element with ID " + selectedElementId;
                console.error(errorText);
                reject(errorText)
            }
        });
    });
}

async function moveDownElement(workspaceId, activePage, selectedElementId){
    return new Promise((resolve, reject) => {
        get(c6ApiConnector).getGateway().getFrontendApi().moveElementDown(workspaceId, activePage, selectedElementId).subscribe(function (result) {
            if (result.response.success) {
                resolve(true);
            }
            else {
                let errorText = "Could not move DOWN Element with ID " + selectedElementId;
                console.error(errorText);
                reject(errorText)
            }
        });
    });
}

async function deleteThisElement(workspaceId, activePage, selectedElementId){
    return new Promise((resolve, reject) => {
        get(c6ApiConnector).getGateway().getFrontendApi().deleteElement(workspaceId, activePage, selectedElementId).subscribe(function (result) {
            if (result.response.success) {
                resolve(true);
            }
            else {
                let errorText = "Could not delete Element with ID " + selectedElementId;
                console.error(errorText);
                reject(errorText)
            }
        });
    });
}

function copyThisElement(workspaceId, activePage, selectedElementId){
    get(c6ApiConnector).getGateway().getFrontendApi().copyElement(workspaceId, activePage, selectedElementId).subscribe(function (result) {
        if (result.response.success) {
            //renderPage();
        }
        else {
            console.error("Could not copy Element with ID " + selectedElementId);
        }
    });
}

function pasteCopiedElement(workspaceId, activePage){
    get(c6ApiConnector).getGateway().getFrontendApi().pasteElement(workspaceId, activePage).subscribe(function (result) {
        if (result.response.success) {
           // renderPage();
        }
        else {
            console.error("Could not update Element with ID " + selectedElementId);
        }
    });
}

async function overwriteDocument(workspaceId){
    return new Promise((resolve, reject) => {
        get(c6ApiConnector).getGateway().getFrontendApi().saveWorkspace(workspaceId).subscribe(function (result) {
            if (result.response.success) {
                resolve(result.response);
            }
        });
    });
}

async function getWorkspaceConfiguration(workspaceId ){
    return new Promise((resolve, reject) => {
        get(c6ApiConnector).getGateway().getFrontendApi().getWorkspaceConfiguration(workspaceId).subscribe(function (result) {
            if (result.response) {
                resolve(result.response);
            }
        });
    });
}

async function saveAsDocument(newCdocName = 'A next new document'){
    return new Promise((resolve, reject) => {
        //let workspaceIdInputElement = document.querySelector('#workspaceId');
        //let workspaceId = workspaceIdInputElement.value;
        const documentService = get(c6ApiConnector).getDocumentService();
        const save = documentService.saveWorkspaceAsDocument({
            workspaceId:  get(c6WorkspaceId),
            repository: get(c6Repo),
            name: newCdocName
        });
        save.subscribe({
            next: result => {
                //console.log(result);
                resolve(result.response);
            }
        });
    });
}


async function saveAsNewTemplate(newCdocName = 'A next new document'){
    return new Promise((resolve, reject) => {
        //let workspaceIdInputElement = document.querySelector('#workspaceId');
        //let workspaceId = workspaceIdInputElement.value;
        const templateService = get(c6ApiConnector).getTemplateService();
        const save = templateService.saveWorkspaceAsTemplate({
            workspaceId:  get(c6WorkspaceId),
            repository: get(c6Repo),
            name: newCdocName
        });
        save.subscribe({
            next: result => {
                //console.log(result);
                resolve(result.response);
            }
        });
    });
}

async function deleteCurrentWorkspace() {
    return new Promise((resolve, reject) => {
        get(c6ApiConnector).getGateway().getFrontendApi().deleteWorkspace(get(c6WorkspaceId)).subscribe(function (result) {
            if (result.response.success) {
                resolve(result);
            }
        });
    });
}

export {moveUpElement, moveDownElement, copyThisElement, pasteCopiedElement, deleteThisElement, updateElement, getWorkspaceConfiguration, reloadWorkspace, updateWorkspaceMedium, saveAsNewTemplate, getPageProperties, deleteCurrentWorkspace, resetCreatorData, getWorkspaceMedium, prepareWorkspaceAsync, setWorkspaceId, renderPageInitialAsync, renderPage, registerSelection, validateImprint, saveAsDocument, overwriteDocument, loadElements};