<script>
    import {Jumper} from 'svelte-loading-spinners'
    import {c6LoaderColor} from "../resources/js/stores";
    export let opacity = false;
    export let text = null;
    export let fullscreen = false;
    export let size = 60;
    export let color = $c6LoaderColor;
    export let transparent = false;


</script>
<div class="creator6-loader {(opacity) ? 'creator6-loader-opacity' : '' } {(fullscreen) ? 'creator6-loader-fullscreen' : '' } {(transparent) ? 'creator6-loader-transparent' : '' }">
    <div>
        <div class="creator6-loader-icon">
            <Jumper size="{size}" color="{color}" unit="px" duration="1s"></Jumper>
        </div>
        {#if text}
            <div>{text}</div>
        {/if}
    </div>
</div>

<style>
    .creator6-loader {
        width: 100%;
        background: white;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .creator6-loader-fullscreen {
        position: absolute;
        width: 100%;
        height: 100%;
        min-height:200px;
        background: white;
        top: 0;
        text-align: center;
        display: flex;
        align-items: center;
    }
    .creator6-loader-opacity {
        opacity: 0.9;
    }
    .creator6-loader-transparent {
        background:transparent;
    }

    .creator6-loader-icon {width:max-content; margin:0 auto;}
    .creator6-loader > div {margin:0 auto; width:auto; text-align:center;}
</style>