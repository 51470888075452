import App from './App.svelte';
import CanvasOnly from './CanvasOnly.svelte';
import camelcase from "camelcase";

/**
 * Converts all attributes to an object. The keys of the object will be the attribute names, converted
 * from e.g. 'data-this-param' to 'dataThisParam'. If a prefix is supplied (default is 'data-') it will be cut off from
 * the attribute name. 'data-this-param' with the prefix paramater value 'data-' will be converted to 'thisParam'. The
 * attribute value will be set as the object property value.
 *
 * @param {Element} target An HTML tag with attributes.
 * @param {string} prefix If given, only attributes with the prefix will be accounted, whereat this prefix will
 * be cut off during conversion.
 * @return {Object} An object with the converted attributes. Can be empty if no attribute was found in the passed
 * target.
 */
const convertAttributes = (target, prefix = 'data-') => {
    prefix = prefix || '';
    return [...target.attributes].reduce((accumulator, attribute) => {
        return attribute.name.startsWith(prefix)
            ? {...accumulator, [camelcase(attribute.name.substring(prefix.length))]: attribute.value}
            : accumulator;
    }, {});
};

/**
 * Creates an application object and initialize it with data attributes the target tag has. The returned object gets
 * a function unloadApp with which the unmount process can be started (unmount the root component and all its
 * subcomponents).
 *
 * @param {null|Element} target
 * @return {null|Object} An application object or null if target is null.
 */
const createApp = target => {
    if (target) {
        const props = convertAttributes(target);
        const appParams = {target, props};

        let app;

        if (props.ct === 'canvas-only') {
            app = new CanvasOnly(appParams);
        } else {
            app = new App(appParams);
        }

        app.unloadApp = app.$destroy;

        return app;
    }
    return null;
}

/**
 * Create application objects for each tag with the passed id found within the DOM.
 *
 * @param {...string} ids Tag ids. Works with and without '#' as prefix.
 * @return {(Object|null)|(Object|null)[]} An application object if a single id was passed. Or an array of application
 * objects when multiple ids was passed. An array entry will be null if no tag
 * with the requested id was found in the DOM or an entry in the passed parameter ids was null.
 */
export const loadById = (...ids) => {
    let apps = ids.map(id => {
        if (id) {
            const target = document.querySelector('#' + id.trim().replace('#', ''));
            return createApp(target);
        }
        return null;
    });
    if(ids.length === 1) {
        return apps[0];
    }
    return apps;
};

/**
 * Create application objects for each tag with the passed class name found within the DOM.
 *
 * @param {string} className A class name.  Works with and without '.' as prefix.
 * @return {(Object)[]} An application object array.
 */
export const loadByClass = (className) => {
    const targets = [...document.querySelectorAll('.' + className.trim().replace('.', ''))];
    return targets.map(target => createApp(target));
};

/**
 * An application object if a tag with the class 'c6ui' can be found in the DOM. Only the first tag will be considered
 * then. Otherwise, null.
 *
 * @type {null|Object}
 */
export const app = createApp(document.querySelector('.c6ui'));