<script>
    import {onMount} from "svelte";

    export let reverse = false;
    export let checked = false;
    export let color = "#2196F3";
    export let valueClicked;

    function switchClick() {
        //console.log("switch was clicked");
        valueClicked = true;
    }

    onMount(async () => {
        //console.log("reverse: " + reverse + "checked before: " + checked);
        checked = (reverse) ? !checked : checked;
        //console.log("reverse: " + reverse + "checked after: " + checked);

    });

</script>

<style>
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--creator6-controls-bg);
        border: 2px solid white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: var(--creator6-border-radius);
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    input:checked + .slider {
        background-color: var(--creator6-color-blue);
    }

    input:checked + .slider {
        box-shadow: 0 0 1px var(--creator6-color-blue);
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
</style>

<label class="switch">
    <input type="checkbox" bind:checked on:click={switchClick} />
    <span class="slider" />
</label>