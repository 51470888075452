<script>
    export let padcolor;
</script>

<span class="colop-icon-padcolor colop-icon-padcolor_{padcolor.toLowerCase()}">{#if padcolor.toLowerCase() === "bluereddater" || padcolor.toLowerCase() === "blackreddater" }<span class="path1"></span><span class="path2"></span>{/if}</span>
<style>
    .colop-icon-padcolor {
        border: 2px solid transparent;
        border-radius: 100%;
        cursor:pointer;
        font-size:var(--creator6-fontsize-padcolor);
    }
</style>