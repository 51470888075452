<script>
    //import Slider from '@bulatdashiev/svelte-slider';
    import RangeSlider from "svelte-range-slider-pips";
    //import { getContext } from "svelte";
    import {
        uploadImage,
        updateImageGeometry,
        deleteImageMediaPoolId,
        uploadPhoto,
        submitImageFilter,
        getSharedAssets,
        uploadClipart
    } from '../../../resources/js/creator6-image';
    import {getFabricElementById, getFabricValue} from '../../../resources/js/fabricjshelper';
    import {getFrameElementData} from '../../../resources/js/creator6-frame';

    import {_} from '../../../services/i18n';
    import {validateImprint, renderPage} from '../../../resources/js/creator6-core';
    import {
        c6ApiConnector,
        c6EngineCanvas,
        c6ImageProxyEndpoint,
        c6SceneryCanvas,
        c6WorkspaceId,
        c6WorkspaceMedium,
        c6WorkspaceContainerIds,
        c6WorkspaceConfiguration,
        c6WorkspaceValidation,
        c6DebugIt, c6WorkspaceCliparts, c6SolutionType
    } from '../../../resources/js/stores';
    import {getImageElementData} from "../../../resources/js/creator6-image";
    import {onMount} from "svelte";
    import Loader from "../../Loader.svelte";
    import Togglebuttons from "./Togglebuttons.svelte";
    import {getUiConfigs} from "../../../resources/js/creator6-configuration";
    import Cliparts from "./Cliparts.svelte";

    export let imageId;

    let canvasWidth;
    let canvasHeight;
    let imageHeight = [0];
    let imageWidth = [0];
    let imageRotation = [0];
    let imageScale = [0];
    let imageX = [0];
    let imageY = [0];
    let mediaPoolId;
    let maxScale;
    let imageMaxHeight;
    let minX;
    let maxX;
    let minY;
    let maxY;

    let useInch = false;
    let unit = "mm";

    let allowedFileExtensions = ".jpg, .jpeg, .png, .svg";

    let creator6image, creator6upload, fileinput, clipartinput;
    let validationElements = false;
    let imageUploadInfoShow = ($_('image.info.bottomlabel') !== "" || $_('image.info.bottomdetails') !== "");

    let imageFilter;
    let updateImageFilter = false;
    $:imageFilter, submitImageFilter(imageId, imageFilter, updateImageFilter);
    let imageFilterOptions = [];

    let clipartsAll = [];
    let hasCliparts = false;
    let showCliparts = false;
    let clipartCategories =  [];
    let clipartSrc = false;

    async function loadElementData(imageId) {
        if (imageId === "empty") {
            return true;
        }
        if (imageId) {
            let canvasFabric = $c6EngineCanvas.fabricCanvas;
            let object = await getFabricElementById(canvasFabric, imageId);
            if (object && 'sceneElement' in object) {
                creator6image = object.sceneElement.model.dataUrl;
                creator6image = creator6image.replace('image-proxy:/', $c6ImageProxyEndpoint);
            }
        }

        let currentElementData = await getImageElementData($c6ApiConnector, $c6EngineCanvas, $c6WorkspaceId, imageId);
        if(currentElementData){
            imageHeight = [Math.round(currentElementData.geometry.height * 10) / 10];
            imageWidth = [Math.round(currentElementData.geometry.width * 10) / 10];
            imageScale = [Math.round(currentElementData.geometry.width * 10) / 10];
            imageRotation = [currentElementData.geometry.rotation];
            imageX = [Math.round(currentElementData.geometry.x * 10) / 10];
            imageY = [Math.round(currentElementData.geometry.y * 10) / 10];
            mediaPoolId = currentElementData.mediaPoolId;
            canvasHeight = $c6WorkspaceMedium.height;
            canvasWidth = $c6WorkspaceMedium.width;
            maxScale = canvasWidth * 2;
            minX = 0 - imageWidth + 1;
            maxX = canvasWidth - 1;
            minY = 0 - imageHeight + 1;
            maxY = canvasHeight - 1;
            imageMaxHeight = imageHeight;
            imageFilter = (currentElementData.imageFilters) ? currentElementData.imageFilters[0] : "None";
        }

        if ($c6WorkspaceContainerIds.length) {
            let containerFrame = await getFrameElementData(Object.keys($c6WorkspaceContainerIds)[0]);
            let frameStrokeWidth = containerFrame.stroke.strokeWidth;
            let frameX = containerFrame.geometry.x;
            let frameY = containerFrame.geometry.y;
            let frameHeight = containerFrame.geometry.height;
            let frameWidth = containerFrame.geometry.width;
            let framePadding = $c6WorkspaceConfiguration.get('scene.elements.frame.defaultPadding').values[0];
            imageMaxHeight = canvasHeight - (frameY * 2) - (framePadding * 2) - (frameStrokeWidth * 2);
        }
        return currentElementData;
    }

    const onFileSelected = async (e) => {
        let image = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => {
            creator6upload = e.target.result
        };
        let newImageId = await uploadImage(image, imageId);
        let sceneryCanvas = await renderPage();

        if (imageId === "empty") {
            imageId = newImageId;
        }
    }

    const onClipartSelected = async (e) => {
        let image = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => {
            creator6upload = e.target.result
        };
        let newImageId = await uploadClipart(image);

        if (imageId === "empty") {
            imageId = newImageId;
        }

    }

    const getCliparts = async (e) => {
        clipartsAll = $c6WorkspaceCliparts;
    }

    const deleteCurrentImage = async (e) => {
        await deleteImageMediaPoolId(imageId);
        mediaPoolId = null;
        clipartSrc = false;
        creator6image = "";
        showCliparts = false;
        creator6upload = null;
        imageId = "empty";
    }

    document.onkeydown = checkKey;

    function checkKey(e) {

        e = e || window.event;

        if (e.keyCode === '38') {
            // up arrow
            imageY[0]--;
            previewImageGeometry(e, "y", imageY[0]);
        } else if (e.keyCode === '40') {
            // down arrow
            imageY[0]++;
            previewImageGeometry(e, "y", imageY[0]);

        } else if (e.keyCode === '37') {
            // left arrow
            imageX[0]--;
            previewImageGeometry(e, "x", imageX[0]);
        } else if (e.keyCode === '39') {
            // right arrow
            imageX[0]++;
            previewImageGeometry(e, "x", imageX[0]);
        }
    }

    const previewImageGeometry = async (event, key, value) => {
        let newValue = getFabricValue(value);
        let canvasFabric = $c6EngineCanvas.fabricCanvas;
        let object = getFabricElementById(canvasFabric, imageId);

        var currentImageHeight = imageHeight;
        imageHeight = [(object.height / object.width * imageScale)];
        if(currentImageHeight*1 !== imageHeight*1){
            /*let imageHeightDifference = (currentImageHeight - imageHeight)/2;
            var newY = imageY * 1 +  imageHeightDifference;
            imageY = [newY];
            object.top = getFabricValue(newY) + (object.height / 2) * object.scaleY;
            object.setCoords();*/
        }

        if (key === "rotation") {
            object.angle = value;
            object.setCoords();
            imageRotation = [value];
        }

        if (key === "x") {
            object.left = newValue + (object.width / 2) * object.scaleX;
            object.setCoords();
            imageX = [value];
        }
        if (key === "y") {
            object.top = newValue + (object.height / 2) * object.scaleY;
            object.setCoords();
            imageY = [value];
        }
        if (key === "scale") {
            newValue = newValue / object.width;
            object.scale(newValue).setCoords();
            object.left = getFabricValue(imageX) + (object.width / 2) * object.scaleX;
            object.top = getFabricValue(imageY) + (object.height / 2) * object.scaleY;
            imageWidth = [value];
            object.setCoords();
            imageScale = [value];

        }
        canvasFabric.requestRenderAll();
        return true;
    }
    const submitImageCenterX = async (event) => {
        let value = (canvasWidth - imageWidth) / 2;
        imageX = [value];
        let dataSet = await previewImageGeometry(event, "x", value);
        let data = {width: imageWidth*1, height: imageHeight*1, x: imageX*1, y: imageY*1, rotation: imageRotation*1};
        updateImageGeometry(imageId, data);

    }
    const submitImageCenterY = (event) => {
        let value = (canvasHeight - imageHeight) / 2;
        imageY = [value];
        let data = {width: imageWidth*1, height: imageHeight*1, x: imageX*1, y: imageY*1, rotation: imageRotation*1};
        updateImageGeometry(imageId, data);
        previewImageGeometry(event, "y", value);
    }

    const submitRotate90 = (event) => {
        let existingRotation = imageRotation / 90;
        let xTimes = (1 + Math.floor(existingRotation));
        let value = (xTimes === 4) ? 0 : xTimes * 90;
        imageRotation = [value];
        let data = {width: imageWidth*1, height: imageHeight*1, x: imageX*1, y: imageY*1, rotation: imageRotation*1};
        updateImageGeometry(imageId, data);
        previewImageGeometry(event, "rotation", value);
    }

    const submitImageMaxY = (event) => {
        let newWidth = imageMaxHeight / imageHeight * imageWidth;
        let newY = (canvasHeight - imageMaxHeight) / 2;
        imageScale = [newWidth];
        imageY = [newY];
        let data = {width: imageWidth*1, height: imageHeight*1, x: imageX*1, y: imageY*1, rotation: imageRotation*1};
        updateImageGeometry(imageId, data);
        previewImageGeometry(event, "y", newY);
        previewImageGeometry(event, "scale", newWidth);
    }

    const moveImageUp = (event) => {
        console.log("submit now move image element " + imageId + "up");
    }

    const moveImageDown = (event) => {
        console.log("submit now move image element " + imageId + "down");
    }

    async function submitImageGeometry(event, key, value, startvalue) {
        if (value !== startvalue) {
            let data = {};
            let canvasFabric = $c6EngineCanvas.fabricCanvas;
            let object = await getFabricElementById(canvasFabric, imageId);
            imageHeight = [(object.height / object.width * imageScale)];

            if (key === "scale") {
                key = "width";
                minX = 0 - value + 1
                imageHeight = [(object.height / object.width * value)];
            }
            data = {width: imageWidth*1, height: imageHeight*1, x: imageX*1, y: imageY*1, rotation: imageRotation*1};
            try {
                let updated = await updateImageGeometry(imageId, data);
                let rendered = await renderPage();
                if (updated && rendered) {
                    c6WorkspaceValidation.set(await validateImprint($c6SceneryCanvas));
                    validationElements = $c6WorkspaceValidation.elements
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    function toggleClipartsPopup() {
        showCliparts = !showCliparts;
    }

    onMount(async () => {
        useInch = !await getUiConfigs('workspace.visibility.canvasinfomm');
        unit = (useInch) ? "" : "mm";

        let filterblackwhite = await getUiConfigs('workspace.visibility.filterblackwhite');
        if(filterblackwhite) {
            imageFilterOptions.push({
            value: 'Recolor',
            label: $_('image.button.blackandwhitestamp'),
            });
        }

        let photofilter = await getUiConfigs('workspace.visibility.photofilter');
        if(photofilter) {
            imageFilterOptions.push({
                value: 'Halftone',
                label: $_('image.button.photofilter'),
            });
        }

        if(await getUiConfigs('workspace.visibility.cliparts') && $c6WorkspaceCliparts.length){
            clipartsAll = $c6WorkspaceCliparts;
            clipartCategories = await getUiConfigs('workspace.visibility.clipartcategories');
            hasCliparts = true;
        }

        return () => {
        };

    });

</script>

{#await loadElementData(imageId) }
    <Loader />
{:then imageLoaded}
<div class="creator6-image" data-c6imageid="{imageId}">
    {#if $c6DebugIt}<div>Image with ID : {imageId} with image: {creator6image}</div>{/if}
    <button class="creator6-image-button-info" on:click={() => imageUploadInfoShow = !imageUploadInfoShow}>
        <span class="colop-icon-question_left"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
    </button>
    <div class="creator6-image-controls">
        <div class="creator6-image-upload">
            <div class="creator6-image-preview">
                {#if creator6upload}
                    <img class="creator6-image-preview-img" src="{creator6upload}" alt="{$_('image.alt.upload')}" />
                {:else if clipartSrc }
                    <img class="creator6-image-preview-img" src="{clipartSrc}" alt="{$_('image.alt.clipart')}" />
                {:else if creator6image || mediaPoolId}
                    <img class="creator6-image-preview-img" src="{creator6image}" alt="{$_('image.alt.creatorfile')}" />
                {:else}
                    <span class="colop-icon-tab_img icon-large"></span>
                {/if}

            </div>
            <div class="creator6-image-buttons">
                <button class="creator6-image-selector" type="button" on:click={()=>{fileinput.click();}}>
                    {#if imageId !== "empty"}
                        <span class="label">{$_('image.button.replace')}</span>
                    {:else}
                        <span class="label">{$_('image.button.upload')}</span>
                    {/if}
                </button>

                {#if imageId !== "empty"}
                    <button class="creator6-image-selector" type="button" on:click={(e)=>deleteCurrentImage(e)}>
                        <span class="label">{$_('image.button.delete')}</span>
                    </button>
                {/if}

                <input style="display:none" type="file" accept="{allowedFileExtensions}" on:change={(e)=>onFileSelected(e)} bind:this={fileinput} >

                {#if hasCliparts }
                    <button class="creator6-image-selector" type="button" on:click={(e)=>toggleClipartsPopup(e)}>
                        <span class="label">{$_('image.button.showcliparts')}</span>
                    </button>
                {/if}
            </div>

        </div>
        {#if hasCliparts }
            <Cliparts cliparts={clipartsAll}  clipartCategories={clipartCategories} bind:imageId={imageId} bind:clipartSrc={clipartSrc} bind:showCliparts={showCliparts}/>
        {/if}

        {#if imageId !== "empty"}
        <div class="creator6-warnings-errors">
            <div class="creator6-image-warnings">
                {#if validationElements && imageId in validationElements && validationElements[imageId]}
                    {$_('image.warning.default')}
                {/if}
            </div>
            <div class="creator6-image-alerts"></div>
        </div>
        {#if imageFilterOptions.length > 1}
        <div class="creator6-image-control creator6-image-control-filter">
            <div class="creator6-control-label">{$_('image.control.filter')}</div>
            <div class="creator6-frame-control-togglebuttongroup">
                <Togglebuttons options={imageFilterOptions} fontSize={16}  bind:valueSelected={imageFilter} bind:valueClicked={updateImageFilter}/>
            </div>
        </div>
        {/if}
        <div class="creator6-image-control creator6-image-control-resize">
            <div class="creator6-image-label-input">
                <div class="creator6-control-label">{$_('image.control.size')}</div>
                <div class="creator6-button-group creator6-button-group-{unit}">
                    <input type="number" step="0.1" bind:value={imageScale[0]} max={maxScale} on:keyup={(e) => previewImageGeometry(e, 'scale', e.target.valueAsNumber)} on:focusout={(e) => submitImageGeometry(e, 'scale', e.target.valueAsNumber)} />
                    <button type="button" on:click={(e) => submitImageMaxY(e)} >
                        <span class="colop-icon-maximize"></span>
                    </button>
                </div>
            </div>
            <div class="creator6-image-control-slider">
                <RangeSlider suffix=" {unit}" values={imageScale} bind:minX bind:maxX pips first='label' last='label'  max={maxScale} float on:change={(e) => previewImageGeometry(e, 'scale', e.detail.value)} on:stop={(e) => submitImageGeometry(e, 'scale', e.detail.value, e.detail.startvalue)} />
            </div>
        </div>

        <div class="creator6-image-control creator6-image-control-posx">
            <div class="creator6-image-label-input">
                <div class="creator6-control-label">{$_('image.control.posx')}</div>
                <div class="creator6-button-group creator6-button-group-{unit}">
                    <input type="number" step="0.1" bind:value={imageX[0]} min={minX} max={maxX} on:keyup={(e) => previewImageGeometry(e, 'x', e.target.valueAsNumber)} on:focusout={(e) => submitImageGeometry(e, 'x', e.target.valueAsNumber)}/>
                    <button type="button" on:click={(e) => submitImageCenterX(e)} >
                        <span class="colop-icon-align_vertical_center"></span>
                    </button>
                </div>
            </div>
            <div class="creator6-image-control-slider">
                <RangeSlider suffix=" {unit}" values={imageX} pips first='label' last='label' min={minX} max={maxX} float on:change={(e) => previewImageGeometry(e, 'x', e.detail.value)} on:stop={(e) => submitImageGeometry(e, 'x', e.detail.value, e.detail.startvalue)} />
            </div>
        </div>
        <div class="creator6-image-control creator6-image-control-posy">
            <div class="creator6-image-label-input">
                <div class="creator6-control-label">{$_('image.control.posy')}</div>
                <div class="creator6-button-group creator6-button-group-{unit}">
                    <input type="number" step="0.1" bind:value={imageY[0]} min={minY} max={maxY} on:keyup={(e) => previewImageGeometry(e, 'y', e.target.valueAsNumber)} on:focusout={(e) => submitImageGeometry(e, 'y', e.target.valueAsNumber)} />
                    <button type="button" on:click={(e) => submitImageCenterY(e)} >
                        <span class="colop-icon-align_horizontal_center"></span>
                    </button>
                </div>
            </div>
            <div class="creator6-image-control-slider">
                <RangeSlider suffix=" {unit}" bind:values={imageY} pips first='label' last='label' min={minY} max={maxY} float on:change={(e) => previewImageGeometry(e, 'y', e.detail.value)} on:stop={(e) => submitImageGeometry(e, 'y', e.detail.value, e.detail.startvalue)} />
            </div>
        </div>

            <div class="creator6-image-control creator6-image-control-rotation">
                <div class="creator6-image-label-input">
                    <div class="creator6-control-label">{$_('image.control.rotation')}</div>
                    <div class="creator6-button-group creator6-button-group-{unit}">
                        <input type="number" step="1" bind:value={imageRotation[0]} min={0} max={359} on:keyup={(e) => previewImageGeometry(e, 'rotation', e.target.valueAsNumber)} on:focusout={(e) => submitImageGeometry(e, 'rotation', e.target.valueAsNumber)} />
                        <button type="button" on:click={(e) => submitRotate90(e)} >
                            <span class="colop-icon-rotate_90"></span>
                        </button>
                    </div>
                </div>
                <div class="creator6-image-control-slider">
                    <RangeSlider suffix=" °" bind:values={imageRotation} pips first='label' last='label' min={0} max={359} float on:change={(e) => previewImageGeometry(e, 'rotation', e.detail.value)} on:stop={(e) => submitImageGeometry(e, 'rotation', e.detail.value, e.detail.startvalue)} />
                </div>
            </div>
            {/if}
    </div>
</div>
{/await}
{#if imageUploadInfoShow}
    <div class="creator6-image-info">
        <div class="creator6-image-info-label">{@html $_('image.info.bottomlabel')}</div>
        <div class="creator6-image-info-details">{@html $_('image.info.bottomdetails').replace('{extensions}', allowedFileExtensions)}</div>
    </div>
{/if}

<style>

    .creator6-image {
        display:block;
        border-top: 1px solid #ddd;
        position:relative;
    }
    .creator6-image:first-child {
        border-top:none;
    }

    .creator6-image {
        display:block;
        border-top: 1px solid #ddd;
        position:relative;
    }

    .creator6-image-controls {
        width:100%;
        padding: 0 10px;
    }

    .creator6-image-preview {
        width: 80px;
        height: 80px;
        border: 1px solid var(--creator6-controls-bg);
        margin: 10px 10px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .creator6-image-preview-img {
        width:100%;
        height:auto;

    }

    .creator6-image-control {
        border-top: 1px solid var(--creator6-controls-spacer);
        margin: 10px 0;
    }

    .creator6-image-control-filter {
        padding-top:20px;
    }

    .creator6-image-control input {
        width: 60px;
        -moz-appearance: textfield;
        text-align: right;
        border: none;
        background: var(--creator6-controls-input-bg);
        height: 29px;
        border-bottom: 1px solid #f3f3f3;
        padding-right:22px;
        color: var(--creator6-color-blue);
        margin-left:10px
    }

    .creator6-control-label {
        min-width:150px;
    }

    .creator6-image-control button {
        height:100%;
        width: 40px;
        border: none;
        font-size: var(--creator6-fontsize-bigger);
        background:none;
        cursor:pointer;
    }

    .creator6-image-control button:hover, .creator6-image-selector:hover {
        cursor: pointer;
        border-radius: var(--creator6-border-radius);
    }

    .creator6-image-label-input {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .creator6-button-group{
        border: 1px solid var(--creator6-controls-bg);
        border-radius: var(--creator6-border-radius);
        background: var(--creator6-controls-bg);
        margin: 5px;
        white-space: nowrap;
        height:40px;
        display:flex;
        position: relative;
        align-items: center;
    }

    .creator6-button-group::after {
        position: absolute;
        right: 40px;
        font-size: var(--creator6-fontsize-small);
        color: #aaa;
        text-align: left;
    }

    .creator6-button-group-mm::after {
        content:"mm";
    }

    .creator6-image-control-rotation .creator6-button-group::after {
        content:"°";
        font-size: var(--creator6-fontsize-bigger);
    }

    .creator6-image-control-slider {
        width:90%;
        margin-top: 0.5em;
        margin-left: 1em;
        margin-right: 1em;
    }

    :global(.creator6-image-control-slider .pipVal) {
        font-size:var(--creator6-fontsize-small);
    }


    .creator6-image-upload {
        display:inline-flex;
        align-items: center;
        width: calc(100% - 40px);
    }

    .creator6-image-selector {
        height: 40px;
        border: 1px solid var(--creator6-color-blue);
        border-radius: var(--creator6-border-radius);
        background: var(--creator6-color-blue);
        color: #fff;
        font-weight: bold;
        padding: 5px;
        position: relative;
    }

    .creator6-image-button-info {
        border:none;
        background:none;
        font-size:var(--creator6-fontsize-big);
        position:absolute;
        right:10px;
        top: 5px;
        z-index: 1000;
        cursor:pointer;
    }

    .creator6-image-info{
        border: 1px solid var(--creator6-color-blue);
        background: #2b77bc;
        margin: 10px 0 0 0;
        padding: 10px;
        color: white;
    }

    @media (min-width: 768px) {
        .creator6-image-control {
            display: inline-flex;
            align-items: stretch;
            width: 100%;
        }
        .creator6-image-preview {
            width:100px;
            height:100px;
        }
    }

</style>



