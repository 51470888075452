<script>
    import {getPreviewImageUrl, showFile, getPlatePreviewUrl} from '../../resources/js/creator6-preview';
    import Loader from "../../components/Loader.svelte";
    import {_} from '../../services/i18n';
    import {
        c6PreviewPdfEndpoint,
        c6PreviewPdfTemplateUrl, c6ProductName,
        c6WorkspaceMedium
    } from "../../resources/js/stores";

    let loading = false;
    let showError = false;
    let errorMessage = "";
    async function openPlatePreview(e)
    {
        const node = e.currentTarget;
        node.disabled = true;
        loading = true;
        var previewUrl = await getPlatePreviewUrl();
        let filename = $_('printpreview.pdf.filename').replace('{productname}', $c6ProductName);
        let title = $_('printpreview.pdf.title');
        let subtitle = $_('printpreview.pdf.subtitle');
        let templateUrl = ($c6PreviewPdfTemplateUrl === null) ? "" : $c6PreviewPdfTemplateUrl;
        let imprintHeight = $c6WorkspaceMedium.height;
        let imprintWidth = $c6WorkspaceMedium.width;
        let previewFileName = "preview";
        let previewConfigId = "default";
        let fetchUrl = $c6PreviewPdfEndpoint+"?configurationId="+previewConfigId+"&title="+title+"&subtitle="+subtitle+"&imprintWidth="+imprintWidth+"&imprintHeight="+imprintHeight+"&imprint="+previewUrl+"&templateUrl="+templateUrl+"&name="+previewFileName+"&productImageUrl=&testMode=0";

        const platePreview = fetch(fetchUrl, {
            method: 'GET'
            //body: formData
        }).then(r => r.blob()).then(r => {
            if(r.type !== "application/pdf"){
                errorMessage = $_('printpreview.error.nofile');
                showError = true;
                node.disabled = false;
                loading = false;
                return Promise.reject(errorMessage);
            }
            else {
                showFile( r,filename);
                showError = false;
                node.disabled = false;
                loading = false;
                return Promise.resolve("ok");
            }
        });
    }

</script>

<button class="creator6-print-preview" on:click={(e)=>openPlatePreview(e)}>
    {#if loading}
        <Loader size="20" color="#fff" background="transparent" transparent="true" />
    {:else }
        {$_('printpreview.button.print')}
    {/if}

</button>
{#if showError}
    <div class="creator6-preview-error-message">{$_('printpreview.error.cannotcreatepdf')} {errorMessage}</div>
{/if}

<style>
    button {
        background: var(--creator6-color-blue);
        border: 1px solid var(--creator6-color-blue);
        border-radius: 10px;
        padding: 0.4em;
        color: white;
        font-weight: bold;
        width: 90%;
        margin: 1em;
        display: inline-block;
        cursor: pointer;
    }

    .creator6-preview-error-message {
        color: var(--creator6-error-color);
        margin-top: -10px;
        margin-bottom: 20px;
        text-align: center;
    }
</style>