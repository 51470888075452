import {renderPage, registerSelection} from "./creator6-core";
import {getWorkspaceFromId, getPageFromWorkspaceId, getCurrentPage, setWorkspaceId, getWorkspaceId, setSelectedElement, getSelectedElement} from './creator6-helper';
import {Unexpected} from "@tagading/scenery-fabricjs";
import { get } from 'svelte/store';
import {c6WorkspaceFonts, c6ApiConnector, c6EngineCanvas, c6WorkspaceId, c6SceneryCanvas} from "./stores";

function toggleLine(sceneElementId) {

    var update = getCurrentPage().frameElementUpdate();
    update.getLine(sceneElementId).subscribe(function (elementModel) {
        if (elementModel instanceof Scenery.Unexpected) {
            console.error('Updater could not get line element model');
        }
        else {
            if(elementModel.stroke.strokeWidth > 0){
                elementModel.stroke.strokeWidth = 0;
            }
            else {
                elementModel.stroke.strokeWidth = 0.2;
            }
            update.updateLineStroke(elementModel, elementModel.stroke).subscribe(function (result) {
                renderPage();
            });
        }
    });
}


async function setLineStrokeData(sceneElementId, key, value) {
    return new Promise((resolve, reject) => {
        var update = getCurrentPage().lineElementUpdate();
        update.getLine(sceneElementId).subscribe(function (elementModel) {
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get line element model');
            }
            else {
                elementModel.stroke[key] = value;
                update.updateLineStroke(elementModel, elementModel.stroke).subscribe(function (result) {
                    if (result) {
                        renderPage();
                        resolve(true);
                    }
                    else {
                        reject("Could not update Line with ID " + sceneElementId);
                    }
                    //renderPage();
                });
            }
        });

    });
}

async function setLineGeometry(sceneElementId, data) {
    return new Promise((resolve, reject) => {
        var update = getCurrentPage().lineElementUpdate();
        update.getLine(sceneElementId).subscribe(function (elementModel) {
            if (elementModel instanceof Unexpected) {
                console.error('Updater could not get line element model');
            }
            else {
                for (const [key, value] of Object.entries(data)) {
                    elementModel.geometry[key] = value;
                }
                update.updateLineGeometry(elementModel, elementModel.geometry).subscribe(function (result) {
                    if (result) {
                        renderPage();
                        resolve(true);
                    }
                    else {
                        reject("Could not update Line Geometry with ID " + sceneElementId);
                    }
                });
            }
        });

    });
}

async function getLineElementData(sceneElementId, apiConnector = get(c6ApiConnector), engineCanvas = get(c6EngineCanvas), workspaceId = get(c6WorkspaceId) ){
    return new Promise((resolve, reject) => {
        let activePage = getCurrentPage().pageIndex;
        apiConnector.getGateway().getFrontendApi().getLine(workspaceId, activePage, sceneElementId).subscribe(function(existingLine){
            if (existingLine instanceof Unexpected) {
                reject(false);
            }
            else {
                resolve(existingLine.response);
            }
        });
    });
}

export { getLineElementData, setLineGeometry, setLineStrokeData };